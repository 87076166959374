import React, { Component } from "react";
class Footer extends Component {
  
  
  render() {
    
    return (
      <footer>
        <div className="container">
            <div className="row">
                <div className="col-sm-6 ">
                    <p>@ 2020 Health Click. All rights reserved.</p>
                </div>
                <div className="col-sm-6 text-right">
                    <p>Developed By Broadway Infotech</p>
                </div>
            </div>
        </div>
    </footer>
    );
  }
}


export default Footer;