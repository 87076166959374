import React, { Component } from 'react';
class Rating extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }


  componentDidMount() {

  }

  render() {
    const rating = this.props.rating;



    return (
      <React.Fragment>

        {rating == 0 ?
          (<div className="rating-col">
            <div className="check-item">
              <input type="checkbox" name="star01" value="" classs="describe-input" />
              <label className="rating-star spt-img"></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star02" value="" classs="describe-input" />
              <label className="rating-star spt-img" ></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star03" value="" classs="describe-input" />
              <label className="rating-star spt-img" ></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star04" value="" classs="describe-input" />
              <label className="rating-star spt-img" ></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star05" value="" classs="describe-input" />
              <label className="rating-star spt-img" ></label>
            </div>
          </div>) :
          ('')
        }

        {rating == 1 ?
          (<div className="rating-col">
            <div className="check-item">
              <input type="checkbox" name="star01" value="" id="star01" classs="describe-input" checked="checked" />
              <label className="rating-star spt-img" htmlFor="star01"></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star02" value="" classs="describe-input" checked="" />
              <label className="rating-star spt-img" ></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star03" value="" classs="describe-input" />
              <label className="rating-star spt-img" ></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star04" value="" classs="describe-input" />
              <label className="rating-star spt-img" ></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star05" value="" classs="describe-input" />
              <label className="rating-star spt-img" ></label>
            </div>
          </div>) :
          ('')
        }

        {rating == 2 ?
          (<div className="rating-col">
            <div className="check-item">
              <input type="checkbox" name="star01"  id="star01" classs="describe-input" checked="checked" />
              <label className="rating-star spt-img" for="star01"></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star02" value="" id="star02" classs="describe-input" checked="checked" />
              <label className="rating-star spt-img" for="star02"></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star03" value="" classs="describe-input" />
              <label className="rating-star spt-img" ></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star04" value="" classs="describe-input" />
              <label className="rating-star spt-img" ></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star05" value="" classs="describe-input" />
              <label className="rating-star spt-img" ></label>
            </div>
          </div>) :
          ('')
        }



        {rating == 3 ?
          (<div className="rating-col">
            <div className="check-item">
              <input type="checkbox" name="star01" value="" id="star01" classs="describe-input" checked="checked" />
              <label className="rating-star spt-img" for="star01"></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star02" value="" id="star02" classs="describe-input" checked="checked" />
              <label className="rating-star spt-img" for="star02"></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star03" value="" id="star03" classs="describe-input" checked="checked" />
              <label className="rating-star spt-img" for="star03"></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star04" value="" classs="describe-input" />
              <label className="rating-star spt-img" ></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star05" value="" classs="describe-input" />
              <label className="rating-star spt-img" ></label>
            </div>
          </div>) :
          ('')
        }


        {rating == 4 ?
          (<div className="rating-col">
            <div className="check-item">
              <input type="checkbox" name="star01" value="" id="star01" classs="describe-input" checked="checked" />
              <label className="rating-star spt-img" for="star01"></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star02" value="" id="star02" classs="describe-input" checked="checked" />
              <label className="rating-star spt-img" for="star02"></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star03" value="" id="star03" classs="describe-input" checked="checked" />
              <label className="rating-star spt-img" for="star03"></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star04" value="" id="star04" classs="describe-input" checked="checked" />
              <label className="rating-star spt-img" for="star04"></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star05" value="" classs="describe-input" />
              <label className="rating-star spt-img" ></label>
            </div>
          </div>) :
          ('')
        }


        {rating == 5 ?
          (<div className="rating-col">
            <div className="check-item">
              <input type="checkbox" name="star01" value="" id="star01" classs="describe-input" checked="checked" />
              <label className="rating-star spt-img" for="star01"></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star02" value="" id="star02" classs="describe-input" checked="checked" />
              <label className="rating-star spt-img" for="star02"></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star03" value="" id="star03" classs="describe-input" checked="checked" />
              <label className="rating-star spt-img" for="star03"></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star04" value="" id="star04" classs="describe-input" checked="checked" />
              <label className="rating-star spt-img" for="star04"></label>
            </div>
            <div className="check-item">
              <input type="checkbox" name="star05" value="" id="star05" classs="describe-input" checked="checked" />
              <label className="rating-star spt-img" for="star05"></label>
            </div>
          </div>) :
          ('')
        }


      </React.Fragment>

    )

  }
}

export default Rating;