import React, { Component } from 'react';
import axios from "axios";
export default class AdvanceSetting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // advanceBooking:'', 
            advanceBookingDay: this.props.advanceBooking,
            cancelDay: '',
            status: false,
            ErrMsgAdvance: '',
            ErrMsgcancelDay: '',
            message: '',

        };
    }


    onChangehandler = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }


    formHandleSubmit(form) {

        const doctorObject = JSON.parse(localStorage.getItem("userData"));
        var doctorDetailId = doctorObject.doctordetailId;
        this.setState({ isLoading: true });

        var body = {};
        if (form == 'advance') {

            body = {
                type: form,
                advanceBookingDay: this.state.advanceBookingDay,
                doctorDetailId: doctorDetailId,
                hospitalId: 1,
            }

        } else {

            body = {
                type: form,
                cancelDay: this.state.cancelDay,
                doctorDetailId: doctorDetailId,
                hospitalId: 1,
            }

        }

         // Pass token in the header
         let auth_token = localStorage.getItem('token');
         let header_token = { 'x-access-token': auth_token };

        axios.post(process.env.REACT_APP_API_URL + "doctor/updateSetting", body, 
        { headers: header_token }).then((response) => {
                console.log(response);
                if (response.data.status == true) {

                    this.setState({
                        status: true,
                        message: response.data.message,
                    })

                } else {

                    this.setState({
                        ErrMsgAdvance: response.data.errors.advanceBookingDay,
                        ErrMsgcancelDay: response.data.errors.cancelDay
                    });
                }

                setTimeout(() => {
                    this.setState({
                        status: false,
                        message: '',
                        ErrMsgAdvance: '',
                        ErrMsgcancelDay: ''
                    });
                }, 5000)

            }).catch((error) => {
                console.log("enter catch error :", error);
            })




        // this.updateTiming(data);
    }


    getDoctorInfo(memberId) {
        this.setState({ isLoading: true });
        axios.get(process.env.REACT_APP_API_URL + "doctor/getDoctorInfo/" + memberId)
            .then((response) => {
                this.setState({ isLoading: false });
                if (response.data.status) {
                    console.log("Get Doctor Details : ", response.data.result[0]);
                    this.setState(
                        {
                            advanceBookingDay: response.data.result[0].advanceBooking,
                            cancelDay: response.data.result[0].cancelation,
                        }
                    )
                }

                })
            .catch((error) => {
                console.log('enter catch error');
                console.log(error);
            });
    }

    
    componentDidMount() {
        const user = JSON.parse(localStorage.getItem("userData"));
        var memberId = user.memberId;
        this.getDoctorInfo(memberId);

    }

    render(props) {
        return (



            <div id="notes" class="tab-pane fade note-tab active show"><br />
                <div class="row">
                    <div class="col-sm-12">

                        {this.state.status ? (<div class="alert alert-success">
                            {this.state.message}
                        </div>) : ('')}
                        <div class="doc-detail write-notes">
                            <h3> No. of days of advance booking *</h3>
                            <div className="text-danger">{this.state.ErrMsgAdvance}</div>

                            <form>

                                {/* <select class="form-control" name="advanceBookingDay" >
                                       <option></option>
                                </select> */}
                                <input type="number" name="advanceBookingDay" value={this.state.advanceBookingDay} class="form-control" onChange={this.onChangehandler} />


                                <button onClick={() => this.formHandleSubmit('advance')} type="button" class="save-button">Save</button>
                            </form>
                            <p>Patient can book appointment in advance booking </p>
                        </div>
                        <div class="doc-detail write-notes">
                            <h3>No. of days of cancellation </h3>
                            <div className="text-danger">{this.state.ErrMsgcancelDay}</div>
                            <form>
                                <input type="number" name="cancelDay" value={this.state.cancelDay} class="form-control" onChange={this.onChangehandler} />
                                <button onClick={() => this.formHandleSubmit('cancelation')} type="button" class="save-button">Save</button>
                            </form>
                            <p>Patient can cancel his/her appointment before the cancelation time is complete</p>
                        </div>

                    </div>
                </div>
            </div>


        );

    }
}
