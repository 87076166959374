import React, { Component } from 'react';
import axios from "axios";
import { Link, Redirect } from "react-router-dom";

import * as Cookies from 'js-cookie'

class JoinCall extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }





    render() {
        
        // Cookies.set('channel', this.props.channel);
        
        return (
                <a href={`/videocall/${this.props.channel}`}>
                    <button type="button" className="btn btn-primary btn-sm">Join call</button>
                </a> 
        )

    }
}

export default JoinCall;