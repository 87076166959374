import React, { Component } from 'react';
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import NumberFormat from 'react-number-format';


class Invoice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            invoiceDetail: '',
        };
    }


    getPaymentInvoice(id) {





        axios.get(process.env.REACT_APP_API_URL + "getPaymentInvoice/" + id)
            .then((response) => {


                console.log("Patient Detail :", response.data.result[0]);


                if (response.data.status === true) {
                    this.setState({
                        invoiceDetail: response.data.result[0],
                    });

                } else {
                    console.log(response);
                }
            })
            .catch((error) => {
                console.log('catch error', error);
            });


    }




    componentDidMount() {

        var InvoiceId = this.props.match.params.id;
        console.log("Get Invoice Number :", InvoiceId);
        this.getPaymentInvoice(InvoiceId);
    }

    printReceipt() {
        // var printButton = document.getElementById("printbtn");
        // printButton.style.visibility = 'hidden';
        window.print();
    }

    render() {

        // Redirect User if not login
        const login = localStorage.getItem("isLoggedIn");
        if (!login) {
            return <Redirect to="/" />;
        }


        const patientName = JSON.parse(localStorage.getItem("userData"));





        return (
            <React.Fragment>
                <section class="invoce-sec patient-main-section">
                    <div class="container">

                        <div class="invoice-content">
                            <div class="invoice-item">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="invoice-logo">
                                            <img src={process.env.REACT_APP_BASE_URL + "img/logo.png"} alt="" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <p class="invoice-details">
                                            <br />

                                            <strong>Receipt:</strong> #{this.state.invoiceDetail.invoiceId} <br />
                                            <strong>Date of Payment:</strong> {this.state.invoiceDetail.transectionDate}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="invoice-item">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="invoice-info">
                                            <strong class="customer-text">Patient Details</strong>
                                            <p class="invoice-details invoice-details-two captilize">
                                                {this.state.invoiceDetail.first_name} <nbsp />
                                                {this.state.invoiceDetail.last_name} <nbsp />
                                                <br />
                                                {this.state.invoiceDetail.gender} <nbsp />
                                                {this.state.invoiceDetail.age} <nbsp />
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="invoice-info invoice-info2">
                                            <strong class="customer-text">Doctor Details</strong>
                                            <p class="invoice-details">
                                                Dr. {this.state.invoiceDetail.doctor_name}  <br />

                                                {this.state.invoiceDetail.doctor_specialization}

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="invoice-item">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="invoice-info">
                                            <strong class="customer-text">Payment Method</strong>
                                            <p class="invoice-details invoice-details-two">
                                                Debit Card <br />
                                                                                XXXXXXXXXXXX-2541 <br />
                                                                                    HDFC Bank<br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div class="invoice-item">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="list-table">
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead class="thead-dark">
                                                        <tr>
                                                            <th>Transaction ID</th>
                                                            <th>Receipt No.</th>
                                                            <th>Consultation ID</th>
                                                            <th>Date of Payment</th>
                                                            <th>Doctor</th>
                                                            <th>Payment Mode</th>
                                                            <th>Amount (INR) </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <td> DDP{this.state.invoiceDetail.transectionId}</td>
                                                            <td> {this.state.invoiceDetail.invoiceId}</td>
                                                            <td> {this.state.invoiceDetail.appointmentNo}</td>
                                                            <td>{this.state.invoiceDetail.transectionDate}</td>
                                                            <td>{this.state.invoiceDetail.doctor_name} </td>
                                                            <td>{this.state.invoiceDetail.type} </td>
                                                            <td> <NumberFormat value={this.state.invoiceDetail.amount} displayType={'text'} thousandSeparator={true} prefix={''} /> </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-xl-4 ml-auto">
                                        <div class="table-responsive">
                                            <table class="invoice-table-two table">
                                                <tbody>
                                                    <tr>
                                                        <th>Subtotal:</th>
                                                        <td><span><NumberFormat value={this.state.invoiceDetail.amount} displayType={'text'} thousandSeparator={true} prefix={''} /></span></td>
                                                    </tr>
                                                    {/* <tr>
                                                        <th>Discount:</th>
                                                        <td><span>0</span></td>
                                                    </tr> */}
                                                    <tr>
                                                        <th>Total Amount:</th>
                                                        <td><span><NumberFormat value={this.state.invoiceDetail.amount} displayType={'text'} thousandSeparator={true} prefix={''} /></span></td>
                                                    </tr>

                                                    <tr>
                                                        <th> <Link to="/patient/payment">
                                                            <button type="button" className="btn btn-outline-primary">Back To Payment</button></Link> </th>
                                                        <td>
                                                            <button onClick={this.printReceipt} type="button" className="btn btn-outline-primary">Print / Download</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />

                        </div>
                    </div>
                </section></React.Fragment>

        )

    }
}

export default Invoice;


