import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import axios from "axios";


class AddMember extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            mobile: '',
            age: '',
            gender: '',
            email: '',
            madical_history: '',
            relation: '',

            showHide: false,
            successMsg: ' ',
            status: false,
            isLoading: false,

            errMsgFirstName: "",
            errMsgLastName: "",
            errMsgAge: "",
            errMsgMobile: "",
            errMsgGender: "",
            errMsgEmail: "",
            errMsgRelation: "",
            errMsgMedicalHistory: ""
        };
    }

    handleModalShowHide() {
        this.setState({
            showHide: !this.state.showHide,
            errMsgFirstName: "",
            errMsgLastName: "",
            errMsgAge: "",
            errMsgMobile: "",
            errMsgGender: "",
            errMsgEmail: "",
            errMsgRelation: "",
            errMsgMedicalHistory: ""
        })
    }

    registerSubmitHandler = (event) => {
        event.preventDefault();
        this.setState({ isLoading: true });
        this.createPatientMember();

    }

    createPatientMember() {


        // Add New Member

        const user = JSON.parse(localStorage.getItem("userData"));

        var body = {
            id: user.id,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            mobile: this.state.mobile,
            age: this.state.age,
            gender: this.state.gender,
            email: this.state.email,
            madical_history: this.state.madical_history,
            relation: this.state.relation
        };


        // Pass token in the header
        let auth_token = localStorage.getItem('token');
        let header_token = { 'x-access-token': auth_token };

        axios.post(process.env.REACT_APP_API_URL + "createpatient/", body,
            { headers: header_token }).then((response) => {

                console.log("---Add Member Response -----:", response);
                if (response.data.success === true) {

                    this.setState({
                        successMsg: response.data.message,
                        status: true,
                        isLoading: false,

                        first_name: '',
                        last_name: '',
                        mobile: '',
                        age: '',
                        gender: '',
                        email: '',
                        madical_history: '',
                        relation: '',

                        errMsgFirstName: "",
                        errMsgLastName: "",
                        errMsgAge: "",
                        errMsgMobile: "",
                        errMsgGender: "",
                        errMsgEmail: "",
                        errMsgRelation: "",
                        errMsgMedicalHistory: ""

                    });


                    window.location.reload(false);
                    this.props.history.push('/dashboard');

                }
                else if (response.data.status === "failed") {
                    this.setState({
                        status: false,
                        errMsgFirstName: response.data.errors.first_name,
                        errMsgLastName: response.data.errors.last_name,
                        errMsgAge: response.data.errors.age,
                        errMsgGender: response.data.errors.gender,
                        errMsgMobile: response.data.errors.mobile,
                        errMsgEmail: response.data.errors.email,
                        errMsgRelation: response.data.errors.relation,
                        errMsgMedicalHistory: response.data.errors.madical_history,
                    });
                }

                this.setState({ isLoading: false });


            }).catch((error) => {

                console.log('----Add new Member API catch error-------');
                console.log(error);

            });
    }

    registerChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }


    render() {

        const status = this.state.status;
        const isLoading = this.state.isLoading;

        return (<div>
            <div className="add-member-btn">
                <button data-toggle="modal" data-target="#exampleModal" type="button" onClick={() => this.handleModalShowHide()}>
                    <span className="spt-img add-icon"></span> Add Family Member
                        </button>
            </div>

            <Modal className="add-family-member" show={this.state.showHide}>
                <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                    <h5 className="modal-title" id="exampleModalLabel">Add Family Member</h5>
                </Modal.Header>
                <Modal.Body>

                    {status ? (
                        <div>
                            <br />
                            <div className="alert alert-success">
                                {this.state.successMsg}.
                                        </div>
                        </div>
                    ) : (
                            <span></span>

                        )}

                    <div className="profile_deatils">
                        <form onSubmit={this.registerSubmitHandler}>
                            <div className="row">
                                <div className="col-sm-6 form-group">
                                    <label>First Name *</label>
                                    <input type="text" className="form-control" name="first_name" value={this.state.first_name} onChange={this.registerChangeHandler}  maxLength="30"/>
                                    <div className="text-danger">{this.state.errMsgFirstName}</div>
                                </div>

                                <div className="col-sm-6 form-group">
                                    <label >Last Name *</label>
                                    <input type="text" className="form-control" name="last_name" value={this.state.last_name} onChange={this.registerChangeHandler} maxLength="20"/>
                                    <div className="text-danger">{this.state.errMsgLastName}</div>
                                </div>

                                <div className="col-sm-6 form-group">
                                    <label >Mobile *</label>
                                    <input maxLength="10" type="text" className="form-control" name="mobile" value={this.state.mobile} onChange={this.registerChangeHandler} />
                                    <div className="text-danger">{this.state.errMsgMobile}</div>
                                </div>


                                <div className="col-sm-6 form-group">
                                    <label >Email *</label>
                                    <input type="text" className="form-control" name="email" value={this.state.email} onChange={this.registerChangeHandler} maxLength="40"/>
                                    <div className="text-danger">{this.state.errMsgEmail}</div>
                                </div>

                                <div className="col-sm-6 form-group">
                                    <label >Age *</label>
                                    <input type="number" className="form-control" name="age" value={this.state.age} onChange={this.registerChangeHandler} />
                                    <div className="text-danger">{this.state.errMsgAge}</div>
                                </div>

                                <div className="col-sm-6 form-group">
                                    <label >Relationship *</label>
                                    <select className="form-control" name="relation"  onChange={this.registerChangeHandler}> 
                                        <option value=""> --- Select Relationship --- </option>
                                        <option value="Brother">Brother </option>
                                        <option value="Sister">Sister </option>
                                        <option value="Doughter">Doughter </option>
                                        <option value="Son">Son </option>
                                        <option value="Mother">Mother </option>
                                        <option value="Father">Father </option>
                                        <option value="Uncle">Uncle </option>
                                        <option value="Aunty">Aunty </option>
                                        <option value="Friend">Friend </option>
                                        <option value="Husband">Husband </option>
                                        <option value="Wife">Wife </option>
                                    </select>
                                    <div className="text-danger">
                                            {this.state.errMsgRelation}
                                    </div>

                                    {/* <input type="text" className="form-control" name="relation" value={this.state.relation} onChange={this.registerChangeHandler} />
                                     */}
                                </div>

                                <div className="col-sm-12 form-group">
                                    <label >Gender *</label>

                                    <span className="pt-2 gender_box">
                                        <div className="radio-item">
                                            <input type="radio" name="gender" value="male" id="male" className="describe-input" onChange={this.registerChangeHandler} />
                                            <label className="label_f" htmlFor="male">Male</label>
                                        </div>
                                        <div className="radio-item">
                                            <input type="radio" name="gender" value="female" id="femal" className="describe-input" onChange={this.registerChangeHandler} />
                                            <label className="label_f" htmlFor="femal">Female</label>
                                        </div>
                                        <div className="text-danger">{this.state.errMsgGender}</div>
                                    </span>



                                </div>

                                <div className="col-sm-12 form-group">
                                    <label>Medical History (Maximum allowed 1000 Characters) *</label>
                                    <textarea maxLength="1000" value={this.state.madical_history} name="madical_history" className="form-control" onChange={this.registerChangeHandler}>
                                    </textarea>
                                    <div className="text-danger">{this.state.errMsgMedicalHistory}</div>
                                </div>
                            </div>

                            {/* 
                                    <div className="d-flex justify-content-between pt-xl-4">
                                        <div className="mr-3 upload"> 
                                            <img alt="" src={process.env.REACT_APP_BASE_URL+"img/avtar_icons.png"}/>
                                        </div>
                                        <div className=" upload d-flex justify-content-center align-items-center w-100">
                                        <a href="#" className="text-secondary"> 
                                        <img alt="" src={process.env.REACT_APP_BASE_URL+"img/upload-icons.png"}/>
                                            Upload Profile Photo </a>
                                        </div>
                                    </div> 
                                */}


                            <div className="row pt-xl-5 pb-xl-4 add-member-save-btn-box">
                                <button type="submit" className="pay-btn"
                                > Save
                                                    {isLoading ? (
                                        <span
                                            className="spinner-border spinner-border-sm ml-5"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                            <span></span>
                                        )}
                                </button>
                            </div>
                        </form>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
        )

    }
}

export default AddMember;