import React, { Component } from 'react';
import LEFTNAV from "./leftnav";
import axios from "axios";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

class Investigation extends Component {


    constructor(props) {
        super(props);
        this.state = {
            investigations: [],
        };
    }

    getInvestigationLists() {

        // var patientDetail = JSON.parse(localStorage.getItem("userData"));
        var filterByUserId = localStorage.getItem("filterByUserId");
        if (filterByUserId) {

            axios.get(process.env.REACT_APP_API_URL + "getInvestigationList/" + filterByUserId)
                .then((response) => {

                    console.log(response.data.result);

                    if (response.data.status === true) {
                        this.setState({
                            investigations: response.data.result,
                        });

                    } else {
                        console.log(response);
                    }
                })
                .catch((error) => {
                    console.log('catch error');
                    console.log(error);
                });
        }

    }




    componentDidMount() {
        this.getInvestigationLists();
    }
    render() {

        const columns = [

            {
                name: "Consultation No.",
              
                selector: "appointmentNo",
                sortable: true
            },
            {
                name: "Date & Time",
               
                sortable: true,
                selector: "appointmentDate",
                cell: appointment => <span>
                    {appointment.appointmentDate} {appointment.appointment_time}

                </span>,
            },
            {
                name: "Doctor Name",
                width: "200px",
                selector: "doctor_name",
                sortable: true,
                cell: appointment => <span>
                  <span className="captilize"> <b> Dr.  {appointment.doctor_name} </b> 
                 
                  {appointment.doctor_specialization && appointment.doctor_specialization.substring(30,0) } 
                  {appointment.doctor_specialization && appointment.doctor_specialization.length >30 ? ('...') : ('') }
                </span>
                </span>,
        
              },
           
            {
                name: "Investigation(s)",
                selector: "advise_test",
                sortable: true
            },
        ];


        const data = this.state.investigations;
        const tableData = { columns, data };

        return (
            <section className="patient-appointment-section patient-main-section">
                <div className="container">
                    <div>
                        <LEFTNAV />
                        <div className="content-wrapper">
                            <div className="patient-appointment-list-right-col">
                                <div className="col-md-12">
                                    <h2><span><i className="spt-img calendar-icon"></i></span> Investigations</h2>

                                </div>
                                <div className="list-table d-inline-block w-100">
                                    <div className="table-responsive">



                                        {this.state.investigations.length > 0 ? (<DataTableExtensions {...tableData}>
                                            <DataTable
                                                title="Investigation"
                                                columns={columns}
                                                data={data}
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                paginationPerPage={10}
                                                className="table"
                                                responsive
                                            />
                                        </DataTableExtensions>) : (<div className="table">
                                            <div className="datanotAvailable"> There are no records to display </div>
                                        </div>)}



                                        
                                        {/* <table className="table">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th>Consultation No.</th>
                                                    <th>Date &amp; Time</th>
                                                    <th>Doctor</th>
                                                    <th>Investigation(s) </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.investigations.map((investigation, index) => (
                                                    <tr>
                                                        <td> {investigation.appointmentNo} </td>
                                                        <td> {investigation.appointmentDate} {investigation.appointment_time} </td>
                                                        <td>
                                                            <table className="profile-table">
                                                                <tbody>
                                                                    <tr>
                                                                       
                                                                        <td><b>{investigation.doctor_name}</b><br />{investigation.doctor_specialization}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td> {investigation.advise_test} </td>



                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        )

    }
}

export default Investigation;