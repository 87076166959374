import React, { Component } from 'react';
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
// import DatePicker from 'react-date-picker';


class ForgotPasswordPharmacy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            otp: '',
            isLoading: false,
            status: false,
            successMsg: '',
            errMsgEmail: '',
        };

    }

    forgotSubmitHandler = (event) => {
        event.preventDefault();
        this.setState({
            isLoading: true,
        });
        var body = {
            email: this.state.email,
            type: 'lab',
        };

        axios.post(process.env.REACT_APP_API_URL + 'pharmacy/forgotPasswordOtp', body)
            .then((response) => {
                console.log(response);
                this.setState({ isLoading: false });
                if (response.data.status) {
                    this.setState({
                        successMsg: response.data.message,
                        status: true,
                        errMsgEmail: "",
                        email: '',
                    });
                }

                if (response.data.status == false) {
                    this.setState({
                        errMsgEmail: response.data.validation_error.email,
                    }
                    );
                    setTimeout(() => {
                        this.setState({
                            errMsgEmail: "",
                        });
                    }, 5000);
                }

            }).catch((error) => {
                console.log("enter in the catch error :", error);
            })
    }


    changePasswordHandler = (event) => {
        event.preventDefault();
        this.setState({
            isLoading: true,
        });

        var body = {
            otp: this.state.otp,
            type: 'doctor',
        };

        axios.post(process.env.REACT_APP_API_URL + 'pharmacy/UpdatePasswordByOtp', body)
            .then((response) => {
                console.log(response);
                this.setState({ isLoading: false });
                if (response.data.status) {
                    this.setState({
                        successMsg: response.data.message,
                        status: true,
                        errMsgEmail: "",
                        email: '',
                    });
                }

                if (response.data.status == false) {
                    this.setState({
                        errMsgEmail: response.data.validation_error.email,
                    }
                    );
                    setTimeout(() => {
                        this.setState({
                            errMsgEmail: "",
                        });
                    }, 5000);
                }

            }).catch((error) => {
                console.log("enter in the catch error :", error);
            })
    }









    formChangeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }




    render() {
        const status = this.state.status;
        const isLoading = this.state.isLoading;

        const calendarCss = {
            border: "0 px"
        };
        return (

            <React.Fragment>


                {status ? (
                    <div>
                        <br />
                        <div className="alert alert-success"> {this.state.successMsg}.</div>
                    </div>
                ) : (<span></span>)}
                <br />





                {status ? (
                    <form className="pt-4 mt-1" onSubmit={this.changePasswordHandler}>
                        <div className="form-row pb-3">
                            <div className="col">
                                <input placeholder="Enter Your OTP" type="text" name="otp" className="form-control" value={this.state.otp} onChange={this.formChangeHandler} />

                                <div className="text-danger">
                                    {this.state.errMsgEmail}
                                </div>
                            </div>
                        </div>

                        <div className="form-group pt-2 submitbtbn w-100">

                            <button type="submit" className="btn Fbtn-secondary btn-block"
                            > Submit {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm ml-5"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                    <span></span>
                                )}
                            </button>
                        </div>
                    </form>
                ) : (<form className="pt-4 mt-1" onSubmit={this.forgotSubmitHandler}>
                    <div className="form-row pb-3">
                        <div className="col">
                            <input placeholder="Enter Your E-Mail Id" type="email" name="email" className="form-control" value={this.state.email} onChange={this.formChangeHandler} />
                            <div className="text-danger">{this.state.errMsgEmail}</div>
                        </div>
                    </div>
                    <div className="form-group pt-2 submitbtbn w-100">

                        <button type="submit" className="btn Fbtn-secondary btn-block"
                        > Submit {isLoading ? (
                            <span
                                className="spinner-border spinner-border-sm ml-5"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        ) : (
                                <span></span>
                            )}
                        </button>
                    </div>
                </form>)

                }
            </React.Fragment>


        );
    }

}

export default ForgotPasswordPharmacy;