import React, { Component } from 'react';
import { ProtectedRoute, BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';






import Footer from './views/layouts/footer';
import About from './views/pages/About';
import Contact from './views/pages/Contact';
import Faq from './views/pages/Faq';
import HOME from './views/pages/Home';
import Thankyou from './views/pages/ThankYou';
import TermAndCondition from './views/pages/TermAndCondition';

import PatientLogin from './views/patient/login/LoginForm';
import User from './views/users/index';

// Agora 
import Agora from './views/chat/agora';


// Start Patient User Route 
import PatientDashboard from './views/patient/dashboard/dashboard';
import Appointment from './views/patient/dashboard/appointment';
import Consultation from './views/patient/dashboard/consultation';
import Prescription from './views/patient/dashboard/prescription';
import Investigation from './views/patient/dashboard/investigation';
import Payment from './views/patient/dashboard/payment';
import Invoice from './views/patient/dashboard/invoice';
import ProfileSetting from './views/patient/dashboard/profilesetting';
import SEARCHBAR from './views/patient/dashboard/searchBar';
import DoctorList from './views/patient/dashboard/doctorList';
import DoctorDetail from './views/patient/dashboard/doctorDetail';
import BookAppointment from './views/patient/dashboard/bookAppointment';
import PatientSummary from './views/patient/dashboard/patientsummary';


// End Patient

//Start Doctor
import DoctorDashboard from './views/doctor/dashboard';
import DoctorAppointment from './views/doctor/doctorappointment';
import DoctorConsultation from './views/doctor/doctorconsultation';
import DoctorConsultationView from './views/doctor/doctorconsultationview';
import PatientList from './views/doctor/patientList';
import Referrals from './views/doctor/referalList';
import DoctorPayments from './views/doctor/payment';
import DoctorFeedback from './views/doctor/feedback';
import DoctorSetting from './views/doctor/doctorsetting';
import ConsultationSummary from './views/doctor/consultationsummary';

//Start Lab
import LabDashboard from './views/lab/dashboard';
import LabReferral from './views/lab/referral';
import LabSetting from './views/lab/labsetting';


//Start Pharmacy
import PharmacyDashboard from './views/pharmacy/dashboard';
import PharmacyReferral from './views/pharmacy/referral';
import PharmacySetting from './views/pharmacy/pharmacysetting';



class App extends Component {



    constructor(props) {

        super(props);
        this.state = {

            login: false,
            logoutPage: false,
            access: false,

            user: false,
            setUser: false,
            userInput : '',

        }
    }


    handleLogout = e => {

        e.preventDefault();
        this.setState({
            user: false,
            setUser: false,
        });
    }


    logoutBtn() {

        this.setState({
            logoutPage: true,
        })


    }

    componentDidMount() {

    }

    render() {


        // Authenticate route
        const login = localStorage.getItem("isLoggedIn");
        const userType = localStorage.getItem("userType");

        const user = JSON.parse(localStorage.getItem("userData"));
        console.log("-----Get logged in user data -----", user);







        const body = document.getElementById('root');
        body.setAttribute('hidden', 'true');

        setTimeout(function () {

            const spinner = document.getElementById('spinner');
            if (spinner && !spinner.hasAttribute('hidden')) {
                spinner.setAttribute('hidden', 'true');
                const body = document.getElementById('root');
                body.removeAttribute('hidden');
            }

        }, 2000);








        return (
            <React.Fragment>


                <Router>

                    {
                        login ?
                            (

                                <div>
                                    <nav className="navbar navbar-expand-lg navbar-light bg-light p-xl-0">
                                        <Link className="navbar-brand pl-4" to="/">
                                            <img src={process.env.REACT_APP_BASE_URL + "img/logo.png"} alt="" />
                                        </Link>


                                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon"></span>
                                        </button>

                                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                            <ul className="acount-login navbar-right">


                                                {/* <li>
                                                    <Link to="/patient/dashboard">
                                                        <i className="spt-img bell-icon"></i>
                                                        <sup>4</sup>
                                                    </Link>
                                                </li> */}
                                                <li className="dropdown">



                                                    {userType == "doctor" ? (


                                                        <React.Fragment>
                                                            <Link to="/doctor/dashboard" data-toggle="dropdown" >
                                                                <span className="user-img">
                                                                    <img src={process.env.REACT_APP_BASE_URL + "img/user-icon.png"} alt=""
                                                                    />
                                                                </span>

                                                                {user.first_name} {user.last_name}
                                                            </Link>
                                                            {/* <span onClick={()=> this.logoutBtn()} data-toggle="dropdown" className="dropdown-toggle"></span> */}
                                                        </React.Fragment>
                                                    ) : ("")
                                                    }


                                                    {userType == "patient" ? (


                                                        <Link to="/patient/dashboard">
                                                            <span className="user-img">
                                                                <img src={process.env.REACT_APP_BASE_URL + "img/user-icon.png"} alt="" />
                                                            </span>{user.first_name} {user.last_name}
                                                        </Link>
                                                    ) : ("")
                                                    }

                                                    {userType == "lab" ? (
                                                            <Link to="/lab/dashboard">
                                                            <span className="user-img">
                                                                <img src={process.env.REACT_APP_BASE_URL + "img/user-icon.png"} alt="" />
                                                            </span> Lab
                                                        </Link>
                                                    ) : ("")
                                                    }

                                                    {userType == "pharmacy" ? (
                                                            <Link to="/pharmacy/dashboard">
                                                            <span className="user-img">
                                                                <img src={process.env.REACT_APP_BASE_URL + "img/user-icon.png"} alt="" />
                                                            </span> Pharmacy
                                                        </Link>
                                                    ) : ("")
                                                    }






                                                    <ul className='dropdown-menu '>
                                                        <li> <Link to="/" >Logout </Link> </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                    {userType == "patient" ?
                                        (<SEARCHBAR />) : ("")
                                    }
                                </div>) :
                            (

                                <nav className="navbar navbar-expand-lg navbar-light bg-light p-xl-0">

                                    <Link className="navbar-brand pl-4" to="/">
                                        <img src={process.env.REACT_APP_BASE_URL + "img/logo.png"} alt="" />
                                    </Link>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>

                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul className="navbar-nav m-auto">
                                            <li className="nav-item active">
                                                <Link className="nav-link" to="/">Hospitals
                                            <i className="arrow-down"> </i>
                                                    <span className="sr-only"></span>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/">Specialist
                                                <i className="arrow-down"> </i>
                                                </Link>
                                            </li>
                                            {/* <li className="nav-item">
                                                <Link className="nav-link" to="/">For Providers
                                                <i className="arrow-down"> </i>
                                                </Link>
                                            </li> */}
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/faq">Faqs</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/contact">Contact Us</Link>
                                            </li>
                                            <li className="nav-item demobtn">
                                                <Link className="nav-link" to="/">Request A Demo</Link>
                                            </li>


                                        </ul>
                                    </div>





                                    <PatientLogin />

                                </nav>)
                    }





                    <Switch>
                        <Route exact path="/" component={HOME} />
                        <Route path="/about" component={About} />
                        <Route path="/contact" component={Contact} />
                        <Route exact path="/faq" component={Faq} />
                        <Route path="/doctors" component={User} />
                        <Route path="/thank-you" component={Thankyou} />
                        <Route path="/term-and-condition" component={TermAndCondition} />

                        <Route path="/videocall/:channel" component={Agora} />

                        {userType == 'patient' ? (
                            <React.Fragment>
                                <Route path="/patient/dashboard" component={PatientDashboard} />
                                <Route path="/patient/appointment" component={Appointment} />
                                <Route path="/patient/consultation" component={Consultation} />
                                <Route path="/patient/prescription" component={Prescription} />
                                <Route path="/patient/prescriptionDetail/:id" component={PatientSummary} />
                                <Route path="/patient/investigation" component={Investigation} />
                                <Route path="/patient/payment" component={Payment} />
                                <Route path="/patient/invoice/:id" component={Invoice} />
                                <Route path="/patient/profileSetting" component={ProfileSetting} />
                                <Route exact path="/patient/member/:userId" component={ProfileSetting} />
                                <Route path="/doctorList/:search" component={DoctorList} />
                                <Route path="/patient/doctor/:doctorId" component={DoctorDetail} />
                                <Route path="/patient/bookappointment/:doctorId" component={BookAppointment} />
                            </React.Fragment>) :
                            (' ')}




                        {userType == 'doctor' ? (
                            <React.Fragment>
                                <Route path="/doctor/dashboard" component={DoctorDashboard} />
                                <Route path="/doctor/appointment" component={DoctorAppointment} />
                                <Route path="/doctor/consultation" component={DoctorConsultation} />

                                <Route path="/doctor/consultationview/:id" component={DoctorConsultationView} />
                                <Route path="/doctor/consultationDetail/:id/summary" component={ConsultationSummary} />
                                <Route path="/doctor/patients" component={PatientList} />
                                <Route path="/doctor/referrals" component={Referrals} />
                                <Route path="/doctor/payments" component={DoctorPayments} />
                                <Route path="/doctor/feedback" component={DoctorFeedback} />
                                <Route path="/doctor/settings" component={DoctorSetting} />
                            </React.Fragment>) :
                            ('')}



                        {userType == 'lab' ? (
                            <React.Fragment>
                                <Route path="/lab/dashboard" component={LabDashboard} />
                                <Route path="/lab/referral" component={LabReferral} />
                                <Route path="/lab/setting" component={LabSetting} />
                            </React.Fragment>) :
                            ('')}


                        {userType == 'pharmacy' ? (
                            <React.Fragment>
                                <Route path="/pharmacy/dashboard" component={PharmacyDashboard} />
                                <Route path="/pharmacy/referral" component={PharmacyReferral} />
                                <Route path="/pharmacy/setting" component={PharmacySetting} />
                                
                            </React.Fragment>) :
                        ('')}




                    </Switch>
                    <Footer />
                </Router>

            </React.Fragment>
        );

    }


}

export default App;