import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import LEFTNAV from "./includes/leftnav";
import axios from "axios";
import AnimatedNumber from "animated-number-react";
import jwt_decode from "jwt-decode";
import NumberFormat from 'react-number-format';



import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";





export default class Contact extends Component {
    constructor(props) {

        super(props);
        this.state = {
            id: null,
            isLoading: false,
            filterValue: 'today',
            today: 'today',
            tomorrow: 'tomorrow',
            week: 'week',

            duration: 2000,
            doctorName: '',
            navigate: false,
            appointments: [],
            totalAppointment: 0,
            totalPendingAppointment: 0,
            totalCancelledAppointment: 0,
            totalConfirmAppointment: 0,
            totalCompleteAppointment: 0,
            totalRevenue: 0,
            totalReview: 0,
            totalRating: 0,

            calendarDates: [],
            todayDate: '',
            activeId: 0,
            calendarActiveDate: '',
            day: '',
            slotsArray: [],
            emptySlotMessage: 'Slot not available',
            page: 1,
            availableDays: null,
            record: []
        };
    }

    formatValue = (value) => value.toFixed(0);


    onChangeHandler = (event) => {

        if (event.target.name == 'filterValue') {
            this.setState({
                [event.target.name]: event.target.value,
                filterValue: event.target.value
            });


            var LogedInUserObject = JSON.parse(localStorage.getItem("userData"));
            var doctorDetailId = LogedInUserObject.doctordetailId;

            var filterParameter = event.target.value;
            this.getDashboard(doctorDetailId, filterParameter);

        }
    }






    onLogoutHandler = () => {

        var doctorObject = JSON.parse(localStorage.getItem("userData"));
        var id = doctorObject.doctordetailId;


        axios.get(process.env.REACT_APP_API_URL + 'doctorLogout/' + id)
            .then((response) => {
                console.log("-------Doctor logout ----------", response);
                if (response.data.status == true) {
                    console.log("-------Doctor logout success----------", response);

                    localStorage.clear();
                    this.setState({
                        navigate: true,
                    });
                    window.location = process.env.REACT_APP_BASE_URL;
                }
                else {
                    console.log("Failed to doctor logout");
                }
            })
            .catch((error) => {
                console.log("Catch error doctor logout :", error);
            });
    }


    getAppointmentList(id) {
        console.log("Get doctor Id :", id);

        axios.get(process.env.REACT_APP_API_URL + 'doctor/getUpcomingList/' + id)
            .then((response) => {
                console.log("--------Appointment list response ----------", response);
                if (response.data.status == true) {
                    this.setState({
                        appointments: response.data.result
                    });
                } else {
                    console.log("Failed Api request");
                }
            })
            .catch((error) => {
                console.log("Doctor Appointment  catch List");
            });
    }

    getDashboard(id, search) {

        // Pass token in the header
        let auth_token = localStorage.getItem('token');
        let header_token = { 'x-access-token': auth_token };

        var body = {
            id: id,
            search: search,
        }

        console.log("Body :", body);


        axios.post(process.env.REACT_APP_API_URL + 'doctor/getDashboard', body,
            { headers: header_token }).then((response) => {

                console.log("------------Dashboard response -----------------", response);

                if (response.data.status == true) {


                    this.setState({
                        totalAppointment: response.data.totalAppointment,
                        totalPendingAppointment: response.data.totalAppointmentPending,
                        totalCompleteAppointment: response.data.totalAppointmentComplete,
                        totalCancelledAppointment: response.data.cancelledAppointment,
                        totalConfirmAppointment: response.data.confirmAppointment,
                        totalRevenue: response.data.totalRevenue,
                        totalReview: response.data.totalReview,
                        totalRating: response.data.totalRating,
                    })
                }

            })
            .catch((error) => {
                console.log("Catch error dashboard ");
            });
    }



    nextPrevBtn(page) {

        this.setState({
            page: page,
        })
    }


    handleClick(event, date) {
        var selectedDate = date;
        // show selected date
        this.setState({
            activeId: event,
            calendarActiveDate: selectedDate,
            isLoading: true,
            slotsArray: '',
            emptySlotMessage: '',
        })

        setTimeout(() => {
            this.getCalenderSlot(selectedDate);
        }, 1000);


    }

    getCalenderSlot(date) {

        var newDate = new Date(date);
        var newDay = newDate.getDate();
        var newMonth = newDate.getMonth() + 1;
        var newYear = newDate.getFullYear();
        var requestDate = newYear + '-' + newMonth + '-' + newDay;

        var body = {
            id: this.state.id,
            date: requestDate,
        }



        axios.post(process.env.REACT_APP_API_URL + 'getDoctorCalendar', body)
            .then((response) => {
                console.log(response);
                if (response.data.status == true) {
                    this.setState({
                        isLoading: false,
                        slotsArray: response.data.Calendar_Slots,
                    });
                }

                if (response.data.Calendar_Slots.length == 0) {
                    this.setState({
                        emptySlotMessage: 'Slot not available',
                    });
                }

            }).catch((error) => {
                console.log("catch error", error);
            })

    }

    getCalenderDate(id) {

        var body = {
            id: id,
        }

        axios.post(process.env.REACT_APP_API_URL + 'getDoctorCalendarDate', body)
            .then((response) => {

                console.log("Get calendar data :", response.data);

                if (response.data.status === true) {

                    this.setState({
                        calendarDates: response.data.CalendarDate,
                        availableDays: response.data.calendarAvailableDay,
                        calendarActiveDate: response.data.CalendarDate[0].date,
                    });

                    this.getCalenderSlot(response.data.CalendarDate[0].date);


                } else {


                }
            }).catch((error) => {
                console.log("Catch error in the calendar date :", error);
            });
    }

    componentDidMount() {


        const user = JSON.parse(localStorage.getItem("userData"));

        if (user) {
            
            this.setState({
                doctorName: user.first_name,
                id: user.doctordetailId,
            });
            
            var doctorDetailId = user.doctordetailId;
            this.getAppointmentList(doctorDetailId);
            this.getDashboard(doctorDetailId, 'today');
            this.getCalenderDate(user.doctordetailId);
        }


        // Manage Logout when referesh the page 
        if(localStorage.getItem('token')) {
            const jwt_Token_decoded = jwt_decode(localStorage.getItem('token'));
            console.log("decode token :", jwt_Token_decoded);
            if (jwt_Token_decoded.exp * 1000 < Date.now()) {
                localStorage.clear();
                window.location = process.env.REACT_APP_BASE_URL;
                console.log("----- logout user -----");
            } 
        }



    }






    render() {





        const slots = this.state.slotsArray;

        const recordnotfound = {
            "textAlign": "center", "color": "red"
        }
        console.log("render appointment list :", this.state.appointments);
        const calenderMessage = {
            color: "red",
            textAlign: "center",
            margin: "70px 0px 70px 0px",

        };

        const btn = { "cursor": "pointer" }


        const { navigate } = this.state;
        // Authenticate
        const login = localStorage.getItem("isLoggedIn");
        if (!login) {
            return <Redirect to="/" />;
        }
        if (navigate) {
            return <Redirect to="/" />;
        }







        const columns = [

            {
                name: "Appointment No.",
                selector: "appointmentNo",
                sortable: true
            },
            {
                name: "Patient Name",
                selector: "first_name",
                cell: appointment => <span> <span className="patientName">
                    <b className="captilize">{appointment.first_name} {appointment.last_name}</b>
                </span>
                    <p><span className="age">{appointment.age} yrs</span> | <span className="jendeer">{appointment.gender}</span></p>
                </span>,
            },
            {
                name: "Date",
                selector: "appointmentDate",
                sortable: true,

            },
            {
                name: "Time",
                selector: "appointment_time",
                sortable: true
            },

            {
                name: "Symptoms",
                selector: "symptoms",
                sortable: true
            },
            {
                name: "Consult Type",
                selector: "consult_type",
                sortable: true,
                cell: appointment => <span>{appointment.consult_type == 1 ? 'Online' : 'In-Clinic'}</span>,
            },
            {
                name: "Status",
                selector: "appointmentStatus",
                sortable: true,
                cell: d => <span>{d.appointmentStatus == 1 ? (<span className="cancelled">PENDING </span>) : ('')} {d.appointmentStatus == 2 ? (<span className="confirm">CONFIRM </span>) : ('')}</span>,
            }

        ];


        const data = this.state.appointments;
        const tableData = { columns, data };


        return (
            <React.Fragment>
                <section className="doctor-consultation-view doctor-main-section">
                    <div className="container-fulid">
                        <div>
                            <LEFTNAV />
                            <div className="content-wrapper">
                                <div className="patient-appointment-list-right-col dr-dashboard">
                                    <div className="row">
                                        <div className="col-sm-7">
                                            <h2>Welcome  {this.state.doctorName}, Here is your summary for {this.state.filterValue}.</h2>
                                        </div>
                                        <div className="col-sm-5">
                                            <div className="appointments-list-add-col">
                                                <div className="add-member-btn">
                                                    <form className="select-day">
                                                        <select name="filterValue" className="form-select" onChange={this.onChangeHandler}>

                                                            {/* <option value={this.state.tomorrow}>Tomorrow</option>
                                                            <option value={this.state.week}>Last Week</option> */}

                                                            <option value={this.state.today} >Today</option>
                                                            <option value="tomorrow" >Tomorrow</option>
                                                            <option value="All">All</option>

                                                            {/* <option>Week</option>
                                                        <option>Month</option> */}
                                                        </select>
                                                        <span className="spt-img bold-calender-icon"></span>
                                                        <span className="spt-img down-icon"></span>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dashboard-tab">
                                        <a href="#"><i className="spt-img appointments-big-icon"></i> Total
                                    <AnimatedNumber
                                                value={this.state.totalAppointment}
                                                formatValue={this.formatValue}
                                                duration={this.state.duration} />
                                        </a>
                                        <a href="#"><i className="spt-img appointments-big-icon"></i> Pending
                                    <AnimatedNumber
                                                value={this.state.totalPendingAppointment}
                                                formatValue={this.formatValue}
                                                duration={this.state.duration} /></a>

                                        <a href="#"><i className="spt-img appointments-big-icon"></i> Cancelled
                                    <AnimatedNumber
                                                value={this.state.totalCancelledAppointment}
                                                formatValue={this.formatValue}
                                                duration={this.state.duration} /></a>

                                        <a href="#"><i className="spt-img appointments-big-icon"></i> Confirm
                                    <AnimatedNumber
                                                value={this.state.totalConfirmAppointment}
                                                formatValue={this.formatValue}
                                                duration={this.state.duration} /> </a>

                                        <a href="#"><i className="spt-img appointments-big-icon"></i> Completed
                                    <AnimatedNumber
                                                value={this.state.totalCompleteAppointment}
                                                formatValue={this.formatValue}
                                                duration={this.state.duration} /> </a>

                                        <a href="#"><i className="spt-img payment-big-icon"></i> Total  Revenue  
                                        
                                        
                                        <NumberFormat value={this.state.totalRevenue} displayType={'text'} thousandSeparator={true} prefix={' '} />
                                        {/* <AnimatedNumber
                                            value= {this.state.totalRevenue}
                                            formatValue={this.formatValue}
                                            duration={this.state.duration} /> */}
                                        </a>



                                        <a href="#" className="rating-list">Rating by Patients
                                        <div className="rating-col">



                                                {this.state.totalRating == 0 ?
                                                    (
                                                        <div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star01" classs="describe-input" />
                                                                <label className="rating-star spt-img" htmlFor="star01"></label>
                                                            </div> <div className="check-item">
                                                                <input type="checkbox" name="star02" classs="describe-input" />
                                                                <label className="rating-star spt-img" htmlFor="star02"></label>
                                                            </div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star03" classs="describe-input" />
                                                                <label className="rating-star spt-img" htmlFor="star03"></label>
                                                            </div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star04" classs="describe-input" />
                                                                <label className="rating-star spt-img" htmlFor="star04"></label>
                                                            </div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star05" classs="describe-input" />
                                                                <label className="rating-star spt-img" htmlFor="star05"></label>
                                                            </div>
                                                        </div>) :
                                                    ('')}


                                                {this.state.totalRating == 1 ?
                                                    (
                                                        <div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star01" value="" id="star01" classs="describe-input" checked />
                                                                <label className="rating-star spt-img" for="star01"></label>
                                                            </div> <div className="check-item">
                                                                <input type="checkbox" name="star02" classs="describe-input" />
                                                                <label className="rating-star spt-img" for="star02"></label>
                                                            </div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star03" classs="describe-input" />
                                                                <label className="rating-star spt-img" for="star03"></label>
                                                            </div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star04" classs="describe-input" />
                                                                <label className="rating-star spt-img" for="star04"></label>
                                                            </div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star05" classs="describe-input" />
                                                                <label className="rating-star spt-img" for="star05"></label>
                                                            </div>
                                                        </div>) :
                                                    ('')}


                                                {this.state.totalRating == 2 ?
                                                    (
                                                        <div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star01" value="" id="star01" classs="describe-input" checked />
                                                                <label className="rating-star spt-img" for="star01"></label>
                                                            </div> <div className="check-item">
                                                                <input type="checkbox" id="star02" name="star02" classs="describe-input" checked />
                                                                <label className="rating-star spt-img" for="star02"></label>
                                                            </div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star03" classs="describe-input" />
                                                                <label className="rating-star spt-img" for="star03"></label>
                                                            </div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star04" classs="describe-input" />
                                                                <label className="rating-star spt-img" for="star04"></label>
                                                            </div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star05" classs="describe-input" />
                                                                <label className="rating-star spt-img" for="star05"></label>
                                                            </div>
                                                        </div>) :
                                                    ('')}


                                                {this.state.totalRating == 3 ?
                                                    (
                                                        <div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star01" value="" id="star01" classs="describe-input" checked />
                                                                <label className="rating-star spt-img" for="star01"></label>
                                                            </div> <div className="check-item">
                                                                <input type="checkbox" name="star02" classs="describe-input" id="star02" checked />
                                                                <label className="rating-star spt-img" for="star02"></label>
                                                            </div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star03" classs="describe-input" id="star03" checked />
                                                                <label className="rating-star spt-img" for="star03"></label>
                                                            </div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star04" classs="describe-input" />
                                                                <label className="rating-star spt-img" for="star04"></label>
                                                            </div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star05" classs="describe-input" />
                                                                <label className="rating-star spt-img" for="star05"></label>
                                                            </div>
                                                        </div>) :
                                                    ('')}


                                                {this.state.totalRating == 4 ?
                                                    (
                                                        <div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star01" value="" id="star01" classs="describe-input" checked />
                                                                <label className="rating-star spt-img" for="star01"></label>
                                                            </div> <div className="check-item">
                                                                <input type="checkbox" name="star02" classs="describe-input" id="star02" checked />
                                                                <label className="rating-star spt-img" for="star02"></label>
                                                            </div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star03" classs="describe-input" id="star03" checked />
                                                                <label className="rating-star spt-img" for="star03"></label>
                                                            </div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star04" classs="describe-input" id="star04" checked />
                                                                <label className="rating-star spt-img" for="star04"></label>
                                                            </div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star05" classs="describe-input" />
                                                                <label className="rating-star spt-img" for="star05"></label>
                                                            </div>
                                                        </div>) :
                                                    ('')}



                                                {this.state.totalRating == 1 ?
                                                    (
                                                        <div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star01" value="" id="star01" classs="describe-input" checked />
                                                                <label className="rating-star spt-img" for="star01"></label>
                                                            </div> <div className="check-item">
                                                                <input type="checkbox" name="star02" classs="describe-input" id="star02" checked />
                                                                <label className="rating-star spt-img" for="star02"></label>
                                                            </div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star03" classs="describe-input" id="star03" checked />
                                                                <label className="rating-star spt-img" for="star03"></label>
                                                            </div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star04" classs="describe-input" id="star04" checked />
                                                                <label className="rating-star spt-img" for="star04"></label>
                                                            </div>
                                                            <div className="check-item">
                                                                <input type="checkbox" name="star05" classs="describe-input" id="star05" checked />
                                                                <label className="rating-star spt-img" for="star05"></label>
                                                            </div>
                                                        </div>) :
                                                    ('')}

                                                <div className="check-item count-dblock">
                                                    <p className="count-rating">({this.state.totalReview})</p>
                                                </div>


                                            </div>
                                        </a>

                                        <Link to="/" onClick={this.onLogoutHandler} style={btn}>
                                            <span className="spt-img Logout-icon"><br /><br /></span> <span> Logout </span>
                                        </Link>
                                    </div>


                                    <div className="list-table">
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#upcoming">Calendar
                                            </a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="upcoming" className="tab-pane active">
                                                <div className="table-responsive">

                                                    <div class="timeslot-row pt-xl-4">
                                                        <div class="slot_inner">
                                                            <ul>

                                                                <li>
                                                                    {this.state.page == 1 ? ('') : (<Link to="#" onClick={() => this.nextPrevBtn(this.state.page - 1)}> &lt; </Link>)}
                                                                </li>

                                                                {this.state.page == 1 ? (
                                                                    this.state.calendarDates.map((object, index) => (
                                                                        index < 7 ? (<li key={object.date}>
                                                                            <Link className={this.state.activeId === index ? 'active' : ' '}
                                                                                onClick={this.handleClick.bind(this, index, object.date)} >
                                                                                {object.day}
                                                                                <span> {object.date} </span>
                                                                            </Link>
                                                                        </li>) : ('')
                                                                    ))) : ('')}


                                                                {this.state.page == 2 ? (
                                                                    this.state.calendarDates.map((object, index) => (
                                                                        index > 6 && index < 14 ? (<li key={object.date}>
                                                                            <Link className={this.state.activeId === index ? 'active' : ' '}
                                                                                onClick={this.handleClick.bind(this, index, object.date)} >
                                                                                {object.day}
                                                                                <span> {object.date} </span>
                                                                            </Link>
                                                                        </li>) : ('')
                                                                    ))) : ('')}


                                                                {this.state.page == 3 ? (
                                                                    this.state.calendarDates.map((object, index) => (
                                                                        index > 13 && index < 21 ? (<li key={object.date}>
                                                                            <Link className={this.state.activeId === index ? 'active' : ' '}
                                                                                onClick={this.handleClick.bind(this, index, object.date)} >
                                                                                {object.day}
                                                                                <span> {object.date} </span>
                                                                            </Link>
                                                                        </li>) : ('')
                                                                    ))) : ('')}


                                                                {this.state.page == 4 ? (
                                                                    this.state.calendarDates.map((object, index) => (
                                                                        index > 20 && index < 28 ? (<li key={object.date}>
                                                                            <Link className={this.state.activeId === index ? 'active' : ' '}
                                                                                onClick={this.handleClick.bind(this, index, object.date)} >
                                                                                {object.day}
                                                                                <span> {object.date} </span>
                                                                            </Link>
                                                                        </li>) : ('')
                                                                    ))) : ('')}


                                                                {this.state.page == 5 ? (
                                                                    this.state.calendarDates.map((object, index) => (
                                                                        index > 27 && index < 34 ? (<li key={object.date}>
                                                                            <Link className={this.state.activeId === index ? 'active' : ' '}
                                                                                onClick={this.handleClick.bind(this, index, object.date)} >
                                                                                {object.day}
                                                                                <span> {object.date} </span>
                                                                            </Link>
                                                                        </li>) : ('')
                                                                    ))) : ('')}





{/* 
                                                                <li>

                                                                    {this.state.availableDays <= 7 ? ('') : ('')}

                                                                    {this.state.availableDays > 7 && this.state.page <= 4 ? (<Link to="#" onClick={() => this.nextPrevBtn(this.state.page + 1)}> &gt; </Link>) : ('')}



                                                                    

                                                                </li> */}


                                                                
                                                                {this.state.availableDays > 7 && this.state.availableDays < 15 ? (<li>
                                                                        {this.state.page >= 2 ? ('') : (<Link to="#" onClick={() => this.nextPrevBtn(this.state.page + 1)}> &gt; </Link>)}
                                                                    </li>) : (<li> </li>)}

                                                                {this.state.availableDays > 14 && this.state.availableDays < 22 ? (<li>
                                                                        {this.state.page >= 3 ? ('') : (<Link to="#" onClick={() => this.nextPrevBtn(this.state.page + 1)}> &gt; </Link>)}
                                                                    </li>) : ('')}

                                                                {this.state.availableDays > 21 && this.state.availableDays < 29 ? (<li>
                                                                        {this.state.page >= 4 ? ('') : (<Link to="#" onClick={() => this.nextPrevBtn(this.state.page + 1)}> &gt; </Link>)}
                                                                    </li>) : ('')} 


                                                            </ul>
                                                        </div>
                                                    </div>




                                                    <div class="timeslot py-xl-4">
                                                        {this.state.isLoading ? (<p style={calenderMessage}>
                                                            <span
                                                                className="spinner-border spinner-border-sm ml-5"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span></p>) : (' ')}


                                                        {slots.length == 0 ?
                                                            (<p style={calenderMessage}>    {this.state.emptySlotMessage} </p>)
                                                            : (<ul>
                                                                {slots.map((slot, index) => (
                                                                    <li>
                                                                        {slot.Available == null ? (<Link className="bookedslot" title="Booked">{slot.Booked}</Link>) : (
                                                                            <Link title="Available">{slot.Available}</Link>)}
                                                                    </li>
                                                                ))
                                                                }</ul>)}
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                    </div>


                                    <div className="list-table">
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#upcoming">Upcoming Appointment
                                            </a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="upcoming" className="tab-pane active">
                                                <div className="table-responsive">



                                                    {this.state.appointments.length > 0 ? (<DataTableExtensions {...tableData}>
                                                        <DataTable
                                                            title="Upcomming Appointment"
                                                            columns={columns}
                                                            data={data}
                                                            noHeader
                                                            defaultSortField="id"
                                                            defaultSortAsc={false}
                                                            pagination
                                                            paginationPerPage={10}
                                                            highlightOnHover
                                                            className="table"
                                                            responsive
                                                        />
                                                    </DataTableExtensions>) : (<div className="table">
                                                        <div className="datanotAvailable"> There are no records to display </div>
                                                    </div>)}


                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}