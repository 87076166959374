import React from 'react';
import { Redirect, Link, NavLink } from 'react-router-dom';
// import CommonApi from "../common-service/service";

import axios from "axios";
import jwt_decode from "jwt-decode";

class leftNav extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            memberId:''
        };
    }


    getMemberList() {
        var patientDetail = JSON.parse(localStorage.getItem("userData"));
        if (patientDetail) {

            var menberId = patientDetail.id;

            console.log("Patient Id :", menberId);

            axios.get(process.env.REACT_APP_API_URL + "getMemberList/" + menberId)
                .then((response) => {
                     if (response.data.status === true) {
                        this.setState({
                            data: response.data.result,
                        });

                    } else {
                        console.log(response);
                    }
                })
                .catch((error) => {
                    console.log('catch error');
                    console.log(error);
                });
        }

    }



    componentDidMount() {
        // this.getMemberList();

        // Manage Logout when referesh the page 
        if(localStorage.getItem('token')) {
            const jwt_Token_decoded = jwt_decode(localStorage.getItem('token'));
            console.log("decode token :", jwt_Token_decoded);
            if (jwt_Token_decoded.exp * 1000 < Date.now()) {
                localStorage.clear();
                window.location = process.env.REACT_APP_BASE_URL;
                console.log("----- logout user -----");
            } 
        }

    }

    onChangeHandler = (event) => {
        this.setState({ memberId : event.target.value });

        // this.getMember(event.target.value);
        
        // window.location.reload();
    }


    getMember(id) {
        console.log("--------Get Patient data 1--------", id);
        axios.get(process.env.REACT_APP_API_URL + "patient/" + id)
        .then((response) => {
                console.log("--------Get Patient data 3--------", response);
                alert("Member update successfully");
                localStorage.setItem("selectedUser", JSON.stringify(response.data[0]));
                
                /*this.setState({
                    patient: response.data[0],
                });
                */
        })
        .catch((error) => {
            console.log('catch error', error);
        });

}

    render() {

         // Redirect User if not login
         const login = localStorage.getItem("isLoggedIn");
         if (!login) {
             return <Redirect to="/" />;
         }

        if (this.state.memberId) {
            localStorage.setItem("filterByUserId", this.state.memberId);
            
            // this.getMember(this.state.memberId);
            window.location.reload();
        }

        const getSelectedId = localStorage.getItem("filterByUserId");
        // this.getMember(getSelectedId);

        
        var user = JSON.parse(localStorage.getItem("userData"));

        var selectedMember = '';
        selectedMember = JSON.parse(localStorage.getItem("selectedUser"));
        if(selectedMember!=null){
            user = selectedMember;
        }

        return (

            <aside className="main-sidebar">
                <section className="sidebar">
                    <div className="side-nav">
                        <div className="mamber-section">
                            <div className="mamber-pro">
                                <span className="member-img">

                                    {user.profile_pic == null ?
                                        (<img alt="image-not-found" src={process.env.REACT_APP_BASE_URL + process.env.REACT_APP_IMAGE_ICON} />
                                        )
                                        :
                                        (
                                            <img alt="member-img"  src={process.env.REACT_APP_IMAGE_LOCATION + "patient/profile_picture/" + user.profile_pic} />
                                        )
                                    }


                                </span>

                                <span className="mamber-info">
                                    <h3 className="captilize">{user.first_name} {user.last_name} </h3>
                                    <p><span className="age">{user.age} yrs  </span>
                                        {/* <span className="jendeer">{user.gender}</span> */}
                                    </p>
                                    {/* <p>Myself</p> */}
                                </span>
                            </div>
                            <div className="change-mamber">
                                {/* <form>
                                    <select className="form-select" name="memberId" onChange={this.onChangeHandler}>
                                        
                                        {this.state.data.map((member,index)=>
                                            (<option value={member.id} selected={member.id == getSelectedId ? "selected" : ""}  key={index}>  {member.first_name} {member.last_name} </option>)
                                        )}
                                    </select>
                                    <span className="spt-img down-icon"></span>
                                </form> */}
                            </div>
                            <br />
                        </div>
                        <ul>

                            <li>
                                <Link to="/patient/dashboard">
                                    <span className="spt-img dashboard-icon"></span> Dashboard
                                </Link>
                            </li>


                            <li>
                                <NavLink to="/patient/appointment" activeClassName="active">
                                    <span className="spt-img appointments-icon"></span> Appointments
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/patient/consultation" activeClassName="active">
                                    <span className="spt-img consultations-icon"></span> Consultations
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/patient/prescription" activeClassName="active">
                                    <span className="spt-img prescriptions-icon"></span> Prescriptions
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/patient/investigation" activeClassName="active">
                                    <span className="spt-img investigations-icon"></span> Investigations
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/patient/payment" activeClassName="active">
                                    <span className="spt-img payment-icon"></span> Payments
                                </NavLink>
                            </li>
                            {/* <li>
                                <Link to="/">
                                    <span className="spt-img favourites-icon"></span> Favourites
                                </Link>
                            </li> */}
                            {/* <li>
                                <NavLink to="/patient/profileSetting" activeClassName="active">
                                    <span className="spt-img Profile-icon"></span> Profile Setting
                                </NavLink>
                            </li> */}
                        </ul>
                    </div>
                </section>
            </aside>



        )

    }
}





export default leftNav;