import React, { Component } from 'react';
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import NumberFormat from 'react-number-format';


class PatientSummary extends Component {

    constructor(props) {
        super(props);
        this.state = {

            modelAppointmentDoctorDetailId: '',
            modelAppointmentMemberContactId: '',
            modelAppointmentId: '',
            modelAppointmentNo: '',
            modelPatientName: '',
            modelGender: '',
            modelAge: '',
            modelSymptoms: '',
            modelDocuments: '',
            modelDate: '',
            modelTime: '',
            modelConsultType: '',
            modelStatus: '',

            doctorName: '',
            doctorExperience: '',
            doctorFee: '',
            doctorRegistration_no: '',
            doctorQualification: '',
            doctorSpecializations: '',
            doctorSignature : '',

            investigations: [],
            prescriptions: [],

        };
    }


    getAppointmentDetail(id) {

        axios.get(process.env.REACT_APP_API_URL + 'doctor/getAppointmentDetails/' + id)
            .then((response) => {

                console.log("get appointment detail:", response);
                if (response.data.status === true) {


                    this.setState({
                        modelAppointmentId: response.data.result[0].appointmentId,
                        modelAppointmentDoctorDetailId: response.data.result[0].doctordetail_id,
                        modelAppointmentMemberContactId: response.data.result[0].membercontact_id,
                        modelAppointmentNo: response.data.result[0].appointmentNo,
                        modelPatientName: response.data.result[0].first_name + ' ' + response.data.result[0].last_name,
                        modelGender: response.data.result[0].gender,
                        modelAge: response.data.result[0].age,
                        modelSymptoms: response.data.result[0].symptoms,
                        modelDocuments: response.data.result[0].documents,
                        modelDate: response.data.result[0].appointmentDate,
                        modelTime: response.data.result[0].appointment_time,
                        modelConsultType: response.data.result[0].consult_type,
                        modelStatus: response.data.result[0].appointmentStatus,
                    })

                    this.getDoctorInfo(response.data.result[0].doctordetail_id);

                } else {
                    console.log("response failed for single object");
                }

            }).catch((error) => {

                console.log("catch error : ", error);

            });
    }


    getDoctorInfo(id) {

        axios.get(process.env.REACT_APP_API_URL + "getDoctorDetail/" + id)
            .then((response) => {

                if (response.data.status) {
                    console.log("Get Doctor Details 333: ", response.data.result[0]);
                    this.setState(
                        {
                            doctorName: response.data.result[0].name,
                            doctorExperience: response.data.result[0].experience,
                            doctorFee: response.data.result[0].fee,
                            doctorRegistration_no: response.data.result[0].registration_no,
                            doctorQualification: response.data.result[0].qualifications,
                            doctorSpecializations: response.data.result[0].specializations,
                            doctorSignature: response.data.result[0].signature,


                        },
                    );
                }
            })
            .catch((error) => {
                console.log('enter catch error');
                console.log(error);
            });
    }


    getInvestigation(id) {

        axios.get(process.env.REACT_APP_API_URL + 'doctor/getInvestigationList/' + id)
            .then((response) => {
                console.log("GET INVETIGATION : ", response.data.result);

                if (response.data.status === true) {
                    this.setState({
                        investigations: response.data.result
                    })
                }
                else {
                    console.log("response failed for Investigation");
                }

            }).catch((error) => {
                console.log("catch error : ", error);
            });
    }

    getPrescription(id) {

        axios.get(process.env.REACT_APP_API_URL + 'doctor/getPriscriptionList/' + id)
            .then((response) => {

                console.log("get prescription :", response);
                if (response.data.status === true) {

                    this.setState({
                        prescriptions: response.data.result
                    })
                }
                else {
                    console.log("response failed for prescription");
                }

            }).catch((error) => {

                console.log("catch error : ", error);

            });
    }




    componentDidMount() {


        var id = this.props.match.params.id;
        this.getAppointmentDetail(id);
        // this.getDoctorInfo(memberId);
        this.getInvestigation(id);
        this.getPrescription(id);




        // var LogedInUserObject = JSON.parse(localStorage.getItem("userData"));


        /*
        if (LogedInUserObject) {
           
           var memberId = LogedInUserObject.memberId;
        
           var id = this.props.match.params.id;
           this.getAppointmentDetail(id);
           this.getDoctorInfo(memberId);
           this.getInvestigation(id);
           this.getPrescription(id);
        }
        */
    }

    printReceipt() {
        window.print();
    }

    render() {

        return (
            <React.Fragment>
                <section class="invoce-sec patient-main-section">
                    <div class="container">

                        <div class="invoice-content consultationInvoice">
                            <div class="invoice-item">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="invoice-logo">
                                            <img src={process.env.REACT_APP_BASE_URL + "img/logo.png"} alt="" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <p class="invoice-details">
                                            <br />

                                            <strong>Consultation :</strong> #{this.state.modelAppointmentNo} <br />
                                            <strong>Appointment Date And Time :</strong> {this.state.modelDate} <nbsp /><nbsp />
                                            {this.state.modelTime}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="invoice-item">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="invoice-info">
                                            <strong class="customer-text">Patient Details</strong>
                                            <p class="invoice-details invoice-details-two captilize">
                                                {this.state.modelPatientName}  <nbsp />

                                                <br />
                                                {this.state.modelGender} <nbsp />
                                                {this.state.modelAge} <nbsp />
                                                <br />
                                                {this.state.modelSymptoms} <nbsp />

                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="invoice-info invoice-info2">
                                            <strong class="customer-text">Doctor Details</strong>
                                            <p class="invoice-details">
                                                Dr. {this.state.doctorName}  <br />

                                                {this.state.doctorQualification} <br />
                                                {this.state.doctorSpecializations}

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="invoice-item">
                                <div class="row">
                                    <div class="col-sm-12">


                                        {this.state.investigations.length > 0 ? (
                                            <div class="list-table">
                                                <strong class="customer-text">Investigation(s)</strong>
                                                <div class="table-responsive investigation">
                                                    <table class="table">
                                                        <thead class="thead-dark">
                                                            <tr>
                                                                <th>Serial No.</th>
                                                                <th>Lab</th>
                                                                <th>Test</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {this.state.investigations.map((investigation, index) => (
                                                                <tr>

                                                                    <td> {index + 1} </td>
                                                                    <td> {investigation.name} </td>
                                                                    <td> {investigation.advise_test} </td>
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>) : ('')}



                                        {this.state.prescriptions.length > 0 ? (

                                            <div class="list-table">
                                                <strong class="customer-text">Prescription(s)</strong>
                                                <div class="table-responsive">
                                                    <table class="table prescription">
                                                        <thead class="thead-dark">
                                                            <tr>
                                                                <th>Serial No.</th>
                                                                <th>Dosage</th>
                                                                <th>No. of times in a day</th>
                                                                <th>No. of days</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.prescriptions.map((prescription, index) => (<tr>
                                                                <td> {index + 1}</td>
                                                                <td> {prescription.dose}</td>
                                                                <td> {prescription.no_of_time_in_day}</td>
                                                                <td> {prescription.no_of_days}</td>
                                                            </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : ('')}


                                    </div>

                                    <div class="col-sm-6 col-xl-4 ml-auto">
                                        <div class="table-responsive">
                                            <table class="invoice-table-two table">
                                                <tbody>

                                                    <tr>
                                                        <th>
                                                            <Link to="/patient/prescription">
                                                                <button type="button" className="btn btn-outline-primary">Back To Consultation</button>
                                                            </Link>
                                                        </th>
                                                        <td>
                                                            <button onClick={this.printReceipt} type="button" className="btn btn-outline-primary">Print / Download</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>


                                    <div class="col-sm-12">
                                      { this.state.doctorSignature ? (
                                        <div class="col-4">
                                            <label>Doctor's Signature</label> <br/>
                                            <img src={ process.env.REACT_APP_ADMIN_BASE_URL+'images/doctor/signature/'+ this.state.doctorSignature} alt="signature" />
                                            <br/>
                                            <label>Dr. {this.state.doctorName} </label>

                                        </div>): (' ') }
                                    </div>


                                </div>
                            </div>
                            <br />
                            <br />

                        </div>
                    </div>
                </section></React.Fragment>

        )

    }
}

export default PatientSummary;