import React, { Component } from 'react';
import LEFTNAV from "./leftnav";
import axios from "axios";
import { Link } from "react-router-dom";
import NumberFormat from 'react-number-format';



import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

class Payment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            transections: [],
        };
    }


    getPaymentHistory() {

        // var patientDetail = JSON.parse(localStorage.getItem("userData"));
        var filterByUserId = localStorage.getItem("filterByUserId");
        if (filterByUserId) {

            // var memberId = patientDetail.id;
            axios.get(process.env.REACT_APP_API_URL + "getTransectionList/" + filterByUserId)
                .then((response) => {



                    if (response.data.status === true) {
                        this.setState({
                            transections: response.data.result,
                        });

                    } else {
                        console.log(response);
                    }
                })
                .catch((error) => {
                    console.log('Get transection list Api catch error');
                    console.log(error);
                });
        }

    }




    componentDidMount() {
        this.getPaymentHistory();
    }



    render() {





        const columns = [

            // {
            //     name: "Transaction ID",
            //     sortable: true,
            //     selector: "transectionId",
            //     width: "170px",
            //     cell: appointment => <span>DPP{appointment.transectionId}</span>,
            // },
            {
                name: "Receipt No.",
                sortable: true,
                selector: "invoiceId",
                width: "150px",

            },
            {
                name: "Date of Payment",
                selector: "transectionDate",
                sortable: true,


            },
            {
                name: "Doctor Name",
                width: "200px",
                selector: "doctor_name",
                sortable: true,
                cell: appointment => <span>
                    <span className="captilize"> <b> Dr.  {appointment.doctor_name} </b> {appointment.doctor_specialization} </span>
                </span>,

            },


            {
                name: "Payment Mode",
                selector: "type",
                sortable: true,

            },
            {
                name: "Amount (INR)",
                selector: "amount",
                sortable: true,
                cell:d=><span><NumberFormat value={d.amount} displayType={'text'} thousandSeparator={true} prefix={''} /></span>

            },
            {
                name: "Receipt",
                selector: "status",
               
                cell: d => <span><a href={`/patient/invoice/${d.invoiceId}`}>
                    <button type="button" className="btn btn-outline-primary"> Receipt </button>
                </a>
                </span>,
            }

        ];


        const data = this.state.transections;
        const tableData = { columns, data };

        return (
            <section className="patient-appointment-section patient-main-section">
                <div className="container">
                    <div>
                        <LEFTNAV />
                        <div className="content-wrapper">
                            <div className="patient-appointment-list-right-col">
                                <div className="col-md-12">
                                    <h2><span><i className="spt-img calendar-icon"></i></span> Payments</h2>
                                    {/* <form className="appointments-list-search">
                                        <input type="text" name="appointments-list-search" className="form-control"/>
                                        <button type="submit" className="ap-list-search"><i className="spt-img search-icon"></i></button>
                                    </form> */}
                                </div>
                                <div className="list-table d-inline-block w-100">
                                    <div className="table-responsive">



                                        {this.state.transections.length > 0 ? (<DataTableExtensions {...tableData}>
                                            <DataTable
                                                title="Transection"
                                                columns={columns}
                                                data={data}
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                paginationPerPage={10}
                                                className="table"
                                                responsive
                                            />
                                        </DataTableExtensions>) : (<div className="table">
                                            <div className="datanotAvailable"> There are no records to display </div>
                                        </div>)}


                                        {/* <table className="table">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th>Transection ID.</th>
                                                    <th>Receipt No.</th>
                                                    <th>Date of Payment</th>
                                                    <th>Doctor</th>
                                                    <th>Payment Mode</th>
                                                    <th>Amount </th>
                                                    <th>Receipt </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.transections.map((transection, index) => (
                                                    <tr>

                                                        <td>DPP{transection.transectionId}</td>
                                                        <td>{transection.invoiceId}</td>
                                                        <td>{transection.transectionDate}</td>
                                                        <td>
                                                            <table className="profile-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="doctor-pro"></td>
                                                                        <td><b>{transection.doctor_name}</b><br />{transection.doctor_specialization}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td>  {transection.type} </td>
                                                        <td>  {transection.amount} </td>
                                                        <td className="text-right">
                                                            
                                                            <Link to={`/patient/invoice/${transection.invoiceId}`}>
                                                                <button type="button" className="btn btn-outline-primary"> Receipt </button>
                                                            </Link>
                                                        </td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        )

    }
}

export default Payment;