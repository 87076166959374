import React, { Component } from 'react';
import axios from "axios";
import { withRouter } from 'react-router-dom'; 

class searchBar extends Component {

    
   
      state = {
        activeOption: 0,
        filteredOptions: [],
        showOptions: false,
        userInput: '',
        searchLists:'',
        searchstatus:false,
      };


    componentDidMount() {

       // console.log(this.props);


        axios.get(process.env.REACT_APP_API_URL+"getSpecializationList/")
        .then((response) => {
          if (response.data.status === true) {

            var specializationArray = response.data.result;
            var newArray = [];
            for(var i=0;i<specializationArray.length;i++){
                    newArray.push(specializationArray[i].name);
            }

            console.log(newArray);

            this.setState({
                searchLists: newArray,
            });


          }else{
            console.log(response);
          }
        })
        .catch((error) => {
          console.log('specialization catch error');
          console.log(error);
        });
    }
      

      
    
      onChange = (e) => {

        console.log('onChanges');
        var options = this.state.searchLists;
        
        // const { options } = this.props;
        // console.log(options);

        const userInput = e.currentTarget.value;
        
    
        const filteredOptions = options.filter(
          (optionName) =>
            optionName.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );
    
        this.setState({
          activeOption: 0,
          filteredOptions,
          showOptions: true,
          userInput: e.currentTarget.value
        });
      };
    
      onClick = (e) => {
        this.setState({
          activeOption: 0,
          filteredOptions: [],
          showOptions: false,
          userInput: e.currentTarget.innerText
        });
      };
      onKeyDown = (e) => {
        const { activeOption, filteredOptions } = this.state;
    
        if (e.keyCode === 13) {
          this.setState({
            activeOption: 0,
            showOptions: false,
            userInput: filteredOptions[activeOption]
          });
        } else if (e.keyCode === 38) {
          if (activeOption === 0) {
            return;
          }
          this.setState({ activeOption: activeOption - 1 });
        } else if (e.keyCode === 40) {
          if (activeOption === filteredOptions.length - 1) {
            console.log(activeOption);
            return;
          }
          this.setState({ activeOption: activeOption + 1 });
        }
      };



    searchSubmitHandler = (event) => { 
        event.preventDefault();
        this.setState({
            searchstatus:true,
        });
       
        console.log("------------Get user filter ------------:", this.state.userInput);

        var searchParam = this.state.userInput;

         if(searchParam===''){
              searchParam = 'all';
         }

        
        this.props.history.push('/doctorList/'+searchParam);
        
    }

  render() {


    const {
        onChange,
        onClick,
        onKeyDown,
  
        state: { activeOption, filteredOptions, showOptions, userInput }
      } = this;
      let optionList;
      if (showOptions && userInput) {
        if (filteredOptions.length) {
          optionList = (
            <ul className="options">
              {filteredOptions.map((optionName, index) => {
                let className;
                if (index === activeOption) {
                  className = 'option-active';
                }
                return (
                  <li className={className} key={optionName} onClick={onClick}>
                    {optionName}
                  </li>
                );
              })}
            </ul>
          );
        } else {
          optionList = ('');

          
        }
      }
   
    
    return ( 
       
        <section className="search-sec noprint">
            <div className="container">
                <div className="search-col ">
                    <form onSubmit={this.searchSubmitHandler}>
                        <input id="printbtn" type="text" name="search" placeholder="Search for doctors by specialization "
                        onChange={onChange} onKeyDown={onKeyDown} value={userInput} autoComplete="off"/>

                        <button type="submit" className="search-btn">Browse</button>
                        
                    </form>
                    
                    {optionList}

                </div>
            </div>

        </section> 
          
     )

  }
}
 

export default withRouter(searchBar);