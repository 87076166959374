import React, { Component } from 'react';
 
class Faq extends Component {
  render() {
    return <div className="container">
            <div className="row">
                <div className="col-sm-12">
                  <br/>
                  <br/>
                  
                    <h3> Terms and condition </h3>
                    <p>No lockdown on your practise! Consult online from the convenience of your home.</p>
                    <p>Use our world class teleconsult platform to stay in touch with patients and allay their fears..</p>
                </div>

                
            </div>

           
                  <br/>
                  <br/>  

          </div>

          
  }
}
 
export default Faq;