import React, { Component } from 'react';
import { Modal, Alert } from 'react-bootstrap';
import LEFTNAV from "./leftnav";
import axios from "axios";
import Button from 'react-bootstrap/Button';


import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

class Appointment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      appointments: [],
      appointmentId: '',
      showHide: false,
      reason: '',
      errMsgReason: '',
      cancelStatus: false,
      message: ''
    };
  }



  getAppointmentLists() {

    // var patientDetail = JSON.parse(localStorage.getItem("userData"));

    var filterByUserId = localStorage.getItem("filterByUserId");

    if (filterByUserId) {
      // var memberId = patientDetail.id;
      // alert(memberId);



      axios.get(process.env.REACT_APP_API_URL + "getAppointmentList/" + filterByUserId)
        .then((response) => {

          console.log("Get patient appointment List :", response.data.result);

          if (response.data.status === true) {
            this.setState({
              appointments: response.data.result,
            });

          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log('catch error');
          console.log(error);
        });
    }

  }

  handleModalShowHide(id) {

    console.log("Appointment id : ", id);

    this.setState({
      showHide: !this.state.showHide,
      appointmentId: id,
      cancelStatus: false,
      errMsgReason: '',
    })
  }

  cancelAppointment() {
    if (this.state.reason.length == 0) {

      this.setState({
        errMsgReason: 'Please select your reason',
        cancelStatus: false,
      });
    } else {

      // Pass token in the header
      let auth_token = localStorage.getItem('token');
      let header_token = { 'x-access-token': auth_token };

      axios.post(process.env.REACT_APP_API_URL + "cancelAppointment", {
        id: this.state.appointmentId,
        email: this.state.email,
      }, { headers: header_token })
        .then((response) => {

          console.log(response);

          if (response.data.status === true) {
            console.log("Appointment cancel :", response);

            this.setState({
              errMsgReason: '',
              cancelStatus: true,
              message: response.data.message
            });

            this.getAppointmentLists();



          }


        });



    }

    // setTimeout(() => {

    //   this.setState({
    //     errMsgReason: '',
    //     cancelStatus: false,
    //   });

    // }, 5000);

  }


  componentDidMount() {

    var patientDetail = JSON.parse(localStorage.getItem("userData"));
    var email = patientDetail.email;
    this.setState({
      email: email
    });

    this.getAppointmentLists();
  }

  onChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }




  render() {

    const cancelRadioBtn = {
      margin: "10px"
    };

    const columns = [

      {
        name: "Appointment No.",
        width: "180px",
        selector: "appointmentNo",
        sortable: true
      },
      {
        name: "Date & Time",
        width: "180px",
        sortable: true,
        selector: "appointmentDate",
        cell: appointment => <span>
          {appointment.appointmentDate} {appointment.appointment_time}
        </span>,
      },
      {
        name: "Doctor Name",
        width: "200px",
        selector: "doctor_name",
        sortable: true,
        cell: appointment => <span>
          <span className="captilize"> <b> Dr.  {appointment.doctor_name} </b> 
         
          {appointment.doctor_specialization && appointment.doctor_specialization.substring(30,0) } 
          {appointment.doctor_specialization && appointment.doctor_specialization.length >30 ? ('...') : ('') }
        </span>
        </span>,

      },
      {
        name: "Symptoms",
        selector: "symptoms",
        sortable: true
      },

      {
        name: "Type",
        selector: "consult_type",
        sortable: true,
        cell: appointment => <span>{appointment.consult_type == 1 ? 'Online' : 'In-Clinic'}</span>,
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        cell: d => <span>{d.status == 1 ? (<span className="pending">PENDING </span>) : ('')}
          {d.status == 3 ? (<span className="confirm">CANCELLED BY SELF </span>) : ('')}
          {d.status == 4 ? (<span className="confirm">CANCELLED BY DOCTOR </span>) : ('')}
        </span>,
      },
      {
        name: "Action",
        width: "100px",
        cell: d => <span><Button disabled={d.status != 1} variant="danger" size="sm" onClick={() => this.handleModalShowHide(d.appointmentId)}>Cancel </Button></span>,
      }

    ];

    const data = this.state.appointments;
    const tableData = { columns, data };


    return (
      <section className="patient-appointment-section patient-main-section">
        <div className="container">
          <div>

            <LEFTNAV />
            <div className="content-wrapper">
              <div className="patient-appointment-list-right-col">
                <div className="col-md-12">
                  <h2>
                    <span>
                      <i className="spt-img calendar-icon"></i>
                    </span> Appointments
                                </h2>
                  {/* <form className="appointments-list-search">
                    <input type="text" name="appointments-list-search" className="form-control" />
                    <button type="submit" className="ap-list-search">
                      <i className="spt-img search-icon"></i></button>
                  </form> */}
                  <br />
                  <br />
                </div>

                <div className="list-table">
                  <div className="table-responsive">

                    {this.state.appointments.length > 0 ? (<DataTableExtensions {...tableData}>
                      <DataTable
                        title="Appointments"
                        columns={columns}
                        data={data}
                        noHeader
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        paginationPerPage={10}
                        className="table"
                        responsive
                      />
                    </DataTableExtensions>) : (<div className="table">
                      <div className="datanotAvailable"> There are no records to display </div>
                    </div>)}




                    
                    <Modal className="patient-cancel-appointment" show={this.state.showHide}>
                      <Modal.Header closeButton onClick={() => this.handleModalShowHide('')}>
                        <h5 >Cancel Appointment</h5>
                      </Modal.Header>
                      <Modal.Body>

                        {this.state.cancelStatus ? (<Alert variant="success"> {this.state.message} </Alert>) : ('')}
                        <h6>Choose a reason for cancellation * </h6>

                        <div className="col-sm-12 form-group">

                          <div className="text-danger">{this.state.errMsgReason}</div>
                          <div class="form-group">
                            <ul>
                              <li>
                                <input style={cancelRadioBtn} type="radio" name="reason" value="1" onChange={this.onChangeHandler} />
                                <label>  Booked the wrong doctor </label>
                              </li>

                              <li>
                                <input style={cancelRadioBtn} type="radio" name="reason" value="2" onChange={this.onChangeHandler} />
                                <label> I have changed my mind </label>
                              </li>

                              <li>
                                <input style={cancelRadioBtn} type="radio" name="reason" value="3" onChange={this.onChangeHandler} />
                                <label> I will book an appointment at a later date </label>
                              </li>

                              <li>
                                <input style={cancelRadioBtn} type="radio" name="reason" value="4" onChange={this.onChangeHandler} />
                                <label> My reason is not listed  </label>
                              </li>

                            </ul>


                          </div>
                        </div>

                        <h6>Are you sure you want to cancel the appointment?</h6>
                        <br />


                        <Button variant="outline-primary" disabled={this.state.cancelStatus} onClick={() => this.cancelAppointment()}> Yes </Button> {' '}
                        <Button variant="outline-warning" disabled={this.state.cancelStatus} onClick={() => this.handleModalShowHide()}> No </Button>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    )

  }
}

export default Appointment;