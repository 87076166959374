import Axios from 'axios';
import React, { Component } from 'react';
import {Redirect, NavLink, Link } from "react-router-dom";
import jwt_decode from "jwt-decode";

// import '../css/doctor.css';




class leftNav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            label: localStorage.getItem("doctorLive"),
            // status: localStorage.getItem("doctorLive"),
        }
    }

    onOffStatus(status) {

        var doctorObject = JSON.parse(localStorage.getItem("userData"));
        var id = doctorObject.doctordetailId;

        var body;

        if (status == "Online") {
            
            this.setState({
                label: "Offline",
            })

            body = {
                id: id,
                status: "Offline",
            }
            
        } else {
            
            this.setState({
                label: "Online",
            })

            body = {
                id: id,
                status: "Online",
            }
        }

        localStorage.setItem("doctorLive",body.status);
        // Pass token in the header
        let auth_token = localStorage.getItem('token');
        let header_token = { 'x-access-token': auth_token };

        Axios.post(process.env.REACT_APP_API_URL + 'doctor/changeLiveStatus', body,{ headers: header_token })
            .then((response) => {
                console.log(response);

            }).catch((error) => {
                console.log("Catch error :", error);
            })



    }

    componentDidMount(){

        // Manage Logout when referesh the page 
        if(localStorage.getItem('token')) {
            const jwt_Token_decoded = jwt_decode(localStorage.getItem('token'));
            console.log("decode token :", jwt_Token_decoded);
            if (jwt_Token_decoded.exp * 1000 < Date.now()) {
                localStorage.clear();
                window.location = process.env.REACT_APP_BASE_URL;
                console.log("----- logout user -----");
            } 
        }

    
    }



    render() {

        // Redirect User if not login
        const login = localStorage.getItem("isLoggedIn");
        if (!login) {
            return <Redirect to="/" />;
        }

        return (
            <aside className="main-sidebar">
                <section className="sidebar">
                    <div className="side-nav">
                        <div className="status-section">
                            <div className="dr-status-col">
                                <h3 className="online-status" onClick={() => this.onOffStatus(this.state.label)}>
                                    <span className={this.state.label == "Online" ? 'online-status-btn' : 'online-status-btn on-off-status'}>
                                    </span> {this.state.label}
                                </h3>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <NavLink to="/doctor/dashboard" >
                                    <span className="spt-img dashboard-icon"></span> Dashboard
                                </NavLink>
                            </li>
                            <li >
                                <NavLink to="/doctor/appointment">
                                    <span className="spt-img appointments-icon"></span> Appointments
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/doctor/consultation" >
                                    <span className="spt-img consultations-icon"></span> Consultations
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/doctor/patients">
                                    <span className="spt-img patients-icon"></span> Patients
                                </NavLink>
                            </li>
                            {/* <li>
                                <NavLink to="/doctor/referrals"> 
                                    <span className="spt-img referrals-icon"></span> Referrals
                                </NavLink>
                            </li> */}
                            <li>
                                <NavLink to="/doctor/payments">
                                    <span className="spt-img payment-icon"></span> Payments
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/doctor/feedback">
                                    <span className="spt-img feedback-star-icon"></span> Reviews
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/doctor/settings" activeClassName="active">
                                    <span className="spt-img setting-icon"></span> Settings
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </section>
            </aside>



        )

    }
}

export default leftNav;