import React, { Component } from 'react';
import axios from "axios";
export default class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: false,
            message: '',
            password: '',
            cpassword: '',
            isLoading: false,
        };
    }


    onChangehandler = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }


    handleSubmit = (event) => {
        event.preventDefault();
        const doctorObject = JSON.parse(localStorage.getItem("userData"));
        var memberId = doctorObject.memberId;

        this.setState({
            isLoading: true
        });

        var body = {};


        body = {
            password: this.state.password,
            cpassword: this.state.cpassword,
            memberId: memberId,
            hospitalId: 1,
        }


        

        // Pass token in the header
        let auth_token = localStorage.getItem('token');
        let header_token = { 'x-access-token': auth_token };

        axios.post(process.env.REACT_APP_API_URL + "doctor/updateDoctorPassword", body,
            { headers: header_token }).then((response) => {
                console.log(response);
                if (response.data.status == true) {

                    this.setState({
                        status: true,
                        message: response.data.message,
                        isLoading :false,
                        password:'',
                        cpassword:'',

                    })

                } else {

                    this.setState({
                        ErrMsgPassword: response.data.errors.password,
                        ErrMsgCpassword: response.data.errors.cpassword,
                        isLoading:false,
                    });
                }

                setTimeout(() => {
                    this.setState({
                        status: false,
                        message: '',
                        ErrMsgPassword: '',
                        ErrMsgCpassword: '',
                        isLoading:false,
                    });
                }, 5000)

            }).catch((error) => {
                console.log("enter catch error :", error);
            })




        // this.updateTiming(data);
    }


    getDoctorInfo(memberId) {
        this.setState({ isLoading: true });
        axios.get(process.env.REACT_APP_API_URL + "doctor/getDoctorInfo/" + memberId)
            .then((response) => {
                this.setState({ isLoading: false });
                if (response.data.status) {
                    console.log("Get Doctor Details : ", response.data.result[0]);
                    this.setState(
                        {
                            advanceBookingDay: response.data.result[0].advanceBooking,
                            cancelDay: response.data.result[0].cancelation,
                        }
                    )
                }

            })
            .catch((error) => {
                console.log('enter catch error');
                console.log(error);
            });
    }


    componentDidMount() {
        const user = JSON.parse(localStorage.getItem("userData"));
        var memberId = user.memberId;
        this.getDoctorInfo(memberId);

    }

    render(props) {
        return (<React.Fragment>
            <div class="profile_deatils pt-xl-5">
            
            {this.state.status ? (<div class="alert alert-success">
                            {this.state.message}
                        </div>) : ('')}


                <form onSubmit={this.handleSubmit}>
                    <div class="card-header formTitle">
                        <h5>CHANGE PASSWORD </h5>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-sm-6 form-group">
                            <label for="">Password*</label>
                            <input type="password" class="form-control" name="password" value={this.state.password} onChange={this.onChangehandler} />
                            <div class="text-danger">{this.state.ErrMsgPassword}</div>
                        </div>

                        <div class="col-sm-6 form-group">
                            <label for="">Confirm Password*</label>
                            <input type="password" class="form-control" name="cpassword" value={this.state.cpassword} onChange={this.onChangehandler} />
                            <div class="text-danger">{this.state.ErrMsgCpassword}</div>
                        </div>

                        <div class="col-md-12 pt-xl-4">
                            <button type="submit" class="pay-btn">Save {this.state.isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm ml-5"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                    <span></span>
                                )}</button>

                        </div>
                    </div>
                </form>
            </div>

        </React.Fragment>
        );

    }
}
