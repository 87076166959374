import React, { Component } from 'react';
import { Redirect, Link } from "react-router-dom";


class Thankyou extends Component {
  render() {
    return (
      <React.Fragment>
        <section class="payment-section thankyou-page-sec registration-msg">
          <div class="container">
            <div class="detail-right-col">
              <h2>Registration Successful</h2>
              <div class="thank-message">
                <p>
                  <span>Thank You for registering here</span>
                </p>
                <Link to="/patient/dashboard" class="view-invoice">Go to Dashboard</Link>
                <br/>
                <br/>
                <br/>
                <br/>
              
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>)


  }
}

export default Thankyou;