import React, { Component } from 'react';

import { Link } from "react-router-dom";
import axios from "axios";
import Rating from "./rating";
import NumberFormat from 'react-number-format';


class doctorList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            doctorList: [],
            rating: '',
            totalReview: ''


        };
    }




    getDoctorList(search) {

        // console.log("get search request",search);

        var ApiPath;
        if (search === 'all') {
            ApiPath = "getDoctorList/";
        } else {
            ApiPath = "getDoctorListBySearch/" + search;
        }
        axios.get(process.env.REACT_APP_API_URL + ApiPath)
            .then((response) => {

                // console.log("-get doctor list --",response);

                if (response.data.status === true) {
                    this.setState({
                        doctorList: response.data.result,
                    });
                } else {

                }
            });
    }



    componentDidMount() {
        var searchParameter = this.props.match.params.search;
        // console.log("Get filter parameter ", searchParameter);
        this.getDoctorList(searchParameter);
    }





    componentDidUpdate() {
        var searchParameter = this.props.match.params.search;
        this.getDoctorList(searchParameter);
    }



    render() {

        const doctors = this.state.doctorList;
        // console.log("Array of doctor : ", doctors);











        return (
            <section className="patient-main-section">


                {/* <div className="searchByFilter">
                    <div className="container">

                        

                        <div className="laguage">
                            <h2>language</h2>
                            <div className="selectLang">
                                <div className="customDropDown">
                                    <select  className="ng-pristine ng-untouched ng-valid ng-not-empty">
                                        <option value="all">All</option>
                                        -- end ngRepeat: language in languages --
                                    </select>
                                </div>
                            </div>

                        </div>
                        <div className="userStatus">
                            <h2>status</h2>
                            <div className="status">
                                <div className="customDropDown">
                                    <select ng-model="query.onlineStatus" ng-change="filteredDoctor()" className="ng-pristine ng-untouched ng-valid ng-not-empty">
                                        <option value="all">All</option>
                                        <option value="online">online</option>
                                        <option value="offline">offline</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="sort">
                            <span>sort by</span>
                            <div className="orderBy">
                                <div className="customDropDown">
                                    <select ng-model="orderBy" className="ng-pristine ng-untouched ng-valid ng-not-empty">
                                    
                                        <option value="popularity">popularity</option>
                                        
                                        <option value="price-low-high">price low-high</option>
                                        <option value="price-high-low">price high-low</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>

                </div> */}
                <div className="container">
                    <br />
                    <br />
                    <div className="row">


                        {doctors.length==0 ? (<div className="col-sm-12 doctorEmptyMessage"> Record not found </div>) : ('')}

                        {doctors.map(doctor => (

                            <div className="col-sm-4" key={doctor.doctordetail_id}>
                                <Link to={`/patient/doctor/${doctor.doctordetail_id}`}  className="doctorProfileList">
                                    <div className="detail-left-col doctor-list-col">

                                        <div className="doct-img">


                                            {doctor.profile_pic == null ?
                                                (<img alt="image-not-found" className="imageList" src={process.env.REACT_APP_BASE_URL + "img/picture-not-available.jpg"} />
                                                )
                                                :
                                                (
                                                    <img alt="member-img" className="imageList" src={process.env.REACT_APP_IMAGE_LOCATION + "doctor/profile_picture/" + doctor.profile_pic} />
                                                )
                                            }

                                        </div>


                                        <h4> {doctor.title} {doctor.name}</h4>
                                        <h5>{doctor.qualifications}</h5>
                                        <h5> 
                                            {doctor.specializations.substring(35,0)} 
                                            {doctor.specializations.length >35 ? ('...') : ('') } 
                                        </h5>
                                        <p><b>Experience- {doctor.experience} Years</b></p>

                                        <Rating rating={doctor.rating} />

                                        {/* <div className="check-item">
                                            <p className="count-rating">(0)</p>
                                        </div> */}


                                        <div className="dr-price">
                                            <ul className="user-review-list">
                                                <li><i className="spt-img curency-icon"></i>  
                                                <NumberFormat value={doctor.fee} displayType={'text'} thousandSeparator={true} prefix={'Rs. '} /></li>

                                            </ul>
                                        </div>
                                    </div>
                                </Link>

                            </div>

                        ))}
                    </div>
                    <br />
                    <br />
                </div>
            </section>

        )

    }
}

export default doctorList;