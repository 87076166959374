import React, { Component } from 'react';
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
// import DatePicker from 'react-date-picker';


class Register extends Component {

  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      age: '',
      mobile: '',
      gender: '',
      email: '',
      password: '',
      password_confirmation: '',
      successMsg: "",
      errorMsg: "",
      errMsgFirstName: "",
      errMsgLastName: "",
      errMsgAge: "",
      errMsgMobile: "",
      errMsgGender: "",
      errMsgEmail: "",
      errMsgPassword: "",
      errMsgpassword_confirmation: "",
      isLoading: false,
      status: false,
      date: '',
      date2: '',
      registerPage: "",

    };

    // this.state = {status: false};
  }

  registerSubmitHandler = (event) => {
    event.preventDefault();




    console.log(process.env.REACT_APP_API_URL);

    console.log(this.state);

    this.setState({ isLoading: true });



    var data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      age: this.state.age,
      mobile: this.state.mobile,
      gender: this.state.gender,
      email: this.state.email,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
    };







    axios
      .post(process.env.REACT_APP_API_URL + "patientSignUp", this.state)
      .then((response) => {


        this.setState({ isLoading: false });
        if (response.data.status === 200) {
          this.setState({
            successMsg: response.data.message,
            status: true,
            /*
            first_name: '',
            last_name: '',
            mobile: '',
            age: '',
            gender: '',
            email: '',
            password: '',
            password_confirmation: '',
          */
            errMsgFirstName: "",
            errMsgLastName: "",
            errMsgAge: "",

            errMsgMobile: "",
            errMsgGender: "",
            errMsgEmail: "",
            errMsgPassword: "",
            errMsgpassword_confirmation: ""
          });

          // login User 

          var logInRequest = {
            email: this.state.email,
            password: this.state.password,
            loginAs: 'patient',
          };

          this.loginUser(logInRequest);



          /*
           setTimeout(() => {
             this.setState({ successMsg: "" });
           }, 2000);
           */

        }

        if (response.data.status === "failed") {


          console.log(response.data.errors);


          this.setState({

            errMsgFirstName: response.data.errors.first_name,
            errMsgLastName: response.data.errors.last_name,
            errMsgAge: response.data.errors.age,
            errMsgGender: response.data.errors.gender,
            errMsgMobile: response.data.errors.mobile,
            errMsgEmail: response.data.errors.email,
            errMsgPassword: response.data.errors.password,
            errMsgpassword_confirmation: response.data.errors.password_confirmation
          }
          );

          /*
          setTimeout(() => {
            this.setState({
              errMsgFirstName: "",
              errMsgLastName: "",
              errMsgAge: "",
              errMsgGender: "",
              errMsgMobile: "",
              errMsgEmail: "",
              errMsgPassword: "",
              errMsgpassword_confirmation: ""


            });
          }, 5000);
          */

        }

      });


  }

  loginUser(logInRequest) {

    axios.post(process.env.REACT_APP_API_URL + "patientLogin", logInRequest
    )
      .then((response) => {

        if (response.data.status === 200) {
          console.log('---------Login Success ----------------');
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("userData", JSON.stringify(response.data.data));
          localStorage.setItem("userType", logInRequest.loginAs);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("filterByUserId", response.data.data.membercontactId);

          // Redirect to thank you page
          var url = process.env.REACT_APP_BASE_URL + "thank-you";
          window.location = url;
          
        }

      });
  }

  registerChangeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }






  /*
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }


  handleChange(value) {
    var newDate = this.convert(value);
    this.setState({
      date: value, 
      date2:newDate
    });
  
  }
  */


  render() {
    const status = this.state.status;
    const isLoading = this.state.isLoading;

    const calendarCss = {
      border: "0 px"
    };
    return (

      <React.Fragment>

        {/* 
        {status ? (
          <div>
            <br />
            <div className="alert alert-success"> {this.state.successMsg}.</div>
          </div>
        ) : (
            <span></span>

          )}
        <br /> */}

        <form className="pt-4 mt-1" onSubmit={this.registerSubmitHandler}>
          <div className="form-row pb-3">
            <div className="col">
              <input placeholder="First Name *" type="text" name="first_name" className="form-control" value={this.state.first_name} onChange={this.registerChangeHandler} minLength="3" />
              <div className="text-danger">{this.state.errMsgFirstName}</div>
            </div>
            <div className="col">
              <input placeholder="Last Name *" type="text" name="last_name" className="form-control" value={this.state.last_name} onChange={this.registerChangeHandler} minLength="3" />
              <div className="text-danger">{this.state.errMsgLastName}</div>
            </div>
          </div>

          <div className="form-group">
            <input maxLength="10" placeholder="Mobile *" type="text" name="mobile" className="form-control" value={this.state.mobile} onChange={this.registerChangeHandler} minLength="10" />
            <div className="text-danger">{this.state.errMsgMobile}</div>
          </div>
          <div className="form-group">
            <input placeholder="Email *" type="email" name="email" className="form-control" value={this.state.email} onChange={this.registerChangeHandler} />
            <div className="text-danger">{this.state.errMsgEmail}</div>
          </div>
          <div className="form-group">
            <input placeholder="Password *" type="password" name="password" className="form-control" value={this.state.password} onChange={this.registerChangeHandler} />
            <div className="text-danger">{this.state.errMsgPassword}</div>
          </div>


          <div className="form-group">
            <input placeholder="Confirm Password *" type="password" name="password_confirmation" className="form-control" value={this.state.password_confirmation} onChange={this.registerChangeHandler} />
            <div className="text-danger">{this.state.errMsgpassword_confirmation}</div>
          </div>



          <div className="form-row">

            <div className="col">

              {/* <DatePicker style={calendarCss} name="date"  className="form-control" format="y-MM-dd" onChange={(event) => this.handleChange(event)}  selected={ this.state.date }  value={this.state.date} />  */}

              <input placeholder="Age *" type="number" name="age" className="form-control" value={this.state.age} onChange={this.registerChangeHandler} />
              <div className="text-danger">{this.state.errMsgAge}</div>
            </div>


            <div className="col  d-flex justify-content-around pt-1">


              <label className="radio">Male
                  <input type="radio" name="gender" value="male" onChange={this.registerChangeHandler} />
                <span className="checkround"></span>
              </label>
              <label className="radio">Female
                  <input type="radio" name="gender" value="female" onChange={this.registerChangeHandler} />
                <span className="checkround"></span>
              </label>
            </div>
          </div>
          <div className="form-group pt-5">
            <p> <label className="check ">By creating an account you agree to the Terms of Services,
            Informed Consent and Privacy Policy.
                                        <input type="checkbox" checked name="is_name" />
              <span className="checkmark"></span>
            </label>
            </p>
          </div>

          <div className="form-group pt-2 submitbtbn w-100">

            <button type="submit" className="btn Fbtn-secondary btn-block"
            > Register {isLoading ? (
              <span
                className="spinner-border spinner-border-sm ml-5"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
                <span></span>
              )}
            </button>





          </div>
        </form>

      </React.Fragment>


    );
  }






}

export default Register;