import React, { Component } from 'react';
import LEFTNAV from "./includes/leftnav";
import { Button, Modal } from 'react-bootstrap';
import axios from "axios";
import Rating from "../patient/dashboard/rating";
import {Redirect } from "react-router-dom";


import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";


class Feedback extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            feedbacks: [],
        };
    }


    getFeedbackList(id) {
        console.log("Get doctor Feedback :", id);

        axios.get(process.env.REACT_APP_API_URL + 'getDoctorRating/' + id)
            .then((response) => {
                console.log("--------Feedback list response ----------", response);
                if (response.data.status == true) {
                    this.setState({
                        feedbacks: response.data.result
                    });
                } else {
                    console.log("Failed Api request");
                }
            })
            .catch((error) => {
                console.log("catch List");
            });
    }

    componentDidMount() {

        var LogedInUserObject = JSON.parse(localStorage.getItem("userData"));
        if (LogedInUserObject) {
            var doctorDetailId = LogedInUserObject.doctordetailId;
            this.getFeedbackList(doctorDetailId);
        }

    }




    render() {

        // Redirect User if not login
        const login = localStorage.getItem("isLoggedIn");
        if (!login) {
            return <Redirect to="/" />;
        }


      
        const columns = [

            {
                name: "Patient Name",
                selector: "first_name",
                cell: row => <span> <span className="patientName">
                    <b className="captilize">{row.first_name} {row.last_name}</b>
                </span>
                    <p><span className="age">{row.age} yrs</span> | <span className="jendeer">{row.gender}</span></p>
                </span>,
            },
            {
                name: "Rating",
                selector: "rating",
                cell: row => <span> <Rating rating={row.rating}/> </span>,
            },
            {
                name: "Review",
                selector: "feedback",
                sortable: true,

            },
            {
                name: "Created on",
                selector: "reviewdate",
                sortable: true
            }

        ];


        const data = this.state.feedbacks;
        const tableData = { columns, data };

        return (
            <section className="doctor-consultation-view  doctor-main-section">
                <div className="container-fulid">
                    <div>

                        <LEFTNAV />
                        <div className="content-wrapper">
                            <div className="patient-appointment-list-right-col">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h2><span className="calendar-teg"><i className="spt-img calendar-icon"></i></span> Reviews </h2>
                                        {/* <form className="appointments-list-search">
                                            <input type="text" name="appointments-list-search" className="form-control" placeholder="Search" />
                                            <button type="submit" className="ap-list-search"><i className="spt-img search-icon"></i></button>
                                        </form> */}
                                    </div>

                                </div>
                                <div className="list-table">
                                    <div className="table-responsive">


                                    {this.state.feedbacks.length > 0 ? (<DataTableExtensions {...tableData}>
                                        <DataTable
                                            title="Review"
                                            columns={columns}
                                            data={data}
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            pagination
                                            paginationPerPage={10}
                                            className="table"
                                            responsive
                                        />
                                    </DataTableExtensions>) : (<div className="table">
                                            <div className="datanotAvailable"> There are no records to display </div>
                                        </div>)}



                                    

                                        {/* <table className="table">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th>Patient</th>
                                                    <th>Rating</th>
                                                    <th>Review</th>
                                                    <th>Created on</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.feedbacks.map((feedback, index) => (
                                                    <tr>
                                                        <td>
                                                            <table className="profile-table">
                                                                <tr>

                                                                    <td className="doctor-pro"><span className="member-img">

                                                                        {feedback.profile_pic == null ?
                                                                            (<img alt="image-not-found" src={process.env.REACT_APP_BASE_URL + "img/picture-not-available.jpg"} />
                                                                            )
                                                                            :
                                                                            (
                                                                                <img alt="member-img" src={process.env.REACT_APP_IMAGE_LOCATION + "patient/profile_picture/" + feedback.profile_pic} />
                                                                            )
                                                                        }

                                                                    </span></td>
                                                                    <td><b className="captilize">{feedback.first_name} {feedback.last_name}</b>
                                                                        <p><span className="age">{feedback.age} yrs</span> | <span className="jendeer">{feedback.gender}</span></p>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                        <td> <Rating rating={feedback.rating}/> </td>
                                                        <td>{feedback.feedback}</td>
                                                        <td>{feedback.reviewdate}</td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>




        )

    }
}

export default Feedback;