import React, { Component } from 'react';
import LEFTNAV from "./includes/leftnav";
import { Button, Modal } from 'react-bootstrap';
import axios from "axios";
import { Link } from "react-router-dom";
class Referral extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            referrals: [],
        };
    }


    getPharmacyReferral() {

        axios.get(process.env.REACT_APP_API_URL + 'pharmacy/referralList')
            .then((response) => {
                console.log("Get Pharmacy referral ", response);

                if (response.data.status) {
                    this.setState({
                        referrals: response.data.result
                    })
                }
                else {
                    console.log("response failed for pharmacy referral ");
                }

            }).catch((error) => {
                console.log("catch error : ", error);
            });
    }


    componentDidMount() {
        this.getPharmacyReferral();
    }




    render() {
        return (
            <section className="doctor-consultation-view doctor-main-section">
                <div className="container-fulid">
                    <div>

                        <LEFTNAV />
                        <div className="content-wrapper">
                            <div className="patient-appointment-list-right-col">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h2><span className="calendar-teg"><i className="spt-img calendar-icon"></i></span> Referrals </h2>
                                    </div>
                                </div>
                                <div className="list-table">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Doctor</th>
                                                    <th>Patient</th>
                                                    <th>Age</th>
                                                    <th>Mobile</th>
                                                    <th>Gender</th>
                                                    <th>Referral Summary</th>
                                                    <th>Date</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.referrals.map((referral, index) => (
                                                    <tr>
                                                        <td>{index+1} </td>
                                                        <td className="captilize"> {referral.doctorName} </td>
                                                        <td className="captilize"> {referral.first_name} {referral.last_name}</td>
                                                        <td> {referral.age} </td>
                                                        <td> {referral.mobile} </td>
                                                        <td> {referral.gender}</td>
                                                        <td> {referral.summary} </td>
                                                        <td> {referral.referralDate} </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>




        )

    }
}

export default Referral;