import React, { Component } from 'react';
import LEFTNAV from "./leftnav";
import axios from "axios";
import {Link } from "react-router-dom";


import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

class Prescription extends Component {

    constructor(props) {
        super(props);
        this.state = {
            priscriptions: [],
            showHide: false,
        };
    }

    getPriscriptionList() {

        // var patientDetail = JSON.parse(localStorage.getItem("userData"));
        var filterByUserId = localStorage.getItem("filterByUserId");

        if (filterByUserId) {

            axios.get(process.env.REACT_APP_API_URL + "getPrescriptionList/" + filterByUserId)
                .then((response) => {

                    console.log("get prescription response :", response.data.result);

                    if (response.data.status === true) {
                        this.setState({
                            priscriptions: response.data.result,
                        });

                    } else {
                        console.log(response);
                    }
                })
                .catch((error) => {
                    console.log('catch error');
                    console.log(error);
                });
        }

    }

    componentDidMount() {
        this.getPriscriptionList();
    }


    render() {


        const columns = [

            {
                name: "Consultation No.",
                width: "170px",
                selector: "appointmentNo",
                sortable: true
            },
            {
                name: "Date & Time",
                width: "160px",
                sortable: true,
                selector: "appointmentDate",
                cell: appointment => <span>
                    {appointment.appointmentDate} {appointment.appointment_time}

                </span>,
            },
            {
                name: "Doctor Name",
                width: "160px",
                selector: "doctor_name",
                sortable: true,
                cell: appointment => <span>
                  <span className="captilize"> <b> Dr.  {appointment.doctor_name} </b> 
                 
                  {appointment.doctor_specialization && appointment.doctor_specialization.substring(30,0) } 
                  {appointment.doctor_specialization && appointment.doctor_specialization.length >30 ? ('...') : ('') }
                </span>
                </span>,
        
              },
            
            {
                name: "Dosage",
                width: "150px",
                selector: "dose",
                sortable: true
            },


            {
                name: "No. of times in a day",
                selector: "no_of_time_in_day",
                sortable: true,
                width: "200px",

            },
            {
                name: "No. of days",
                width: "100px",
                selector: "no_of_days",
            },
            {
                name: "Detail",
                width: "100px",
                cell: appointment => 
                    <Link to={`/patient/prescriptionDetail/${appointment.appointment_id}`}><span> View </span></Link>,
                width: "100px",
                button: true,
            }

        ];


        const data = this.state.priscriptions;
        const tableData = { columns, data };

        return (
            <section className="patient-appointment-section patient-main-section">
                <div className="container">
                    <div>
                        <LEFTNAV />

                        <div className="content-wrapper">
                            <div className="patient-appointment-list-right-col">
                                <div className="col-md-12">
                                    <h2><span><i className="spt-img calendar-icon"></i></span> Prescriptions</h2>
                                    {/* <form className="appointments-list-search">
                                        <input type="text" name="appointments-list-search" className="form-control" />
                                        <button type="submit" className="ap-list-search"><i className="spt-img search-icon"></i></button>
                                    </form> */}
                                </div>
                                <div className="list-table d-inline-block w-100">
                                    <div className="table-responsive">


                                        {this.state.priscriptions.length > 0 ? (<DataTableExtensions {...tableData}>
                                            <DataTable
                                                title="Consultation"
                                                columns={columns}
                                                data={data}
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                paginationPerPage={10}
                                                className="table"
                                                responsive
                                            />
                                        </DataTableExtensions>) : (<div className="table">
                                            <div className="datanotAvailable"> There are no records to display </div>
                                        </div>)}


                                        {/* <table className="table">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th>Consultation No.</th>
                                                    <th>Date &amp; Time</th>

                                                    <th>Doctor</th>
                                                    <th>Dosage</th>
                                                    <th>No. of times in a day</th>
                                                    <th>No. of days</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.priscriptions.map(priscription => (
                                                    <tr>
                                                        <td>{priscription.appointmentNo}</td>
                                                        <td>{priscription.appointmentDate} {priscription.appointment_time}</td>

                                                        <td>
                                                            <table className="profile-table">
                                                                <tbody>
                                                                    <tr>

                                                                        <td><b>{priscription.doctor_name}</b><br />{priscription.doctor_specialization}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>

                                                        <td>{priscription.dose}</td>
                                                        <td>{priscription.no_of_time_in_day}</td>
                                                        <td>{priscription.no_of_days}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        )

    }
}

export default Prescription;