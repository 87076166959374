import React, { Component } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import axios from "axios";
import { Redirect, Link } from "react-router-dom";





import SignUpForm from './Register';
import ForgotPassword from './forgotPassword';
import ForgotPasswordDoctor from './forgotPasswordDoctor';
import ForgotPasswordLab from './forgotPasswordLab';
import ForgotPasswordPharmacy from './forgotPasswordPharmacy';


class Form extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loginAs: " ",
      msg: "",
      isLoading: false,
      redirect: false,
      loggedIn: false,
      errMsgEmail: "",
      errMsgPwd: "",
      errMsg: "",
      showHide: false,
      showHideDoctor: false,
      showHideLab: false,
      showHidePharmacy: false,


      // registerPage: false,
      // forgotPasswordPage:false,

      activePage: 'Login',
      tabTitle: 'Login',

    };
  }

  handleModalShowHide(modal) {

    this.setState({
      isLoading: false,
      errMsgEmail: '',
      errMsgPwd: '',
      activePage: 'Login',
      tabTitle: 'Login',
    });


    if (modal == 'patient') {
      this.setState({
        showHide: !this.state.showHide,
        registerPage: false,
        errMsgEmail: '',
        errMsgPwd: '',
      })
    }
    else if (modal == 'doctor') {

      this.setState({
        showHideDoctor: !this.state.showHideDoctor,
        email: '',
        password: '',
      })
    }
    else if (modal == 'Lab') {
      this.setState({
        showHideLab: !this.state.showHideLab,
        email: '',
        password: '',
      })
    }
    else if (modal == 'pharmacy') {
      this.setState({
        showHidePharmacy: !this.state.showHidePharmacy,
        email: '',
        password: '',
      })
    }



  }





  onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };




  showPage(page) {

    this.setState({
      activePage: page,
      tabTitle: page,
      isLoading: false,
    });
  }


  // showRegisterPage() {

  //   this.setState({
  //     registerPage: true,
  //     tabTitle: "Register",
  //     isLoading: false,
  //   });
  // }

  // showForgotPage() {
  //   this.setState({
  //     forgotPasswordPage: true,
  //     isLoading: false,
  //   });
  // }

  // showLoginPage() {
  //   this.setState({
  //     registerPage: false,
  //     tabTitle: "Login",
  //     isLoading: false,
  //   });
  // }


  onSignInHandler(loginAs) {

    this.setState({ isLoading: true });

    var logInRequest = {
      email: this.state.email,
      password: this.state.password,
      loginAs: loginAs,
    };


    console.log("Get login details :", logInRequest);

    this.loginUser(logInRequest);

  };




  loginUser(logInRequest) {

    axios.post(process.env.REACT_APP_API_URL + "patientLogin", logInRequest
    ).then((response) => {

      this.setState({ isLoading: false });
      if (response.data.status === 200) {


        if (logInRequest.loginAs == 'doctor') {

          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("userType", logInRequest.loginAs);
          localStorage.setItem("userData", JSON.stringify(response.data.data));
          localStorage.setItem("doctorLive", "Online");
          localStorage.setItem("token", response.data.token);

          var url = process.env.REACT_APP_BASE_URL + "doctor/dashboard";
          window.location = url;

        } else {

          console.log('---------Login Success ----------------');
          console.log("response", response.data.token);
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("userType", logInRequest.loginAs);
          //localStorage.setItem("filterByUserId", 'all');
          localStorage.setItem("filterByUserId", response.data.data.membercontactId);
          localStorage.setItem("userData", JSON.stringify(response.data.data));
          localStorage.setItem("token", response.data.token);



          // redirect to dashboard
          var url = process.env.REACT_APP_BASE_URL + "patient/dashboard";
          window.location = url;

        }


        this.setState({
          msg: response.data.message,
          redirect: true,
          loggedIn: true
        });
      }
      if (
        response.data.status === "failed" &&
        response.data.success === undefined
      ) {

        console.log(response.data);

        this.setState({
          errMsgEmail: response.data.validation_error.email,
          errMsgPwd: response.data.validation_error.password,

        });
        setTimeout(() => {
          this.setState({
            errMsgEmail: "",
            errMsgPwd: ""
          });
        }, 5000);
      }
    })
      .catch((error) => {
        console.log('enter catch error');
        console.log(error);
      });

  }


  onSignInLabHandler() {

    this.setState({ isLoading: true });
    var body = {
      email: this.state.email,
      password: this.state.password,
    };

    axios.post(process.env.REACT_APP_API_URL + "lab/labLogin", body)
      .then((response) => {

        this.setState({ isLoading: false });

        if (response.data.status == true) {
          console.log('---------Lab Login Success ----------------', response);
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("userType", 'lab');
          localStorage.setItem("userData", JSON.stringify(response.data.data));
          localStorage.setItem("token", response.data.token);

          this.setState({
            msg: response.data.message,
            redirect: true,
            loggedIn: true
          });

          var url = process.env.REACT_APP_BASE_URL + "lab/dashboard";
          window.location = url;




        }
        if (
          response.data.status === "failed" &&
          response.data.success === undefined
        ) {

          console.log(response.data);

          this.setState({
            errMsgEmail: response.data.validation_error.email,
            errMsgPwd: response.data.validation_error.password,

          });
          setTimeout(() => {
            this.setState({
              errMsgEmail: "",
              errMsgPwd: ""
            });
          }, 5000);
        }
      })
      .catch((error) => {
        console.log('enter catch error', error);
      });

  }


  pharmacyLogin() {

    this.setState({ isLoading: true });
    var body = {
      email: this.state.email,
      password: this.state.password,
    };

    axios.post(process.env.REACT_APP_API_URL + "pharmacy/pharmacyLogin", body)
      .then((response) => {

        this.setState({ isLoading: false });

        if (response.data.status == true) {
          console.log('---------Pharmacy Login Success ----------------');
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("userType", 'pharmacy');
          localStorage.setItem("userData", JSON.stringify(response.data.data));
          localStorage.setItem("token", response.data.token);

          this.setState({
            msg: response.data.message,
            redirect: true,
            loggedIn: true,
          });

          var url = process.env.REACT_APP_BASE_URL + "pharmacy/dashboard";
          window.location = url;




        }
        if (
          response.data.status === "failed" &&
          response.data.success === undefined
        ) {

          console.log(response.data);

          this.setState({
            errMsgEmail: response.data.validation_error.email,
            errMsgPwd: response.data.validation_error.password,

          });
          setTimeout(() => {
            this.setState({
              errMsgEmail: "",
              errMsgPwd: ""
            });
          }, 5000);
        }
      })
      .catch((error) => {
        console.log('enter catch error', error);
      });

  }


  render() {

    const isLoading = this.state.isLoading;


    return (

      <React.Fragment>
        <form className="form-inline my-2 my-lg-0 rightlogin">
          <Link to="/" onClick={() => this.handleModalShowHide('patient')} > Patient </Link>
          <span className="px-2"> | </span>
          <Link to="/" onClick={() => this.handleModalShowHide('doctor')}> Doctor </Link>
          <span className="px-2"> | </span>

          <Link to="/" onClick={() => this.handleModalShowHide('Lab')}> Lab </Link>
          <span className="px-2"> | </span>

          <Link to="/" onClick={() => this.handleModalShowHide('pharmacy')} > Pharmacy </Link>
        </form>
        <Modal className="fullscreen patient-login" show={this.state.showHide}>
          <Modal.Header closeButton onClick={() => this.handleModalShowHide('patient')}>

          </Modal.Header>
          <Modal.Body>

            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-md-6 pt-5">
                  <div className="login">
                    <img src={process.env.REACT_APP_BASE_URL + "img/logo.png"} alt="" />
                    <h3> {this.state.tabTitle} Patient Account </h3>

                    {this.state.activePage == 'Login' ?
                      (
                        <div className="tab-content">
                          <div id="home" className="tab-pane active">
                            <form className="pt-4 mt-1">
                              <div className="form-group">
                                <input type="text" name="email" placeholder="E-Mail Id / Mobile" className="form-control" value={this.state.email}
                                  onChange={this.onChangehandler} />
                                <div className="text-danger">{this.state.errMsgEmail}</div>
                              </div>
                              <div className="form-group">
                                <input type="password" name="password" placeholder="*************" className="form-control"
                                  value={this.state.password} onChange={this.onChangehandler} />


                                <div className="text-danger">{this.state.errMsgPwd}</div>
                              </div>





                              <div className="form-group pt-2 submitbtbn w-100">

                                <button type="button" className="btn btn-secondary btn-block" onClick={() => this.onSignInHandler('patient')}>
                                  Log in  {isLoading ? (
                                    <span
                                      className="spinner-border spinner-border-sm ml-5"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                      <span></span>
                                    )}
                                </button>

                              </div>

                              <div className="form-group pt-2 forgotPassword">
                                <label className="check " onClick={() => this.showPage('Forgot Password')} >Forgot Password </label>
                              </div>

                              <div className="form-group pt-2">
                                <label className="check " onClick={() => this.showPage('Register')} >If you are not register click here for register </label>
                              </div>

                            </form>
                          </div>

                        </div>
                      ) : ('')}
                    {this.state.activePage == 'Register' ? (
                      <div className="tab-content">
                        <SignUpForm />
                        <button onClick={() => this.showPage('Login')} type="button" className="btn btn-secondary btn-block backBtn"
                        > Back to Login
                              </button>

                      </div>) : ('')}

                    {this.state.activePage == 'Forgot Password' ? (

                      <div className="tab-content">
                        <ForgotPassword />
                        <button onClick={() => this.showPage('Login')} type="button" className="btn btn-secondary btn-block backBtn"
                        > Back to Login
                        </button>

                      </div>) : ('')}


                  </div>
                </div>

                <div className="col-xl-6 col-md-6 text-right">
                  <img className="img-fluid" src={process.env.REACT_APP_BASE_URL + "img/loginbg.PNG"} alt="" />
                </div>

              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>


        <Modal className="fullscreen doctor-login" show={this.state.showHideDoctor}>
          <Modal.Header closeButton onClick={() => this.handleModalShowHide('doctor')}>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-md-12 pt-5">
                  <div className="login">
                    <img src={process.env.REACT_APP_BASE_URL + "img/logo.png"} alt="" />
                    <h3> {this.state.tabTitle} Doctor Account </h3>


                    {this.state.activePage == 'Forgot Password' ? (

                      <div className="tab-content">
                        <ForgotPasswordDoctor />
                        <button onClick={() => this.showPage('Login')} type="button" className="btn btn-secondary btn-block backBtn"
                        > Back to Login
                      </button>

                      </div>) : (

                        <div className="tab-content">
                          <div id="home1" className="tab-pane active">
                            <form className="pt-4 mt-1">
                              <div className="form-group">
                                <input type="text" name="email" placeholder="E-Mail Id / Mobile" className="form-control" value={this.state.email}
                                  onChange={this.onChangehandler} />
                                <div className="text-danger">{this.state.errMsgEmail}</div>
                              </div>
                              <div className="form-group">
                                <input type="password" name="password" placeholder="*************" className="form-control"
                                  value={this.state.password} onChange={this.onChangehandler} />
                                <div className="text-danger">{this.state.errMsgPwd}</div>
                              </div>

                              <div className="form-group pt-2 submitbtbn w-100">
                                <button type="button" className="btn btn-secondary btn-block" onClick={() => this.onSignInHandler('doctor')}>
                                  Log in  {isLoading ? (
                                    <span
                                      className="spinner-border spinner-border-sm ml-5"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                      <span></span>
                                    )}
                                </button>
                              </div>

                              <div className="form-group pt-2 forgotPassword">
                                <label className="check" onClick={() => this.showPage('Forgot Password')} >Forgot Password </label>
                              </div>

                            </form>
                          </div>

                        </div>

                      )}


                  </div>
                </div>

                <div className="col-xl-6 col-md-12 text-right">
                  <img className="img-fluid" src={process.env.REACT_APP_BASE_URL + "img/loginbg.PNG"} alt="" />
                </div>

              </div>
            </div>




          </Modal.Body>
          <Modal.Footer>

          </Modal.Footer>
        </Modal>

        <Modal className="fullscreen doctor-login" show={this.state.showHideLab}>
          <Modal.Header closeButton onClick={() => this.handleModalShowHide('Lab')}>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-md-12 pt-5">
                  <div className="login">
                    <img src={process.env.REACT_APP_BASE_URL + "img/logo.png"} alt="" />
                    <h3> {this.state.tabTitle} Lab Account </h3>

                    {this.state.activePage == 'Forgot Password' ? (

                      <div className="tab-content">
                        <ForgotPasswordLab />
                        <button onClick={() => this.showPage('Login')} type="button" className="btn btn-secondary btn-block backBtn"
                        > Back to Login
                      </button>

                      </div>) : (

                        <div className="tab-content">
                          <div id="home1" className="tab-pane active">
                            <form className="pt-4 mt-1">
                              <div className="form-group">
                                <input type="text" name="email" placeholder="E-Mail Id " className="form-control" value={this.state.email}
                                  onChange={this.onChangehandler} />
                                <div className="text-danger">{this.state.errMsgEmail}</div>
                              </div>
                              <div className="form-group">
                                <input type="password" name="password" placeholder="*************" className="form-control"
                                  value={this.state.password} onChange={this.onChangehandler} />
                                <div className="text-danger">{this.state.errMsgPwd}</div>
                              </div>

                              <div className="form-group pt-2 submitbtbn w-100">
                                <button type="button" className="btn btn-secondary btn-block"
                                  onClick={() => this.onSignInLabHandler()}>
                                  Log in  {isLoading ? (
                                    <span
                                      className="spinner-border spinner-border-sm ml-5"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                      <span></span>
                                    )}
                                </button>
                              </div>

                              <div className="form-group pt-2 forgotPassword">
                                <label className="check " onClick={() => this.showPage('Forgot Password')} >Forgot Password </label>
                              </div>

                            </form>
                          </div>

                        </div>)}


                  </div>
                </div>

                <div className="col-xl-6 col-md-12 text-right">
                  <img className="img-fluid" src={process.env.REACT_APP_BASE_URL + "img/loginbg.PNG"} alt="" />
                </div>

              </div>
            </div>




          </Modal.Body>
          <Modal.Footer>

          </Modal.Footer>
        </Modal>


        <Modal className="fullscreen doctor-login" show={this.state.showHidePharmacy}>
          <Modal.Header closeButton onClick={() => this.handleModalShowHide('pharmacy')}>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-md-12 pt-5">
                  <div className="login">
                    <img src={process.env.REACT_APP_BASE_URL + "img/logo.png"} alt="" />
                    <h3> {this.state.tabTitle} Pharmacy Account </h3>
                    {this.state.activePage == 'Forgot Password' ? (

                      <div className="tab-content">
                        <ForgotPasswordPharmacy />
                        <button onClick={() => this.showPage('Login')} type="button" className="btn btn-secondary btn-block backBtn"
                        > Back to Login
                        </button>

                      </div>) : (

                        <div className="tab-content">
                          <div id="home1" className="tab-pane active">
                            <form className="pt-4 mt-1">
                              <div className="form-group">
                                <input type="text" name="email" placeholder="E-Mail Id " className="form-control" value={this.state.email}
                                  onChange={this.onChangehandler} />
                                <div className="text-danger">{this.state.errMsgEmail}</div>
                              </div>
                              <div className="form-group">
                                <input type="password" name="password" placeholder="*************" className="form-control"
                                  value={this.state.password} onChange={this.onChangehandler} />
                                <div className="text-danger">{this.state.errMsgPwd}</div>
                              </div>

                              <div className="form-group pt-2 submitbtbn w-100">
                                <button type="button" className="btn btn-secondary btn-block"
                                  onClick={() => this.pharmacyLogin()}>
                                  Log in  {isLoading ? (
                                    <span
                                      className="spinner-border spinner-border-sm ml-5"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                      <span></span>
                                    )}
                                </button>
                              </div>


                              <div className="form-group pt-2 forgotPassword">
                                <label className="check " onClick={() => this.showPage('Forgot Password')} >Forgot Password </label>
                              </div>

                            </form>
                          </div>

                        </div>)}


                  </div>
                </div>

                <div className="col-xl-6 col-md-12 text-right">
                  <img className="img-fluid" src={process.env.REACT_APP_BASE_URL + "img/loginbg.PNG"} alt="" />
                </div>

              </div>
            </div>




          </Modal.Body>
          <Modal.Footer>

          </Modal.Footer>
        </Modal>
      </React.Fragment>

    );
  }
}

export default Form;