import React, { Component } from 'react';
 
class About extends Component {
  render() {
    return <div className="container">
            <div className="row">
                <div className="col-sm-12">
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                    <h3>About us</h3>
                    <p>No lockdown on your practise! Consult online from the convenience of your home.</p>
                    <p>Use our world class teleconsult platform to stay in touch with patients and allay their fears..</p>
                </div>

                
            </div>

            

          </div>
  }
}
 
export default About;