import React, { Component } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import Rating from "./rating";
import NumberFormat from 'react-number-format';



class DoctorDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      patientObject: '',
      id: '',
      doctor: '',
      doctorHospital: [],
      doctorRatingList: [],
      totalReview: '',
      rating: '',


      reviewFeedback: '',
      reviewTitle: '',
      status: false,
      ErrorMsgTitle: '',
      ErrorMsgFeedback: '',
      ErrorMsgRating: '',
      isLoading: false,
      reviewEligibility: 0,
      dublicateReviewMessage: '',
      ratingByUser: 0,
    };
  }




  getDoctorDetail(id) {

    var ApiPath = "getDoctorDetail/" + id;
    axios.get(process.env.REACT_APP_API_URL + ApiPath)
      .then((response) => {


        if (response.data.status === true) {

          console.log("-------- Get doctors details ----------", response);
          this.setState({
            doctor: response.data.result[0],
            rating: response.data.totalRating,
          });

          console.log("details: ", response.data.result[0].id);


        } else {

          console.log("-------- Get doctors details false ----------", response);

        }
      });
  }

  getDoctorHospital(id) {

    var ApiPath = "getDoctorHospital/" + id;
    axios.get(process.env.REACT_APP_API_URL + ApiPath)
      .then((response) => {

        if (response.data.status === true) {
          console.log("-------- Get doctors hospital details ----------", response);
          this.setState({
            doctorHospital: response.data.result
          });

        } else {

          console.log("-------- Get doctors hospital details false ----------", response);

        }
      });
  }

  checkReviewEligibility(memberContactId) {


    var body = {
      doctorDetailId: this.props.match.params.doctorId,
      memberContactId: memberContactId,
    }

    axios.post(process.env.REACT_APP_API_URL + 'checkReviewEligibility', body)
      .then((response) => {

        if (response.data.status === true) {
          this.setState({
            reviewEligibility: response.data.count
          });

        }
      }).catch((error) => {
        console.log("Catch error: ", error);
      })
  }


  getDoctorRating(id) {

    var ApiPath = "getDoctorRating/" + id;
    axios.get(process.env.REACT_APP_API_URL + ApiPath)
      .then((response) => {

        if (response.data.status === true) {
          console.log("-------- Get doctors rating details ----------", response);
          this.setState({
            doctorRatingList: response.data.result,
            totalReview: response.data.totalReview,
          });

        } else {

          console.log("-------- Get doctors rating details false ----------", response);

        }
      });
  }

  componentDidMount() {

    var patientObject = JSON.parse(localStorage.getItem("userData"));

    if (patientObject) {
      var searchParameter = this.props.match.params.doctorId;

      this.setState({
        patientObject: patientObject
      })

      this.getDoctorDetail(searchParameter);
      this.getDoctorHospital(searchParameter);
      this.getDoctorRating(searchParameter);

      this.checkReviewEligibility(patientObject.membercontactId);
    }
  }

  handleModalShowHide() {
    this.setState({
      showHide: !this.state.showHide,
    })
  }



  reviewSubmitHandler = (event) => {
    event.preventDefault();

    if (this.state.reviewEligibility > 0) {


      this.setState({
        status: false,
        dublicateReviewMessage: ' You have already submitted the review ',
      })

      setTimeout(() => {
        this.setState({
          dublicateReviewMessage: '',
        })
      }, 5000);
      return false;
    }


    this.setState({ isLoading: true });

    var body = {
      doctorDetailId: this.props.match.params.doctorId,
      memberContactId: this.state.patientObject.membercontactId,
      rating: this.state.ratingByUser,
      title: this.state.reviewTitle,
      feedback: this.state.reviewFeedback
    }


    console.log("Review Inputes : ", body);

    // Pass token in the header
    let auth_token = localStorage.getItem('token');
    let header_token = { 'x-access-token': auth_token };

    axios
      .post(process.env.REACT_APP_API_URL + "createDoctorReview", body, { headers: header_token })
      .then((response) => {
        this.setState({ isLoading: false });
        console.log(response);

        if (response.data.status === true) {
          this.setState({

            message: response.data.message,
            status: true,
            reviewFeedback: '',
            reviewTitle: '',
            ratingByUser: '',

            ErrorMsgTitle: '',
            ErrorMsgFeedback: '',
            ErrorMsgRating: '',

          })

          this.componentDidMount();
        }

        else {

          this.setState({
            ErrorMsgTitle: response.data.errors.title,
            ErrorMsgFeedback: response.data.errors.feedback,
            ErrorMsgRating: response.data.errors.rating,
          })
        }

        setTimeout(() => {
          this.setState({
            ErrorMsgTitle: '',
            ErrorMsgFeedback: '',
            ErrorMsgRating: '',
            status: false,
          });
        }, 5000);


      }).catch((error) => {

        console.log("Catch Error: ", error);
      })


  }


  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      ratingByUser: event.target.value

    });
  }




  render() {

    // Authenticate
    const login = localStorage.getItem("isLoggedIn");
    if (!login) {
      return <Redirect to="/" />;
    }

    const doctor = this.state.doctor;
    const hospitals = this.state.doctorHospital;
    const ratings = this.state.doctorRatingList;


    return (
      <React.Fragment>
        <section className="docter-details-sec patient-main-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div className="detail-left-col">
                  <div className="row">
                    <div className="col-sm-5">
                      <div className="doct-img">
                        {doctor.profile_pic == null ?
                          (<img alt="image-not-found" src={process.env.REACT_APP_BASE_URL + "img/picture-not-available.jpg"} />) :
                          (<img alt="member-img" className="imageList" src={process.env.REACT_APP_IMAGE_LOCATION + "doctor/profile_picture/" + doctor.profile_pic} />)
                        }
                      </div>

                      {doctor.live == 'Online' ? (<h3>ONLINE</h3>) : (<h3 className="off-line">OFFLINE</h3>)}

                    </div>
                    <div className="col-sm-7 text-left">
                      <h4>{doctor.title} {doctor.name} </h4>
                      <h5> {doctor.qualifications} </h5>
                      <p> {doctor.specializations && doctor.specializations.substring(100,0) } 
                      {doctor.specializations && doctor.specializations.length >100 ? ('...') : ('') }   </p>
                      <p><b>Experience - {doctor.experience} Years</b></p>

                      <Rating rating={this.state.rating} />

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="specialists-col">
                  <h3>Specialities</h3>
                  <p> {doctor.specializations} </p>
                  <h3>Registration Number &amp; Council</h3>
                  <p>{doctor.registration_no}</p>
                  <h3>Languages Spoken</h3>
                  <p>{doctor.languages}</p>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="book-appointment-col">
                  <Link to={`/patient/bookappointment/${doctor.id}`} className="book-appointment-btn">Book Appointment</Link>
                  <ul className="user-review-list">
                    {/* <li><i className="spt-img thumb-icon"></i> 99%</li> */}
                    <li><i className="spt-img feedback-icon"></i> {this.state.totalReview} Review </li>
                    <li><i className="spt-img location-icon"></i> India</li>
                    <li><i className="spt-img curency-icon"></i>  <NumberFormat value={doctor.fee} displayType={'text'} thousandSeparator={true} prefix={'Rs. '} /></li>
                  </ul>
                  {/* <ul className="contact-list">
                    <li><Link to="#"><i className="spt-img teg-icon"></i></Link></li>
                    <li><Link to="#"><i className="spt-img message-icon"></i></Link></li>
                    <li><Link to="#"><i className="spt-img phone-icon"></i></Link></li>
                    <li><Link to="#"><i className="spt-img video-icon"></i></Link></li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="hospital-details-sec patient-main-section">
          <div className="container">
            <div className="hospital-details-row">
              <Tabs defaultActiveKey="home" transition={false} id="noanim-tab-example">
                <Tab eventKey="home" title=" Timings">
                  <div className="tab-content">
                    <div className="container tab-pane active">


                      <div className="row visit-timings border-bottom">





                        {hospitals.map((hospital, index) => (
                          <React.Fragment>
                            {hospital.workingdays == 'Mon' ? (
                              <div className="col-sm-6">
                                <h3 title="title"> {hospital.hospital_name} </h3>
                                <p title="description">{hospital.description}</p>
                                <p><i className="spt-img location-icon"></i>
                                  {hospital.address},
                                {hospital.city}
                                </p>
                              </div>
                            ) : ('')}
                            {hospital.workingtimefrom1 != null ? (
                              <div className="col-sm-6">
                                <h3>{hospital.workingdays}</h3>
                                <p>{hospital.workingtimefrom1} - {hospital.workingtimeto1} - Video Consultation / Inclinic</p>
                                <p>{hospital.workingtimefrom2} - {hospital.workingtimeto2}  Video Consultation / Inclinic</p>
                                <br />
                              </div>
                            ) : ('')}
                          </React.Fragment>


                        ))}

                        {/* <div className="col-sm-6">
                              <h3>Sunday</h3>
                              <p>11:00 - 12:00 Video Consultation / Inclinic</p>
                              <p>14:00 - 15:00  Video Consultation / Inclinic</p>
                              <br/>
                            </div> */}
                      </div>




                    </div>
                  </div>

                </Tab>
                <Tab eventKey="home1" title="About Doctor ">
                  <div className="tab-content">
                    <div className="container tab-pane active">
                      <div className="row visit-timings border-bottom">
                        <div className="col-sm-12">
                          <h3>About Doctor</h3>


                          <div
                            dangerouslySetInnerHTML={{ __html: doctor.description }}>
                          </div>


                          <br />
                          <h3> EDUCATIONAL QUALIFICATIONS AND CERTIFICATES:</h3>

                          <div
                            dangerouslySetInnerHTML={{
                              __html: doctor.educationandcertificate
                            }}></div>

                        </div>

                      </div>

                    </div>
                  </div>

                </Tab>
                <Tab eventKey="home3" title=" Reviews ">
                  <div className="tab-content">
                    <div id="review" className="container review-tab tab-pane active">
                      <br />
                      <div className="row">
                        <div className="col-sm-6">
                          <h2> {this.state.totalReview} Review</h2>
                        </div>

                        <div className="col-sm-6">
                          <div className="add-member-btn">
                            <button onClick={() => this.handleModalShowHide()} type="button"><span className="spt-img add-icon"></span>Write a review</button>
                          </div>
                        </div>

                      </div>

                      {/* <h2>{this.state.totalReview} Review</h2> */}


                      {ratings.map((rating, index) => (
                        <div className="review-row border-bottom">
                          <div className="mamber-pro" key={index}>
                            <span className="member-img">




                              {rating.profile_pic == null ?
                                (<img alt="image-not-found" src={process.env.REACT_APP_BASE_URL + "img/picture-not-available.jpg"} />) :
                                (<img alt="member-img" src={process.env.REACT_APP_IMAGE_LOCATION + "patient/profile_picture/" + rating.profile_pic} />)
                              }

                            </span>
                            <span className="mamber-info">
                              <h3>{rating.first_name} {rating.last_name}</h3>
                              <p><span className="age">{rating.age} years Old</span> | <span className="jendeer">{rating.gender}</span></p>
                              <p>{rating.reviewdate}</p>

                              <Rating rating={rating.rating} />

                              <p>{rating.feedback}</p>
                              <br />
                            </span>
                          </div>
                        </div>
                      ))}

                    </div>
                  </div>
                </Tab>

              </Tabs>


              <Modal className="review-model" show={this.state.showHide}>
                <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                  {doctor.title} {doctor.name}
                </Modal.Header>
                <Modal.Body>

                  <div className="modal-body">

                    <form onSubmit={this.reviewSubmitHandler}>

                      <div className="review-form-col">
                        <div className="row">
                          <div className="col-sm-12 form-group">


                            {this.state.dublicateReviewMessage ? (<div className="alert alert-danger"> {this.state.dublicateReviewMessage}</div>) : ('')}

                            {this.state.status ? (<div className="alert alert-success"> {this.state.message}</div>) : ('')}

                            <Rating rating={this.state.rating} />


                          </div>
                          <div className="col-sm-12 form-group">
                            <label htmlFor="">Title (Maximum Allowed 100 characters)* </label>
                            <input type="text" className="form-control" name="reviewTitle" value={this.state.reviewTitle} onChange={this.changeHandler}  maxLength="100"/>
                            <div className="text-danger">{this.state.ErrorMsgTitle}</div>
                          </div>
                          <div className="col-sm-12 form-group">
                            <label htmlFor="">Review (Maximum Allowed 200 characters)*</label>
                            <textarea id="review_desc" name="reviewFeedback" value={this.state.reviewFeedback} maxlength="200" className="form-control" onChange={this.changeHandler}></textarea>

                            <div className="text-danger">{this.state.ErrorMsgFeedback}</div>
                          </div>

                          <div className="col-sm-12 form-group">
                            <label htmlFor="">Rating *</label>






                            <div className="rating-col">
                              <div className="check-item">
                                <input type="checkbox" name="ratingByUser" id="r1"
                                  classs="describe-input" value="1" onChange={this.changeHandler}
                                  checked={this.state.ratingByUser >= 1 ? true : false} />
                                <label className="rating-star spt-img" htmlFor="r1"></label>
                              </div>
                              <div className="check-item">
                                <input type="checkbox" name="ratingByUser" value="2" id="r2"
                                  classs="describe-input" checked={this.state.ratingByUser >= 2 ? true : false} onChange={this.changeHandler} />
                                <label className="rating-star spt-img" htmlFor="r2"></label>
                              </div>
                              <div className="check-item">
                                <input type="checkbox" name="ratingByUser" value="3" id="r3"
                                  classs="describe-input" checked={this.state.ratingByUser >= 3 ? true : false} onChange={this.changeHandler} />
                                <label className="rating-star spt-img" htmlFor="r3"></label>
                              </div>
                              <div className="check-item">
                                <input type="checkbox" name="ratingByUser" value="4" id="review04"
                                  classs="describe-input" checked={this.state.ratingByUser >= 4 ? true : false} onChange={this.changeHandler} />
                                <label className="rating-star spt-img" htmlFor="review04"></label>
                              </div>
                              <div className="check-item">
                                <input type="checkbox" name="ratingByUser" value="5" id="review05"
                                  classs="describe-input" checked={this.state.ratingByUser >= 5 ? true : false} onChange={this.changeHandler} />
                                <label className="rating-star spt-img" htmlFor="review05"></label>
                              </div>
                            </div>


                            {/* <select className="form-control" name="reviewRating" onChange={this.changeHandler}>
                              <option>Select Rateing</option>
                              <option value="1">1 Start</option>
                              <option value="2">2 Start</option>
                              <option value="3">3 Start</option>
                              <option value="4">4 Start</option>
                              <option value="5">5 Start</option>
                            </select> */}


                            <div className="text-danger">{this.state.ErrorMsgRating}</div>
                          </div>


                        </div>

                        <div className="submit-section">
                          <button type="submit" className="btn btn-primary submit-btn">Add Review</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Modal.Body>
              </Modal>


            </div>
          </div>
        </section>
      </React.Fragment>

    )

  }
}

export default DoctorDetail;