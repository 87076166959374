import React, { Component } from 'react';

import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import Rating from "./rating";


class LeftPanelDoctorDetail extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            doctor: '',
            rating:'',
        };
    }

    getDoctorDetail(id) {

        var ApiPath = "getDoctorDetail/" + id;
        axios.get(process.env.REACT_APP_API_URL + ApiPath)
            .then((response) => {


                if (response.data.status === true) {

                    console.log("-------- Get doctors details ----------", response);
                    this.setState({
                        doctor: response.data.result[0],
                        rating:response.data.totalRating,
                    });

                    console.log("details: ", response.data.result[0].id);


                } else {

                    console.log("-------- Get doctors details false ----------", response);

                }
            });
    }

    getDoctorRating(id) {

        var ApiPath = "getDoctorRating/" + id;
        axios.get(process.env.REACT_APP_API_URL + ApiPath)
            .then((response) => {

                if (response.data.status === true) {
                    console.log("-------- Get doctors rating details ----------", response);
                    this.setState({
                        doctorRatingList: response.data.result,
                        totalReview: response.data.totalReview,
                    });

                } else {

                    console.log("-------- Get doctors rating details false ----------", response);

                }
            });
    }

    componentDidMount() {
        var searchParameter = this.props.id;
        // console.log(searchParameter);
        this.getDoctorDetail(searchParameter);
        this.getDoctorRating(searchParameter);
    }

    render() {
        const doctor = this.state.doctor;

        return (
            <React.StrictMode>
                <div className="col-sm-3">
                    <div className="detail-left-col">
                        <div className="doct-img">
                            {doctor.profile_pic == null ?
                                (<img alt="image-not-found" src={process.env.REACT_APP_BASE_URL + "img/picture-not-available.jpg"} />) :
                                (<img alt="member-img" className="imageList" src={process.env.REACT_APP_IMAGE_LOCATION + "doctor/profile_picture/" + doctor.profile_pic} />)
                            }
                        </div>
                        {doctor.live == 'Online' ? (<h3>ONLINE</h3>) : (<h3 className="off-line">OFFLINE</h3>)}
                        <h4>{doctor.title} {doctor.name}</h4>
                        <h5>{doctor.qualifications}</h5>
                        <p> {doctor.specializations} </p>
                        <p><b>Experience- {doctor.experience} Years</b></p>
                        <Rating rating={this.state.rating}/>
                    </div>
                </div>

            </React.StrictMode>

        )

    }
}

export default LeftPanelDoctorDetail;