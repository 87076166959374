import Axios from 'axios';
import React, { Component } from 'react';
import { NavLink, Link } from "react-router-dom";

// import '../css/doctor.css';




class leftNav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            label: localStorage.getItem("doctorLive"),
            // status: localStorage.getItem("doctorLive"),
        }
    }

    

    componentDidMount(){
    
    }



    render() {
        return (
            <aside className="main-sidebar">
                <section className="sidebar">
                    <div className="side-nav">
                        
                        <ul>
                            <li>
                                <NavLink to="/pharmacy/dashboard" >
                                    <span className="spt-img dashboard-icon"></span> Dashboard
                                </NavLink>
                            </li>
                            
                            <li>
                                <NavLink to="/pharmacy/referral">
                                    <span className="spt-img patients-icon"></span> Referrals
                                </NavLink>
                            </li>

                            <li>
                                <NavLink to="/pharmacy/setting">
                                <span className="spt-img setting-icon"></span> Setting
                                </NavLink>
                            </li>
                            
                            {/* <li>
                                <NavLink to="/doctor/settings" activeClassName="active">
                                    <span className="spt-img setting-icon"></span> Setting
                                </NavLink>
                            </li> */}
                        </ul>
                    </div>
                </section>
            </aside>



        )

    }
}

export default leftNav;