import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import AddMember from './addMember';
import axios from "axios";
import jwt_decode from "jwt-decode";




export default class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showHide: false,
      activeMember: '',
      navigate: false,
    };
  }

  getMemberList() {
    var patientDetail = JSON.parse(localStorage.getItem("userData"));
    if (patientDetail) {

      var menberId = patientDetail.id;

      console.log("Patient Id 123 :", menberId);

      // Pass token in the header
      let auth_token = localStorage.getItem('token');
      let header_token = { 'x-access-token': auth_token };

      axios.get(process.env.REACT_APP_API_URL + "getMemberList/" + menberId,
        { headers: header_token })
        .then((response) => {

          console.log("Get member List on dashboard : ", response);

          if (response.data.status === true) {
            this.setState({
              data: response.data.result,
              activeMember: localStorage.getItem("filterByUserId"),
            });
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log('get member list catch error', error);
        });
    }

  }


  componentDidMount() {
    this.getMemberList();


    

    // Manage Logout when referesh the page 
    if(localStorage.getItem('token')) {
        const jwt_Token_decoded = jwt_decode(localStorage.getItem('token'));
        console.log("decode token :", jwt_Token_decoded);
        if (jwt_Token_decoded.exp * 1000 < Date.now()) {
            localStorage.clear();
            window.location = process.env.REACT_APP_BASE_URL;
            console.log("----- logout user -----");
        } 
    }

  }

  onLogoutHandler = () => {
    localStorage.clear();
    this.setState({
      navigate: true,
    });
    window.location = process.env.REACT_APP_BASE_URL;
  };





  render() {

    console.log("get active member ", this.state.activeMember);

    const { navigate } = this.state;

    // Authenticate
    const login = localStorage.getItem("isLoggedIn");
    if (!login) {
      return <Redirect to="/" />;
    }

    if (navigate) {
      return <Redirect to="/" />;
    }


    console.log("get member data :", this.state.data);

    const members = this.state.data;





    return (
      <div className="container">
        <section className="dashboard-section patient-main-section">
          <div className="container">
            <div className="dashboard-col">
              <h2><span className="spt-img dashboard-icon"></span> Dashboard</h2>
              <div className="dashboard-user">
                
                {members.map((member, index) => (
                  <Link to={`/patient/member/${member.id}`} key={member.id}>
                    <div className="dashboard-user-col">
                      <div className={this.state.activeMember == member.id ? 'mamber-pro active-user' : 'mamber-pro'}>
                        <span className="member-img" >

                          {member.profile_pic == null ?
                            (<img alt="profilePic" src={process.env.REACT_APP_BASE_URL + process.env.REACT_APP_IMAGE_ICON} />
                            )
                            :
                            (
                              <img alt="profilepic" src={process.env.REACT_APP_IMAGE_LOCATION + "patient/profile_picture/" + member.profile_pic} />
                            )
                          }

                          {/* <img alt="" src={process.env.REACT_APP_BASE_URL+"img/member-img.png"}/> */}
                        </span>
                        <span className="mamber-info">
                          <h3 className="captilize">{member.first_name}</h3>
                          <p><span className="age">{member.age} yrs</span> | <span className="jendeer">{member.gender}</span></p>
                          <p>{member.relation}</p>
                        </span>
                      </div>
                    </div>
                  </Link>
                ))}


              </div>

              <AddMember />




              <div className="dashboard-tab">


                <Link to="/patient/appointment">
                  <span className="spt-img appointments-big-icon"></span> Appointments
                </Link>

                <Link to="/patient/consultation">
                  <span className="spt-img cnsultations-big-icon"></span> Consultations
                </Link>
                <Link to="/patient/prescription">
                  <span className="spt-img prescriptions-big-icon"></span> Prescriptions
                </Link>

                <Link to="/patient/investigation">
                  <span className="spt-img investigations-big-icon"></span> Investigations
                </Link>
                <Link to="/patient/payment">
                  <span className="spt-img payment-big-icon"></span> Payment
                </Link>
                {/* <Link to="#">
                  <span className="spt-img favourites-big-icon"></span> Favourites
                </Link>
                <Link to="/patient/profileSetting">
                  <span className="spt-img profile-big-icon"></span> Profile Setting
                </Link> */}
                <Link to="/" onClick={this.onLogoutHandler} >
                  <span className="spt-img Logout-icon"></span> Logout
                </Link>

              </div>
            </div>


          </div>
        </section>


      </div>
    );
  }
}