import React, { Component } from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import axios from "axios";
import {Redirect } from "react-router-dom";

export default class Slots extends Component {
    constructor(props) {
        super(props);
        this.state = {

            hospitalDoctorDetailId: '',

            workingdays: '',
            slotinterval: '',
            workingtimefrom1: '',
            workingtimeto1: '',
            workingtimefrom2: '',
            workingtimeto2: '',



            timingLists: [],
            showHide: false,
            // timing: '',
            slots: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
            hours: [],

            status: false,
            message: '',
            errorMsg: '',
            isLoading: false,

            ErrorWorkingtimefrom1: '',
            ErrorWorkingtimeto1: '',
            ErrorWorkingtimefrom2: '',
            ErrorWorkingtimeto2: '',
            ErrorSlotinterval:'',
        };
    }



    getTimingList(id) {
        axios.get(process.env.REACT_APP_API_URL + "doctor/getTimeSlot/" + id)
            .then((response) => {

                if (response.data.status) {

                    this.setState({
                        timingLists: response.data.result
                    })
                }

            }).catch((error) => {
                console.log('enter catch error');
                console.log(error);
            });
    }


    getHours() {
        axios.get(process.env.REACT_APP_API_URL + "getSlotList")
            .then((response) => {

                console.log("get slots : ", response);

                if (response.data.status) {

                    this.setState({
                        hours: response.data.result
                    })
                }

            }).catch((error) => {
                console.log('enter catch error');
                console.log(error);
            });
    }


    getSlotDetail(id) {
        axios.get(process.env.REACT_APP_API_URL + "doctor/getSlotDetail/" + id)
            .then((response) => {

                console.log(response);
                if (response.data.status) {
                    this.setState({

                        workingdays: response.data.result[0].workingdays,
                        slotinterval: response.data.result[0].slotinterval,
                        workingtimefrom1: response.data.result[0].workingtimefrom1,
                        workingtimeto1: response.data.result[0].workingtimeto1,
                        workingtimefrom2: response.data.result[0].workingtimefrom2,
                        workingtimeto2: response.data.result[0].workingtimeto2,
                        

                        //timing: response.data.result[0]
                    })
                }
            }).catch((error) => {
                console.log('enter catch error');
                console.log(error);
            });
    }


    timingHandleSubmit = (event) => {
        event.preventDefault();
        this.setState({ isLoading: true });

        var data = {
            id: this.state.hospitalDoctorDetailId,
            workingtimefrom1: this.state.workingtimefrom1,
            workingtimeto1: this.state.workingtimeto1,
            workingtimefrom2: this.state.workingtimefrom2,
            workingtimeto2: this.state.workingtimeto2,
            slotinterval: this.state.slotinterval,
        }

       


        // if(this.state.workingtimefrom2 >= this.state.workingtimeto2){
        //     this.setState({ 
        //         ErrorWorkingtimefrom2: 'Please select valid slot',
        //         isLoading: false
        //     });
        //     return false;
        // }else{
            
        //     this.setState({ 
        //         ErrorWorkingtimefrom2: '',
        //     });
        // }

        


        // this.setState({

        //     workingtimefrom1:'Invalid time',
        // })

        this.updateTiming(data);
    }


    updateTiming(body) {

         // Pass token in the header
        let auth_token = localStorage.getItem('token');
        let header_token = { 'x-access-token': auth_token };

        axios.post(process.env.REACT_APP_API_URL + "doctor/updateTiming", body, { headers: header_token })
            .then((response) => {

                this.setState({
                    isLoading: false
                });

                if (response.data.status == true) {

                    this.setState({
                        status: true,
                        message: response.data.message,
                        errorMsg: '',
                        isLoading: false,

                        ErrorWorkingtimefrom1: '',
                        ErrorWorkingtimeto1: '',
                        ErrorWorkingtimefrom2: '',
                        ErrorWorkingtimeto2: '',
                        ErrorSlotinterval:'',
                    });
                } else {

                    this.setState({
                        status: false,
                        // errorMsg: response.data.errors,
                        ErrorWorkingtimefrom1: response.data.errors.workingtimefrom1,
                        ErrorWorkingtimeto1: response.data.errors.workingtimeto1,
                        ErrorWorkingtimefrom2: response.data.errors.workingtimefrom2,
                        ErrorWorkingtimeto2: response.data.errors.workingtimeto2,
                        ErrorSlotinterval: response.data.errors.slotinterval,
                        
                    });
                }
                // console.log(response);
                this.componentDidMount();

            }).catch((error) => {
                console.log("Catch error ");
            })
    }


    handleModalShowHide(id) {
        this.setState({
            showHide: !this.state.showHide,
            message: '',
            hospitalDoctorDetailId: id,
            status: false,
        })
        this.getSlotDetail(id);
        // this.componentDidMount();
    }


    onChangehandler = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }




    componentDidMount() {

        var LogedInUserObject = JSON.parse(localStorage.getItem("userData"));
        if (LogedInUserObject) {
            var doctorDetailId = LogedInUserObject.doctordetailId;
            this.getTimingList(doctorDetailId);
            this.getHours();
        }
    }

    render() {


        // Redirect User if not login
        const login = localStorage.getItem("isLoggedIn");
        if (!login) {
            return <Redirect to="/" />;
        }
        

        return (

            <React.Fragment>

                <div class="profile_deatils pt-xl-5">
                    <div class="card-header formTitle">
                        <h5>Doctor Calendar Timing </h5>
                    </div>
                    <br />

                    <div className="table-responsive previous-appointment-list">
                        <table className="table">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Day</th>
                                    <th>From Session -1</th>
                                    <th>To Session -1</th>
                                    <th>From Session -2</th>
                                    <th>To Session -2</th>
                                    <th>Slots</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {this.state.timingLists.map((list, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{list.workingdays}</td>
                                        <td>{list.workingtimefrom1}</td>
                                        <td>{list.workingtimeto1}</td>
                                        <td>{list.workingtimefrom2}</td>
                                        <td>{list.workingtimeto2}</td>
                                        <td>{list.slotinterval}</td>
                                        <td><button className="btn btn-primary" onClick={() => this.handleModalShowHide(list.id)}>Update</button></td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </div>


                <Modal className="doctor-slots-modal" show={this.state.showHide}>
                    <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                        <h5 class="modal-title" id="exampleModalLabel">Update Time   <span></span></h5>

                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.timingHandleSubmit}>
                            <div class="row">

                                <div class="col-sm-12">
                                    {
                                        this.state.status === true ? (<div class="alert alert-success">
                                            {this.state.message}
                                        </div>) : ("")
                                    }
                                </div>
                                <div class="col-sm-6 form-group">
                                    <label> Visit day*</label>
                                    <input type="text" disabled class="form-control" name="workingdays" value={this.state.workingdays} />
                                </div>

                                <div class="col-sm-6 form-group">
                                    <label> Calendar Slot*</label>
                                    <select name="slotinterval" class="form-control" onChange={this.onChangehandler}>
                                        <option value=""> --Select Slot-- </option>
                                        {this.state.slots.map((slot, index) => (
                                            <option value={slot} selected={slot == this.state.slotinterval ? "selected" : ""} key={index}> {slot} {slot == 1 ? ('Minute') : ('Minutes')} </option>
                                        ))}
                                    </select>


                                    
                                    <div className="text-danger">{this.state.ErrorSlotinterval}</div>
                                </div>

                                <div class="col-sm-6 form-group">
                                    <label>  (Session-1) From </label>
                                    <select class="form-control" name="workingtimefrom1" onChange={this.onChangehandler}>
                                        <option value="">From</option>
                                        {this.state.hours.map((hour, index) => (
                                            <option value={hour.slot} selected={hour.slot === this.state.workingtimefrom1 ? "selected" : ""} key={index}>{hour.slot}</option>
                                        ))}

                                    </select>
                                    <div className="text-danger">{this.state.ErrorWorkingtimefrom1}</div>
                                </div>
                                <div class="col-sm-6 form-group">
                                    <label>(Session-1) To </label>
                                    <select class="form-control" name="workingtimeto1" onChange={this.onChangehandler}>
                                        <option value="">To</option>
                                        {this.state.hours.map((hour, index) => (
                                            <option value={hour.slot} selected={hour.slot === this.state.workingtimeto1 ? "selected" : ""} key={index}>{hour.slot}</option>
                                        ))}

                                    </select>
                                    <div className="text-danger">{this.state.ErrorWorkingtimeto1}</div>
                                </div>


                                <div class="col-sm-6 form-group">
                                    <label>  (Session-2) From</label>
                                    <select class="form-control" name="workingtimefrom2" onChange={this.onChangehandler}>
                                        <option value="">From</option>
                                        {this.state.hours.map((hour, index) => (
                                            <option value={hour.slot} selected={hour.slot === this.state.workingtimefrom2 ? "selected" : ""} key={index}>{hour.slot}</option>
                                        ))}

                                    </select>
                                    <div className="text-danger">{this.state.ErrorWorkingtimefrom2}</div>
                                </div>
                                <div class="col-sm-6 form-group">
                                    <label>(Session-2) To </label>
                                    <select class="form-control" name="workingtimeto2" onChange={this.onChangehandler}>
                                        <option value="">To</option>
                                        {this.state.hours.map((hour, index) => (
                                            <option value={hour.slot} selected={hour.slot === this.state.workingtimeto2 ? "selected" : ""} key={index}>{hour.slot}</option>
                                        ))}

                                    </select>
                                    <div className="text-danger">{this.state.ErrorWorkingtimeto2}</div>
                                </div>


                                <div class="col-md-12 pt-xl-4">
                                    <button type="submit" class="pay-btn" >Save
                                    {this.state.isLoading ? (
                                            <span
                                                className="spinner-border spinner-border-sm ml-5"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        ) : (
                                                <span></span>
                                            )}</button>
                                </div>


                            </div>
                        </form>


                    </Modal.Body>
                </Modal>


            </React.Fragment>

        );

    }
}
