import Axios from 'axios';
import React, { Component } from 'react';
import { NavLink, Link } from "react-router-dom";

// import '../css/doctor.css';




class leftNav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            label: localStorage.getItem("doctorLive"),
            // status: localStorage.getItem("doctorLive"),
        }
    }

    onOffStatus(status) {

        var doctorObject = JSON.parse(localStorage.getItem("userData"));
        var id = doctorObject.doctordetailId;

        var body;

        if (status == "Online") {
            
            this.setState({
                label: "Offline",
            })

            body = {
                id: id,
                status: "Offline",
            }
            
        } else {
            
            this.setState({
                label: "Online",
            })

            body = {
                id: id,
                status: "Online",
            }
        }

        localStorage.setItem("doctorLive",body.status);

        Axios.post(process.env.REACT_APP_API_URL + 'doctor/changeLiveStatus', body)
            .then((response) => {
                console.log(response);

            }).catch((error) => {
                console.log("Catch error :", error);
            })



    }

    componentDidMount(){
    
    }



    render() {
        return (
            <aside className="main-sidebar">
                <section className="sidebar">
                    <div className="side-nav">
                        
                        <ul>
                            <li>
                                <NavLink to="/lab/dashboard" >
                                    <span className="spt-img dashboard-icon"></span> Dashboard
                                </NavLink>
                            </li>
                            
                            <li>
                                <NavLink to="/lab/referral">
                                    <span className="spt-img patients-icon"></span> Referrals
                                </NavLink>
                            </li>

                            <li>
                                <NavLink to="/lab/setting">
                                <span className="spt-img setting-icon"></span> Setting
                                </NavLink>
                            </li>

                            {/* <li>
                                <NavLink to="/doctor/settings" activeClassName="active">
                                    <span className="spt-img setting-icon"></span> Setting
                                </NavLink>
                            </li> */}
                        </ul>
                    </div>
                </section>
            </aside>



        )

    }
}

export default leftNav;