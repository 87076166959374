import React, { Component } from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import LEFTNAV from "./includes/leftnav";
import axios from "axios";
import ReactDOM from 'react-dom';
import { Multiselect } from 'multiselect-react-dropdown';




class LabSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            status: false,
            message: '',
            password:'',
            cpassword:'',
            errMsgPassword:'',
            errMsgCpassword:'',
            id :null,
        };
    }

    onChangehandler = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }





    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ isLoading: true });


        var body = {
            password:this.state.password,
            cpassword:this.state.cpassword,
            id:this.state.id,
        }


      



        // Pass token in the header
        let auth_token = localStorage.getItem('token');
        let header_token = { 'x-access-token': auth_token };

        axios.post(process.env.REACT_APP_API_URL + "lab/changePassword", body, 
        { headers: header_token })
            .then((response) => {

                console.log("Response of update password :", response);

                this.setState({ isLoading: false });
                if (response.data.status) {

                    this.setState({
                        status:true,
                        message:response.data.message,

                        password:'',
                        cpassword:'',
                        errMsgPassword:'',
                        errMsgCpassword:'',
                    })

                }else{
                    this.setState({
                        status:false,
                        errMsgPassword:response.data.errors.password,
                        errMsgCpassword:response.data.errors.cpassword,
                    })
                }
                
                setTimeout(() => {
                    this.setState({
                        status: false,
                        errMsgPassword:'',
                        errMsgCpassword:'',

                    });
                }, 5000);


            })
            .catch((error) => {
                console.log('enter catch error into the change password');
                console.log(error);
            })

    }






    componentDidMount() {
        var user = JSON.parse(localStorage.getItem("userData"));
        if(user){
            this.setState({
                 id : user.id,
            })    
        }
    }




    render() {


        return (
            <section className="doctor-consultation-view  doctor-main-section">
                <div className="container-fulid">
                    <div>

                        <LEFTNAV />
                        <div class="content-wrapper">
                            <div class="patient-appointment-list-right-col consultation-view-right">
                                <section class="doctor-consultation-view">
                                    <div class="doctor-consultation-view">
                                        <Tabs defaultActiveKey="profile" transition={false} id="doctorSetting" className="nav-item">
                                            <Tab eventKey="profile" title="Profile" class="nav-item">
                                                <div class="list-table">
                                                    <div class="profile_deatils pt-xl-5">
                                                        <form onSubmit={this.handleSubmit}>


                                                            {this.state.status == true ? (<div className="alert alert-success">
                                                                {this.state.message}
                                                            </div>) : ('')}

                                                            <div class="card-header formTitle">
                                                                <h5>Change Password </h5>
                                                            </div>
                                                            <br />

                                                            <div class="row">
                                                                <div class="col-sm-6 form-group">
                                                                    <label for="">Password*</label>
                                                                    <input type="password" placeholder="Enter password" class="form-control" name="password"  value={this.state.password}
                                                                        onChange={this.onChangehandler} />
                                                                    <div className="text-danger">{this.state.errMsgPassword}</div>
                                                                </div>

                                                                <div class="col-sm-6 form-group">
                                                                    <label for="">Confirm Password *</label>
                                                                    <input type="password" placeholder="Enter confirm password" class="form-control" name="cpassword" value={this.state.cpassword}
                                                                        onChange={this.onChangehandler} />
                                                                        <div className="text-danger">{this.state.errMsgCpassword}</div>
                                                                </div>


                                                                <div class="col-md-12 pt-xl-4">
                                                                    <button type="submit" class="pay-btn">Save {this.state.isLoading ? (
                                                                        <span
                                                                            className="spinner-border spinner-border-sm ml-5"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        ></span>
                                                                    ) : (
                                                                            <span></span>
                                                                        )}</button>

                                                                </div>

                                                            </div>
                                                        </form>
                                                    </div>

                                                </div>
                                            </Tab>


                                        </Tabs>


                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>


            </section>

        )

    }
}

export default LabSetting;