import React, { Component } from 'react';
import LEFTNAV from "./includes/leftnav";
import { Button, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import axios from "axios";


import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import NumberFormat from 'react-number-format';



class Payment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            payments: [],
        };
    }


    getPaymentList(id) {
        console.log("Get doctor Id :", id);

        axios.get(process.env.REACT_APP_API_URL + 'doctor/getPaymentHistory/' + id)
            .then((response) => {
                console.log("--------Payment list response ----------", response);
                if (response.data.status == true) {
                    this.setState({
                        payments: response.data.result
                    });
                } else {
                    console.log("Failed Api request");
                }
            })
            .catch((error) => {
                console.log("Payment  catch List");
            });
    }


    componentDidMount() {

        var LogedInUserObject = JSON.parse(localStorage.getItem("userData"));
        if (LogedInUserObject) {
            var doctorDetailId = LogedInUserObject.doctordetailId;
            this.getPaymentList(doctorDetailId);
        }

    }




    render() {


        // Redirect User if not login
        const login = localStorage.getItem("isLoggedIn");
        if (!login) {
            return <Redirect to="/" />;
        }

        const columns = [

            {
                name: "Consultation Id",
                selector: "appointmentNo",
                sortable: true,
                width: "180px",
            },
            {
                name: "Receipt No.",
                selector: "invoiceId",
            },
            {
                name: "Date of Payment",
                selector: "transectionDate",
                sortable: true,

            },
            {
                name: "Patient",
                selector: "first_name",
                cell: row => <span> <span className="patientName">
                    <b className="captilize">{row.first_name} {row.last_name}</b>
                </span>
                </span>,
                sortable: true
            },

            {
                name: "Payment Mode",
                selector: "type",
                sortable: true
            },
            {
                name: "Amount (INR)",
                selector: "amount",
                sortable: true,
                cell: row => <span> 
                    <NumberFormat value={row.amount} displayType={'text'} thousandSeparator={true} prefix={' '} />
                </span>,

            }

        ];


        const data = this.state.payments;
        const tableData = { columns, data };

        return (
            <section className="doctor-consultation-view  doctor-main-section">
                <div className="container-fulid">
                    <div>

                        <LEFTNAV />
                        <div className="content-wrapper">
                            <div className="patient-appointment-list-right-col">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h2><span className="calendar-teg"><i className="spt-img calendar-icon"></i></span> Payments</h2>
                                        {/* <form className="appointments-list-search">
                                            <input type="text" name="appointments-list-search" className="form-control" placeholder="Search" />
                                            <button type="submit" className="ap-list-search"><i className="spt-img search-icon"></i></button>
                                        </form> */}
                                    </div>

                                </div>
                                <div className="list-table">
                                    <div className="table-responsive">
                                    

                                    {this.state.payments.length > 0 ? (<DataTableExtensions {...tableData}>
                                        <DataTable
                                            title="Payment"
                                            columns={columns}
                                            data={data}
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            pagination
                                            paginationPerPage={10}
                                            className="table"
                                            responsive
                                        />
                                    </DataTableExtensions>) : (<div className="table">
                                            <div className="datanotAvailable"> There are no records to display </div>
                                        </div>)}


                                    

                                        {/* <table className="table">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th>Consultation Id</th>
                                                    <th>Receipt No.</th>
                                                    <th>Date of Payment</th>
                                                    <th>Patient</th>
                                                    <th>Payment Mode</th>
                                                    <th>Amount</th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.payments.map((payment, index) => (
                                                    <tr>
                                                        <td>{payment.appointmentNo}</td>
                                                        <td>{payment.invoiceId}</td>
                                                        <td>{payment.transectionDate}</td>
                                                        <td className="captilize">{payment.first_name} {payment.last_name} </td>
                                                        <td>{payment.type}</td>
                                                        <td> {payment.amount}</td>
                                                        
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>




        )

    }
}

export default Payment;