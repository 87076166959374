import React, { Component } from 'react';
import LEFTNAV from "./includes/leftnav";
import { Link } from "react-router-dom";
import { Modal, Tab, Tabs } from 'react-bootstrap';
import JOINCALL from './joinCall';
import { Redirect } from "react-router-dom";
import NumberFormat from 'react-number-format';




import axios from "axios";
class DoctorConsultaion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            showHide: false,
            doctorAppointments: [],



            modelAppointmentId: '',
            modelAppointmentDoctorDetailId: '',
            modelAppointmentMemberContactId: '',
            modelAppointmentId: '',
            modelAppointmentNo: '',
            modelPatientName: '',
            modelPatientPic: '',
            modelGender: '',
            modelAge: '',
            modelSymptoms: '',
            modelDocuments: '',
            modelDate: '',
            modelTime: '',
            modelConsultType: '',
            modelStatus: '',


            consultationHistory: [],

            dose: '',
            frequency: '',
            no_of_days: '',
            errDose: '',
            errFrequency: '',
            errNoofDays: '',
            status: false,
            successMsg: '',
            failedMessage: '',

            prescriptions: [],

            labs: [],
            test: '',
            labId: '',

            investigations: [],
            errorMsg: '',

            labReferral: [],
            pharmacyReferral: [],
            labReferralSummary: '',
            pharmacyReferralSummary: '',

            errorMsgLab: '',
            errorMsgPharmacy: '',
            paymentDetails: '',
            paymentCounter: '',








        };
    }



    getLabsList() {
        axios.get(process.env.REACT_APP_API_URL + 'getLabsList')
            .then((response) => {
                console.log("--------Lab list response --------", response);
                if (response.data.status == true) {
                    this.setState({
                        labs: response.data.result
                    });
                } else {
                    console.log("Failed Api request");
                }
            })
            .catch((error) => {
                console.log("Labs List catch error");
            });
    }



    getAppointmentDetail(id) {

        axios.get(process.env.REACT_APP_API_URL + 'doctor/getAppointmentDetails/' + id)
            .then((response) => {

                console.log("get single appointment :", response);
                if (response.data.status === true) {

                    this.setState({
                        modelAppointmentId: response.data.result[0].appointmentId,
                        modelAppointmentDoctorDetailId: response.data.result[0].doctordetail_id,
                        modelAppointmentMemberContactId: response.data.result[0].membercontact_id,
                        modelAppointmentNo: response.data.result[0].appointmentNo,
                        modelPatientName: response.data.result[0].first_name + ' ' + response.data.result[0].last_name,
                        modelPatientPic: response.data.result[0].profile_pic,
                        modelGender: response.data.result[0].gender,
                        modelAge: response.data.result[0].age,
                        modelSymptoms: response.data.result[0].symptoms,
                        modelDocuments: response.data.result[0].documents,
                        modelDate: response.data.result[0].appointmentDate,
                        modelTime: response.data.result[0].appointment_time,
                        modelConsultType: response.data.result[0].consult_type,
                        modelStatus: response.data.result[0].appointmentStatus,
                    })



                    this.getConsultationHistory(response.data.result[0].doctordetail_id, response.data.result[0].membercontact_id);

                    // Set Appointment Id In Cookie
                    // Cookies.set('channel', response.data.result[0].appointmentNo);


                    // Get Consultation History 


                } else {
                    console.log("response failed for single object");
                }

            }).catch((error) => {

                console.log("catch error : ", error);

            });
    }

    getConsultationHistory(doctorDetailId, MemberContactId) {


        var data = {
            "doctordetailId": doctorDetailId,
            "membercontactId": MemberContactId
        }


        axios.post(process.env.REACT_APP_API_URL + "doctor/getConsultationHistory", data).then((response) => {
            console.log("Response of consultation ", response);
            if (response.data.status === true) {
                console.log(" consultation history Success :", response);




                this.setState({
                    consultationHistory: response.data.result,
                });
            }
            else {
            }

        }).catch((error) => {

            console.log('prescription catch error');
            console.log(error);

        });


    }

    getPrescription(id) {

        axios.get(process.env.REACT_APP_API_URL + 'doctor/getPriscriptionList/' + id)
            .then((response) => {

                console.log("get prescription :", response);
                if (response.data.status === true) {

                    this.setState({
                        prescriptions: response.data.result
                    })
                }
                else {
                    console.log("response failed for prescription");
                }

            }).catch((error) => {

                console.log("catch error : ", error);

            });
    }


    getInvestigation(id) {

        axios.get(process.env.REACT_APP_API_URL + 'doctor/getInvestigationList/' + id)
            .then((response) => {
                console.log("GET INVETIGATION : ", response.data.result);

                if (response.data.status === true) {
                    this.setState({
                        investigations: response.data.result
                    })
                }
                else {
                    console.log("response failed for Investigation");
                }

            }).catch((error) => {
                console.log("catch error : ", error);
            });
    }



    componentDidMount() {
        var LogedInUserObject = JSON.parse(localStorage.getItem("userData"));
        if (LogedInUserObject) {

            // console.log("get URL ", this.props);

            

            var doctorDetailId = LogedInUserObject.doctordetailId;

            this.getLabsList();

            var id = this.props.match.params.id;

            localStorage.setItem("appointmentId", id);
            this.viewConsultation(id);
        }
    }

    viewConsultation(id) {


        this.getAppointmentDetail(id);
        this.getPrescription(id);
        this.getInvestigation(id);
        this.getLabReferral(id);
        this.getPharmacyReferral(id);
        this.getPaymentDetail(id);
    }


    onChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onSubmitHandler = (event) => {

        event.preventDefault();
        this.setState({
            isLoading: true,
        })

        var data = {
            appointmentId: this.state.modelAppointmentId,
            dose: this.state.dose,
            frequency: this.state.frequency,
            no_of_days: this.state.no_of_days
        }

        this.setState({
            isLoading: true,
        })


        // Pass token in the header
        let auth_token = localStorage.getItem('token');
        let header_token = { 'x-access-token': auth_token };

        // Add Prescription
        axios.post(process.env.REACT_APP_API_URL + "doctor/addPrescription", data,
            { headers: header_token }).then((response) => {
                console.log("Response of prescription ", response);
                if (response.data.status === true) {
                    console.log("Success :", response);
                    this.setState({
                        successMsg: response.data.message,
                        status: true,
                        isLoading: false,

                        dose: '',
                        frequency: '',
                        no_of_days: '',
                        errDose: "",
                        errFrequency: "",
                        errNoofDays: "",

                    });


                    this.getPrescription(this.state.modelAppointmentId);


                }
                else {

                    this.setState({
                        errDose: response.data.errors.dose,
                        errFrequency: response.data.errors.frequency,
                        errNoofDays: response.data.errors.no_of_days,
                        isLoading: false,
                    });
                }

                setTimeout(() => {
                    this.setState({
                        successMsg: "",
                        errDose: "",
                        errFrequency: "",
                        errNoofDays: "",
                    });

                    this.componentDidMount();

                }, 5000);



            }).catch((error) => {

                console.log('prescription catch error');
                console.log(error);

            });




    }




    onSubmitHandler2 = (event) => {
        event.preventDefault();

        var data = {

            appointmentId: this.state.modelAppointmentId,
            test: this.state.test,
            labId: this.state.labId
        }

        this.setState({
            isLoading: true,
        })

        // Pass token in the header
        let auth_token = localStorage.getItem('token');
        let header_token = { 'x-access-token': auth_token };

        // Add Investigation
        axios.post(process.env.REACT_APP_API_URL + "doctor/addInvestigation", data,
            { headers: header_token }).then((response) => {
                console.log("Response of Investigation", response);
                if (response.data.status === true) {
                    console.log("Success :", response);
                    this.setState({
                        successMsg: response.data.message,
                        status: true,
                        test: '',
                        isLoading: false,
                    });

                    //for reload the Api data
                    this.getInvestigation(this.state.modelAppointmentId);
                }
                else {

                    this.setState({
                        errorMsg: response.data.errors,
                        isLoading: false,
                    })

                }

                setTimeout(() => {
                    this.setState({
                        successMsg: "",
                        status: false,
                        errorMsg: ''
                    });
                    this.componentDidMount();
                }, 5000);

            }).catch((error) => {
                console.log('Investigation catch error');
                console.log(error);

            });

    }



    getPharmacyReferral(id) {

        axios.get(process.env.REACT_APP_API_URL + 'doctor/getPharmacyReferralList/' + id)
            .then((response) => {
                console.log("Get Pharmacy referral ", response);

                if (response.data.status === true) {
                    this.setState({
                        pharmacyReferral: response.data.result
                    })
                }
                else {
                    console.log("response failed for pharmacy referral ");
                }

            }).catch((error) => {
                console.log("catch error : ", error);
            });
    }
    onSubmitPharmacyReferral = (event) => {
        event.preventDefault();


        var body = {
            appointmentId: this.state.modelAppointmentId,
            summary: this.state.pharmacyReferralSummary,
            doctordetailId: this.state.modelAppointmentDoctorDetailId,
            membercontactId: this.state.modelAppointmentMemberContactId,
        }
        this.setState({
            isLoading: true,
        })

        // Pass token in the header
        let auth_token = localStorage.getItem('token');
        let header_token = { 'x-access-token': auth_token };

        axios.post(process.env.REACT_APP_API_URL + "doctor/pharmacyReferral", body,
            { headers: header_token }).then((response) => {
                console.log("Pharmacy referral Response", response);
                if (response.data.status === true) {
                    console.log("Success :", response);
                    this.setState({
                        successMsg: response.data.message,
                        status: true,
                        pharmacyReferralSummary: '',
                        errorMsgPharmacy: '',
                        isLoading: false,
                    });

                }
                else {
                    this.setState({
                        errorMsgPharmacy: response.data.errors.summary,
                        isLoading: false,
                    })
                }
                setTimeout(() => {
                    this.setState({
                        successMsg: "",
                        status: false,
                        errorMsgPharmacy: ''
                    });
                    this.componentDidMount();
                }, 5000);

            }).catch((error) => {
                console.log("Catch Error :", error);
            });
    }

    getLabReferral(id) {



        axios.get(process.env.REACT_APP_API_URL + 'doctor/getLabReferralList/' + id).then((response) => {
            console.log("Get lab referral ", response);

            if (response.data.status === true) {
                this.setState({
                    labReferral: response.data.result
                })
            }
            else {
                console.log("response failed for lab referral ");
            }

        }).catch((error) => {
            console.log("catch error : ", error);
        });
    }
    onSubmitLabReferral = (event) => {
        event.preventDefault();

        this.setState({
            isLoading: true,
        })
        var body = {
            appointmentId: this.state.modelAppointmentId,
            summary: this.state.labReferralSummary,
            doctordetailId: this.state.modelAppointmentDoctorDetailId,
            membercontactId: this.state.modelAppointmentMemberContactId,

        }

        // console.log(body);


        // Lab Referral 

        // Pass token in the header
        let auth_token = localStorage.getItem('token');
        let header_token = { 'x-access-token': auth_token };

        axios.post(process.env.REACT_APP_API_URL + "doctor/labreferral", body, { headers: header_token })
            .then((response) => {
                console.log("Lab referral Response", response);
                if (response.data.status === true) {
                    console.log("Lab data Success :", response);
                    this.setState({
                        isLoading: false,
                        status: true,
                        successMsg: response.data.message,
                        // failedMessage: response.data.message,
                        labReferralSummary: '',
                        status: true,
                        errorMsgLab: '',
                    });
                    // this.getReferral(this.state.modelAppointmentId);
                }
                else {
                    this.setState({
                        isLoading: false,
                        status: false,
                        errorMsgLab: response.data.errors.summary,
                        // failedMessage: response.data.message

                    })
                }
                setTimeout(() => {
                    this.setState({
                        successMsg: "",
                        status: false,
                        errorMsgLab: '',
                        // failedMessage: '',

                    });
                    this.componentDidMount();
                }, 5000);

            }).catch((error) => {
                console.log("Catch Error :", error);
            });
    }


    getPaymentDetail(id) {

        axios.get(process.env.REACT_APP_API_URL + 'doctor/getPaymentDetail/' + id)
            .then((response) => {
                console.log("Get Payment details ", response);

                if (response.data.status === true) {
                    this.setState({
                        paymentCounter: response.data.counter,
                        paymentDetails: response.data.result[0]
                    })
                }
                else {
                    console.log("response failed for payment ");
                }

            }).catch((error) => {
                console.log("catch error : ", error);
            });
    }








    completeConsultation(id) {

        var data = {
            "id": id,
            "status": 5
        }

        // Pass token in the header
        let auth_token = localStorage.getItem('token');
        let header_token = { 'x-access-token': auth_token };


        axios.post(process.env.REACT_APP_API_URL + "doctor/changeAppointmentStatus",
            data, { headers: header_token }).then((response) => {
                console.log(" Complete consultation  ", response);
                if (response.data.status === true) {
                    console.log("Success :", response);
                    this.setState({
                        status: true,
                    });
                }
                else {
                }

                this.componentDidMount();

            }).catch((error) => {

                console.log('complete consultation catch error');
                console.log(error);

            });

    }

    handleModalShowHide(id) {


        this.setState({
            showHide: !this.state.showHide,
        })

        this.completeConsultation(id);
        setTimeout(() => {
            this.setState({
                showHide: false,
            })
        }, 10000)
    }


   

    handleDelete(id,tab) {

        let auth_token = localStorage.getItem('token');
        let header_token = { 'x-access-token': auth_token };
        let url;
        if(tab=='prescription'){
           url = "doctor/deletePrescription";
        }else if(tab=='investigation'){
           url = "doctor/deleteInvestigation";
        }

        axios.post(process.env.REACT_APP_API_URL + url, {
            id: id,
        }, { headers: header_token })
            .then((response) => {

                console.log(response);
                if (response.data.status) {
                    console.log("Prescription deleted ");
                    this.getPrescription(this.props.match.params.id);
                    this.getInvestigation(this.props.match.params.id);
                }
            })
            .catch((error) => {
                console.log('Error in prescription delete', error);
            });
    }

    






    render() {

        const errorMsg = {
            "text-align": "center"
        }



        console.log(this.state.modelPatientName);

        // const randonNumber = Math.floor((Math.random() * 999999999999999) + 1000);

        return (
            <section className="doctor-consultation-view  doctor-main-section">
                <div className="container-fulid">
                    <div>
                        <LEFTNAV />
                        <div className="content-wrapper">
                            <div className="patient-appointment-list-right-col consultation-view-right">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h2><span className="calendar-teg"><i className="spt-img calendar-icon"></i></span> Consultation # {this.state.modelAppointmentNo}   </h2>
                                    </div>
                                    {/* <div className="col-sm-4">
                                            <ul className="sonsultation-view-status">
                                                <li><span className="spt-img online-icon"></span>Online</li>
                                                <li><span className="spt-img new-icon"></span>In Progress</li>
                                            </ul>
                                        </div> */}
                                    <div className="col-sm-6 text-right">

                                        <Link to="/doctor/consultation">
                                            <button type="button" className="cancel-appointment-btn complete-btn">Back </button>
                                        </Link>


                                        {this.state.modelStatus == 5 || this.state.modelConsultType == 2 ? ('') : (
                                            <React.Fragment>
                                                <JOINCALL channel={this.state.modelAppointmentNo} />
                                            </React.Fragment>)}

                                        {this.state.modelStatus == 5 ? ('') : (
                                            <React.Fragment>
                                                <button type="button" onClick={() => this.handleModalShowHide(this.state.modelAppointmentId)} className="cancel-appointment-btn">Complete Consultation</button>
                                            </React.Fragment>)}




                                        {/* <Link to={`/videocall/${this.state.modelAppointmentNo}`}>
                                                <button type="button" className="cancel-appointment-btn">Join call</button>
                                            </Link> */}


                                        {/* <button type="button" 
                                        onClick={() => this.completeConsultation(this.state.modelAppointmentId)} 
                                        className="cancel-appointment-btn">Complete Consultation</button> */}




                                        <Modal className="complete-consultation" show={this.state.showHide}>
                                            <Modal.Body className="alert-success">
                                                <div> Consultation Complete successfully </div>
                                            </Modal.Body>
                                        </Modal>




                                    </div>
                                </div>
                                <section className="doctor-consultation-view">
                                    <div className="doctor-consultation-view">

                                        <Tabs defaultActiveKey="detail" transition={false} className="nav-item">

                                            <Tab eventKey="detail" title=" Summary ">

                                                <div id="details" className="tab-pane active">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="dashboard-user">
                                                                <div className="dashboard-user-col">
                                                                    <div className="mamber-pro">
                                                                        <span className="member-img">
                                                                            {this.state.modelPatientPic == null ?
                                                                                (<img alt="image-not-found" src={process.env.REACT_APP_BASE_URL + process.env.REACT_APP_IMAGE_ICON} />
                                                                                )
                                                                                :
                                                                                (
                                                                                    <img alt="member-img" src={process.env.REACT_APP_IMAGE_LOCATION + "patient/profile_picture/" + this.state.modelPatientPic} />
                                                                                )
                                                                            }</span>
                                                                        <span className="mamber-info">
                                                                            <h3 className="captilize">{this.state.modelPatientName}</h3>
                                                                            <p><span className="age">{this.state.modelAge} yrs</span> | <span className="jendeer">{this.state.modelGender}</span></p>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="doc-detail">
                                                                <h3>Symptoms</h3>
                                                                <p>{this.state.modelSymptoms} </p>
                                                                <h3>Documents</h3>


                                                                {this.state.modelDocuments == null ?
                                                                    (<p>Patient has not uploaded any documents for this appointment.</p>) : (this.state.modelDocuments)}
                                                                <h3>Previous Consultation</h3>
                                                                <div className="table-responsive previous-appointment-list">
                                                                    <table className="table">
                                                                        <thead className="thead-dark">
                                                                            <tr>
                                                                                <th> Sr. No. </th>
                                                                                <th>Consultation No.</th>
                                                                                <th>Date</th>
                                                                                <th>Time</th>
                                                                                <th className="symptoms">Symptoms</th>

                                                                                <th>Consultation Type</th>
                                                                                <th>Status</th>
                                                                                <th>Detail</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>


                                                                            {this.state.consultationHistory.length == 0 ? (<tr> <td colSpan='7' style={errorMsg}> There are no records to display </td></tr>) : ('')}

                                                                            {this.state.consultationHistory.map((list, index) => (
                                                                                <tr>
                                                                                    <td>{index + 1} </td>
                                                                                    <td>{list.appointmentNo} </td>
                                                                                    <td>{list.appointmentDate} </td>
                                                                                    <td>{list.appointment_time} </td>
                                                                                    <td>{list.symptoms}</td>
                                                                                    <td>{list.consult_type == 1 ? 'Online' : 'In-Clinic'}</td>

                                                                                    {list.status == 2 ? (<td className="confirm">CONFIRM</td>) : (<td className="cancelled">CANCELLED</td>)}


                                                                                    <td>
                                                                                        <Link to={`/doctor/consultationDetail/${list.id}/summary`}> View Detail</Link>

                                                                                    </td>

                                                                                </tr>
                                                                            ))}

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>


                                            <Tab eventKey="Investigation" title=" Investigation ">

                                                <div className="profile_deatils pt-xl-5">
                                                    <form onSubmit={this.onSubmitHandler2}>


                                                        {this.state.successMsg ? (
                                                            <div className="alert alert-success">
                                                                {this.state.successMsg}
                                                            </div>
                                                        ) : ('')
                                                        }
                                                        <div className="row">






                                                            <div className="col-sm-6 form-group">
                                                                <label for=""> Select Lab</label>
                                                                <select className="form-control" name="labId" onChange={this.onChangeHandler}>

                                                                    {this.state.labs.map((lab, index) => (
                                                                        <option value={lab.id}> {lab.name}</option>
                                                                    ))}
                                                                </select>

                                                                <div className="text-danger">
                                                                    {this.state.errorMsg.lab}
                                                                </div>

                                                            </div>

                                                            <div className="col-sm-6 form-group">
                                                                <label for=""> Description (Max allowed 200 characters)</label>
                                                                <input type="text" className="form-control" name="test" placeholder="" value={this.state.test} onChange={this.onChangeHandler} maxLength="200" />
                                                                <div className="text-danger">
                                                                    {this.state.errorMsg.test}
                                                                </div>
                                                            </div>








                                                            <div className="col-md-12 pt-xl-4">
                                                                <button type="submit" className="pay-btn">Save  {this.state.isLoading ? (
                                                                    <span
                                                                        className="spinner-border spinner-border-sm ml-5"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                ) : (
                                                                        <span></span>
                                                                    )}</button>
                                                            </div>


                                                        </div>
                                                    </form>

                                                    <div className="table-responsive previous-appointment-list">
                                                        <table className="table">
                                                            <thead className="thead-dark">
                                                                <tr>
                                                                    <th>Sr. No.</th>
                                                                    <th>Consultation No.</th>
                                                                    <th>Date</th>
                                                                    <th>Time</th>
                                                                    <th>Consultation Type</th>
                                                                    <th>Symptoms</th>
                                                                    <th>Test</th>
                                                                    <th>Action</th>
                                                                    {/* <th>Status</th> */}

                                                                </tr>

                                                            </thead>
                                                            <tbody>

                                                                {this.state.investigations.length == 0 ? (<tr> <td colSpan='7' style={errorMsg}> There are no records to display </td></tr>) : ('')}

                                                                {this.state.investigations.map((investigation, index) => (
                                                                    <tr>
                                                                        <td>{index + 1}</td>
                                                                        <td>{investigation.appointmentNo}</td>
                                                                        <td>{investigation.appointmentDate}</td>
                                                                        <td>{investigation.appointment_time}</td>
                                                                        <td>{investigation.consult_type == 1 ? ('Online') : ('Offline')}</td>
                                                                        <td>{investigation.symptoms}</td>
                                                                        <td>{investigation.advise_test}</td>
                                                                        <td>
                                                                            <button type="button" class="btn btn-danger btn-xs" 
                                                                             disabled={this.state.modelStatus==5} onClick={() => {if(window.confirm('Are you sure to delete this record?')){ this.handleDelete(investigation.investigationId,'investigation')}}}
                                                                            >Delete </button>
                                                                        </td>


                                                                        {/* {investigation.status == 1 ? (<td className="cancelled">Pending</td>) : (<td className="confirm">complete</td>)} */}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </Tab>

                                            <Tab eventKey="Prescriptions" title=" Prescriptions ">
                                                <div className="profile_deatils pt-xl-5">
                                                    <form onSubmit={this.onSubmitHandler}>

                                                        {this.state.successMsg ? (
                                                            <div className="alert alert-success">
                                                                {this.state.successMsg}
                                                            </div>
                                                        ) : ('')
                                                        }
                                                        <div className="row">



                                                            <div className="col-sm-6 form-group">
                                                                <label for="">Dosage (Max allowed 200 characters)*</label>
                                                                <input type="text" className="form-control" name="dose" placeholder="" value={this.state.dose} onChange={this.onChangeHandler} maxLength="200" />
                                                                <div className="text-danger">
                                                                    {this.state.errDose}
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-6 form-group">
                                                                <label for="">No. of times in a day (Max allowed 100 characters)*</label>
                                                                <input type="text" className="form-control" name="frequency" placeholder="" value={this.state.frequency} onChange={this.onChangeHandler} maxLength="100" />
                                                                <div className="text-danger">{this.state.errFrequency}</div>
                                                            </div>

                                                            <div className="col-sm-6 form-group">
                                                                <label for="">No. of days (Max allowed 100 characters)*</label>
                                                                <input type="text" className="form-control" name="no_of_days" placeholder="" value={this.state.no_of_days} onChange={this.onChangeHandler} maxLength="100" />
                                                                <div className="text-danger">
                                                                    {this.state.errNoofDays}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12 pt-xl-4">
                                                                <button type="submit" className="pay-btn">Save  {this.state.isLoading ? (
                                                                    <span
                                                                        className="spinner-border spinner-border-sm ml-5"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                ) : (
                                                                        <span></span>
                                                                    )} </button>
                                                            </div>


                                                        </div>
                                                    </form>

                                                    <div className="table-responsive previous-appointment-list">
                                                        <table className="table">
                                                            <thead className="thead-dark">
                                                                <tr>
                                                                    <th>Sr. No.</th>
                                                                    <th>Consultation No.</th>
                                                                    <th>Date</th>
                                                                    <th>Time</th>
                                                                    <th>Dosage</th>
                                                                    <th>No. of times in a day</th>
                                                                    <th>No. of days</th>
                                                                    <th>Action</th>
                                                                </tr>

                                                            </thead>
                                                            <tbody>

                                                                {this.state.prescriptions.length == 0 ? (<tr> <td colSpan='7' style={errorMsg}> There are no records to display </td></tr>) : ('')}
                                                                {this.state.prescriptions.map((prescription, index) => (
                                                                    <tr>
                                                                        <td>{index + 1}</td>
                                                                        <td>{prescription.appointmentNo}</td>
                                                                        <td>{prescription.appointmentDate} </td>
                                                                        <td>{prescription.appointment_time} </td>
                                                                        <td> {prescription.dose}</td>
                                                                        <td> {prescription.no_of_time_in_day}</td>
                                                                        <td> {prescription.no_of_days}</td>
                                                                        <td>
                                                                            <button type="button" class="btn btn-danger btn-xs" 
                                                                             disabled={this.state.modelStatus==5} onClick={() => {if(window.confirm('Are you sure to delete this record?')){ this.handleDelete(prescription.prescriptionId,'prescription')}}}
                                                                            >Delete </button>
                                                                        </td>

                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>


                                            </Tab>





                                            <Tab eventKey="Referral" title=" Lab Referral ">

                                                <div className="profile_deatils pt-xl-5">

                                                    <form onSubmit={this.onSubmitLabReferral}>


                                                        {this.state.successMsg ? (
                                                            <div className="alert alert-success">
                                                                {this.state.successMsg}
                                                            </div>
                                                        ) : ('')
                                                        }




                                                        <div className="row">



                                                            <div className="col-sm-6 form-group">
                                                                <label for=""> Referral Message (Max allowed 200 characters) *</label>
                                                                <input type="text" className="form-control" name="labReferralSummary" value={this.state.labReferralSummary} onChange={this.onChangeHandler} maxLength="200" />
                                                                <div className="text-danger">
                                                                    {this.state.errorMsgLab}
                                                                </div>
                                                            </div>



                                                            <div className="col-md-12 pt-xl-4">
                                                                <button type="submit" className="pay-btn" >Save   {this.state.isLoading ? (
                                                                    <span
                                                                        className="spinner-border spinner-border-sm ml-5"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                ) : (
                                                                        <span></span>
                                                                    )} </button>
                                                            </div>


                                                        </div>
                                                    </form>

                                                    <div className="table-responsive previous-appointment-list">
                                                        <table className="table">
                                                            <thead className="thead-dark">
                                                                <tr>
                                                                    <th>Sr. No.</th>
                                                                    <th>Lab name</th>
                                                                    <th width="400">Summary</th>
                                                                    <th>Refer Date</th>
                                                                </tr>

                                                            </thead>
                                                            <tbody>


                                                                {/* {this.state.labReferral.length == 0 ? (<tr><td colSpan="4" className="recordnotfound">Referred not found</td></tr>) : (
                                                                    '')} */}



                                                                {this.state.labReferral.length == 0 ? (<tr> <td colSpan='4' style={errorMsg}> There are no records to display </td></tr>) : ('')}
                                                                {this.state.labReferral.map((referral, index) => (<tr><td>{index + 1}</td>
                                                                    <td>{referral.name}</td>
                                                                    <td>{referral.summary}</td>
                                                                    <td>{referral.referralDate}</td></tr>)

                                                                )}



                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </Tab>


                                            <Tab eventKey="Pharmacy" title=" Pharmacy Referral ">

                                                <div className="profile_deatils pt-xl-5">
                                                    <form onSubmit={this.onSubmitPharmacyReferral}>


                                                        {this.state.successMsg ? (
                                                            <div className="alert alert-success">
                                                                {this.state.successMsg}
                                                            </div>
                                                        ) : ('')
                                                        }
                                                        <div className="row">



                                                            <div className="col-sm-6 form-group">
                                                                <label for=""> Referral Message (Max allowed 200 characters) * </label>
                                                                <input type="text" className="form-control" name="pharmacyReferralSummary" value={this.state.pharmacyReferralSummary} onChange={this.onChangeHandler} maxLength="200" />
                                                                <div className="text-danger">
                                                                    {this.state.errorMsgPharmacy}
                                                                </div>
                                                            </div>


                                                            <div className="col-md-12 pt-xl-4">
                                                                <button type="submit" className="pay-btn">Save  {this.state.isLoading ? (
                                                                    <span
                                                                        className="spinner-border spinner-border-sm ml-5"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                ) : (
                                                                        <span></span>
                                                                    )} </button>
                                                            </div>


                                                        </div>
                                                    </form>

                                                    <div className="table-responsive previous-appointment-list">
                                                        <table className="table">
                                                            <thead className="thead-dark">
                                                                <tr>
                                                                    <th>Sr. No.</th>
                                                                    <th>Pharmacy name</th>
                                                                    <th width="400">Summary</th>
                                                                    <th>Refer Date</th>
                                                                </tr>


                                                            </thead>
                                                            <tbody>
                                                                {/* {this.state.pharmacyReferral.length == 0 ? (<tr><td colSpan="8" className="recordnotfound">Referred not found</td></tr>) : ('')} */}

                                                                {this.state.pharmacyReferral.length == 0 ? (<tr> <td colSpan='4' style={errorMsg}> There are no records to display </td></tr>) : ('')}

                                                                {this.state.pharmacyReferral.map((referral, index) => (<tr><td>{index + 1}</td>
                                                                    <td>{referral.name}</td>
                                                                    <td>{referral.summary}</td>
                                                                    <td>{referral.referralDate}</td></tr>)

                                                                )}



                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </Tab>


                                            <Tab eventKey="Payments" title=" Payments ">

                                                <div className="profile_deatils pt-xl-5">


                                                    <div className="table-responsive previous-appointment-list">
                                                        <table className="table">
                                                            <thead className="thead-dark">
                                                                <tr>
                                                                    <th>Consultation No.</th>
                                                                    <th>Receipt No.</th>
                                                                    <th> Date of Payment</th>
                                                                    <th>Patient</th>
                                                                    <th>Payment Mode</th>
                                                                    <th>Amount (INR)</th>

                                                                </tr>

                                                            </thead>
                                                            <tbody>

                                                                {this.state.paymentCounter > 0 ? (<tr>
                                                                    <td>{this.state.paymentDetails.appointmentNo}</td>
                                                                    <td>{this.state.paymentDetails.invoiceId}</td>
                                                                    <td>{this.state.paymentDetails.transectionDate}</td>
                                                                    <td className="captilize">{this.state.paymentDetails.first_name} {this.state.paymentDetails.last_name} </td>
                                                                    <td>{this.state.paymentDetails.type}</td>
                                                                    <td>
                                                                        <NumberFormat value={this.state.paymentDetails.amount} displayType={'text'} thousandSeparator={true} prefix={' '} />
                                                                    </td>

                                                                </tr>) : ('')}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </Tab>

                                        </Tabs>



                                    </div>
                                </section>
                            </div>
                        </div>




                    </div>
                </div>
            </section>

        )

    }
}

export default DoctorConsultaion;