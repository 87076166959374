import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import axios from "axios";


class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showHide: false,
            message: '',
            status: false,
            isLoading: false,
            

            password: '',
            cpassword: '',
            errMsgPassword: "",
            errMsgCpassword: "",

        };
    }

    handleModalShowHide() {
        this.setState({
            showHide: !this.state.showHide,
            errMsgPassword: "",
            errMsgCpassword: "",
            message: "",
        })
    }

    changePasswordSubmitHandler = (event) => {
        event.preventDefault();
        this.setState({ isLoading: true });
        this.changePassword();

    }

    changePassword() {

        // get member Id
        const user = JSON.parse(localStorage.getItem("userData"));
        const id = user.id;

        var body = {
            memberId: id,
            password: this.state.password,
            cpassword: this.state.cpassword,
        };

        // Pass token in the header
        let auth_token = localStorage.getItem('token');
        let header_token = { 'x-access-token': auth_token };

        axios.post(process.env.REACT_APP_API_URL + "changePassword", body,
            { headers: header_token }).then((response) => {

                console.log("---change password Response -----:", response);

                this.setState({ isLoading: false });

                if (response.data.status) {

                    this.setState({
                        message: response.data.message,
                        status: true,
                        password: '',
                        cpassword: '',
                        errMsgPassword: '',
                        errMsgCpassword: '',

                    });

                }
                else {
                    this.setState({
                        status: false,
                        errMsgPassword: response.data.errors.password,
                        errMsgCpassword: response.data.errors.cpassword,
                        message:'',
                    });
                }




            }).catch((error) => {

                console.log('----change password catch error-------');
                console.log(error);

            });
    }

    ChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    



render() {

    const status = this.state.status;
    const isLoading = this.state.isLoading;

    return (

        
        <React.Fragment>
           
            <div className="custom-file patientChangePassword">
                <button className="change-pass" type="button"
                    onClick={() => this.handleModalShowHide()}>Change Password</button>
            </div>
            
            <Modal className="add-family-member" show={this.state.showHide}>
                <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                    <h5 className="modal-title" id="exampleModalLabel">Change Password</h5>
                </Modal.Header>
                <Modal.Body>

                    {status ? (
                        <div>
                            <br />
                            <div className="alert alert-success">
                                {this.state.message}.
                                </div>
                        </div>
                    ) : (
                            <span></span>
                        )}

                    <div className="profile_deatils">
                        <form onSubmit={this.changePasswordSubmitHandler}>
                            <div className="row">
                                <div className="col-sm-6 form-group">
                                    <label>Password*</label>
                                    <input type="password" className="form-control" name="password" value={this.state.password} onChange={this.ChangeHandler} placeholder="Enter password" />
                                    <div className="text-danger">{this.state.errMsgPassword}</div>
                                </div>

                                <div className="col-sm-6 form-group">
                                    <label >Confirm Password*</label>
                                    <input type="password" className="form-control" name="cpassword" value={this.state.cpassword} onChange={this.ChangeHandler} placeholder="Enter confirm password"/>
                                    <div className="text-danger">{this.state.errMsgCpassword}</div>
                                </div>
                            </div>

                            <div className="row pt-xl-5 pb-xl-4 add-member-save-btn-box">
                                <button type="submit" className="pay-btn"> Save
                                        {isLoading ? (
                                        <span
                                            className="spinner-border spinner-border-sm ml-5"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                            <span></span>
                                        )}
                                </button>
                            </div>
                        </form>
                    </div>
                   

                </Modal.Body>
            </Modal>
            
        </React.Fragment>
    )

}
}

export default ChangePassword;