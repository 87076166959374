import React, { Component } from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import LEFTNAV from "./includes/leftnav";
import axios from "axios";
import ReactDOM from 'react-dom';
import { Multiselect } from 'multiselect-react-dropdown';
import Timing from './settings/slots';
import Setting from './settings/advanceSetting';
import ChangePassword from './settings/changePassword';




class DoctorSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            status: false,
            message: '',
            errorFileMsg: '',
            doctorDetail: [],

            name: '',
            gender: '',
            email: '',
            mobile: '',
            member_id: '',
            experience: '',
            fee: '',
            registration_no: '',
            about: '',
            educationDetail: '',
            specializations: '',
            languages: '',
            qualifications: '',
            fileOriginalName: '',

            selectedSpecializationValue: [],
            selectedQualificationValue: [],
            selectedLanguagesValue: [],


            selectedFile: "",
            URL: process.env.REACT_APP_IMAGE_LOCATION + "doctor/profile_picture/",
            profilePicture: null,
            specializationsOptions: [],
            languagesOptions: [],
            qualificationOptions: [],
            signature : '',

            errMsgName: '',
            errMsgExperience: '',
            errMsgRegNum: '',
            errMsgLang: '',
            errMsgGender: '',
            errMsgFee: '',
            errMsgAbout: '',
            errMsgEducationDetail: '',
            errMsgSpecializations: '',
            errMsgLanguages: '',
            errMsgQualifications: '',
        };
    }




    handleChangeLanguage = (selectedList, selectedItem) => {
        var selectedLang = "";
        for (var i = 0; i < selectedList.length; i++) {
            if (parseInt(i + 1) == parseInt(selectedList.length)) {
                selectedLang += selectedList[i].name;
            }
            else {
                selectedLang += selectedList[i].name + ", ";
            }
        }
        this.setState({ "languages": selectedLang });
    }

    handleChangeQualification = (selectedList, selectedItem) => {

        var selectedLang = "";
        for (var i = 0; i < selectedList.length; i++) {
            if (parseInt(i + 1) == parseInt(selectedList.length)) {
                selectedLang += selectedList[i].name;
            }
            else {
                selectedLang += selectedList[i].name + ", ";
            }
        }
        this.setState({ qualifications: selectedLang });
    }

    handleChangeSpecialization = (selectedList, selectedItem) => {

        var selectedSpec = "";
        for (var i = 0; i < selectedList.length; i++) {
            if (parseInt(i + 1) == parseInt(selectedList.length)) {
                selectedSpec += selectedList[i].name;
            }
            else {
                selectedSpec += selectedList[i].name + ", ";
            }
        }

        this.setState({ "specializations": selectedSpec });
    }

    // On file select (from the pop up) 
    onFileChange = event => {
        // Update the state 
        this.setState({ 
            selectedFile: event.target.files[0],
            fileOriginalName: event.target.files[0].name, 
        });


        var fileInput = document.getElementById('file');
        var filePath = fileInput.value;
        // Allowing file type 
        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

        if (!allowedExtensions.exec(filePath)) {

            this.setState({
                errorFileMsg: 'Invalid file type',
                selectedFile: '',
            })

            fileInput.value = '';
            return false;
        }
        else {

            this.setState({
                errorFileMsg: '',
            })
        }

    }

    onFileUpload = () => {

        // Create an object of formData 
        if (this.state.selectedFile != "") {
            this.setState({ isLoading: true });
            const formData = new FormData();
            const memberId = this.state.doctorDetail.member_id

            // Update the formData object 
            formData.append(
                "myFile",
                this.state.selectedFile,
                this.state.selectedFile.name
            );

            axios.post(process.env.REACT_APP_API_URL + "doctor/uploadProfilePic/" + memberId, formData).then((response) => {
                this.setState({ isLoading: true });

                if (response.data.success) {
                    this.setState({ profilePicture: response.data.result });
                    this.setState({ isLoading: false });
                }
                if (response.data.status === "failed" && response.data.success === undefined) {
                    console.log(response.data);
                }
            }).catch((error) => {
                console.log('enter catch error');
                console.log(error);
            });
        }
    }





    getLanguages() {
        axios.get(process.env.REACT_APP_API_URL + "getLanguagesList/",
            {
            }).then((response) => {
                this.setState({ isLoading: false });

                console.log("load language :", response.data.result);

                if (response.data.status) {
                    this.setState({ languagesOptions: response.data.result })
                }
                if (response.data.status === "failed" && response.data.success === undefined) {
                    console.log(response.data);
                }
            }).catch((error) => {
                console.log('enter catch error');
                console.log(error);
            });
    }

    getSpecializations() {
        axios.get(process.env.REACT_APP_API_URL + "getSpecializationList/").then((response) => {
            this.setState({ isLoading: false });

            if (response.data.status) {

                this.setState({
                    specializationsOptions: response.data.result
                })
            }
            if (response.data.status === "failed" && response.data.success === undefined) {
                console.log(response.data);
            }
        }).catch((error) => {
            console.log('enter catch error');
            console.log(error);
        });
    }


    getQualifications() {
        axios.get(process.env.REACT_APP_API_URL + "getQualificationsList/").then((response) => {
            this.setState({ isLoading: false });

            console.log("Get Qualifications -", response.data.result);

            if (response.data.status) {
                this.setState({
                    qualificationOptions: response.data.result
                })
            }
            if (response.data.status === "failed" && response.data.success === undefined) {
                console.log(response.data);
            }
        }).catch((error) => {
            console.log('enter catch error');
            console.log(error);
        });
    }



    stripHtml(html) {
        var temporalDivElement = document.createElement("div");
        temporalDivElement.innerHTML = html;
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }

    getDoctorInfo(memberId) {
        this.setState({ isLoading: true });
        axios.get(process.env.REACT_APP_API_URL + "doctor/getDoctorInfo/" + memberId)
            .then((response) => {
                this.setState({ isLoading: false });
                if (response.data.status) {
                    console.log("Get Doctor Details : ", response.data.result[0]);
                    this.setState(
                        {
                            doctorDetail: response.data.result[0],
                            name: response.data.result[0].name,
                            mobile: response.data.result[0].mobile,
                            gender: response.data.result[0].gender,
                            email: response.data.result[0].email,
                            experience: response.data.result[0].experience,
                            fee: response.data.result[0].fee,
                            registration_no: response.data.result[0].registration_no,
                            educationDetail: this.stripHtml(response.data.result[0].educationandcertificate),
                            about: this.stripHtml(response.data.result[0].description),
                            profilePicture: response.data.result[0].profile_pic,
                            advanceBooking: response.data.result[0].advanceBooking,
                            cancelation: response.data.result[0].cancelation,
                            signature: response.data.result[0].signature,

                        },
                    );


                    var specializationArray = response.data.result[0].specializations.split(',');
                    var newObject1 = {};
                    for (let i = 0; i < specializationArray.length; i++) {
                        newObject1[i] = { name: specializationArray[i] };
                    }
                    this.setState({

                        selectedSpecializationValue: newObject1,
                        // old value
                        specializations: response.data.result[0].specializations,
                    });

                    // convert to array
                    var qualificationArray = response.data.result[0].qualifications.split(',');
                    var newObject2 = {};
                    for (let i = 0; i < qualificationArray.length; i++) {
                        newObject2[i] = { name: qualificationArray[i] };
                    }
                    this.setState({
                        selectedQualificationValue: newObject2,
                        qualifications: response.data.result[0].qualifications,

                    });


                    var languageArray = response.data.result[0].languages.split(',');

                    console.log(languageArray);
                    var newObject3 = {};
                    for (let i = 0; i < languageArray.length; i++) {
                        newObject3[i] = { name: languageArray[i] };
                    }
                    this.setState({
                        selectedLanguagesValue: newObject3,
                        languages: response.data.result[0].languages
                    });





                }
            })
            .catch((error) => {
                console.log('enter catch error');
                console.log(error);
            });
    }




    onChangehandler = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }





    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ isLoading: true });

        const memberId = this.state.doctorDetail.member_id

        console.log(memberId);


        var data = {
            member_id: memberId,
            name: this.state.name,
            gender: this.state.gender,
            experience: this.state.experience,
            fee: this.state.fee,
            registration_no: this.state.registration_no,
            educationDetail: this.state.educationDetail,
            about: this.state.about,
            specializations: this.state.specializations,
            languages: this.state.languages,
            qualifications: this.state.qualifications,
        };

        console.log(data);



        // Pass token in the header
        let auth_token = localStorage.getItem('token');
        let header_token = { 'x-access-token': auth_token };

        axios.post(process.env.REACT_APP_API_URL + "doctor/updateProfile/", data, { headers: header_token })
            .then((response) => {

                console.log("Response of update profile :", response);

                this.setState({ isLoading: false });
                if (response.data.status) {

                    this.setState({
                        status: true,
                        message: response.data.message,
                        errMsgName: '',
                        errMsgExperience: '',
                        errMsgRegNum: '',
                        errMsgLang: '',
                        errMsgGender: '',
                        errMsgFee: '',
                        errMsgAbout: '',
                        errMsgEducationDetail: '',
                        errMsgSpecializations: '',
                        errMsgLanguages: '',
                        errMsgQualifications: '',
                    })

                }
                if (response.data.status === "failed" && response.data.success === undefined) {
                    this.setState({
                        status: false,
                        errMsgName: response.data.errors.first_name,
                        errMsgExperience: response.data.errors.experience,
                        errMsgRegNum: response.data.errors.registrationNumber,
                        errMsgLang: response.data.errors.languages,
                        errMsgGender: response.data.errors.gender,
                        errMsgFee: response.data.errors.fee,
                        errMsgAbout: response.data.errors.about,
                        errMsgEducationDetail: response.data.errors.educationDetail,
                        errMsgSpecializations: response.data.errors.specializations,
                        errMsgLanguages: response.data.errors.languages,
                        errMsgQualifications: response.data.errors.qualifications,
                    });
                    setTimeout(() => {
                        this.setState({
                            errMsgEmail: "",
                            errMsgPwd: ""
                        });
                    }, 5000);
                }

                setTimeout(() => {
                    this.setState({
                        status: false,

                    });
                }, 5000);


            })
            .catch((error) => {
                console.log('enter catch error on the update profile');
                console.log(error);
            })

    }






    componentDidMount() {
        const user = JSON.parse(localStorage.getItem("userData"));
        var memberId = user.memberId;

        this.getDoctorInfo(memberId);
        this.getSpecializations();
        this.getLanguages();
        this.getQualifications();
    }




    render() {


        return (
            <section className="doctor-consultation-view  doctor-main-section">
                <div className="container-fulid">
                    <div>

                        <LEFTNAV />
                        <div class="content-wrapper">
                            <div class="patient-appointment-list-right-col consultation-view-right">
                                <section class="doctor-consultation-view">
                                    <div class="doctor-consultation-view">
                                        <Tabs defaultActiveKey="profile" transition={false} id="doctorSetting" className="nav-item">
                                            <Tab eventKey="profile" title="Profile" class="nav-item">
                                                <div class="list-table">

                                                    <div class="Profilerow d-flex pt-xl-4">
                                                        <div class="profileavtar pr-5">


                                                            {this.state.profilePicture == null ?
                                                                (<img alt="image-not-found" src={process.env.REACT_APP_BASE_URL + 'img/picture-not-available.jpg'} />
                                                                )
                                                                :
                                                                (
                                                                    <img alt="member-img" src={process.env.REACT_APP_IMAGE_LOCATION + "doctor/profile_picture/" + this.state.profilePicture} />
                                                                )
                                                            }

                                                        </div>




                                                        <div class="profile_name pt-xl-3"> <h3 className="captilize"> {this.state.first_name}  {this.state.last_name} </h3>
                                                            <div class="input-group">
                                                                <div class="custom-file">
                                                                    <input id="file" onChange={this.onFileChange} type="file" className="custom-file-input" />
                                                                    <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                                                </div>
                                                                <div class="input-group-prepend">
                                                                    <span class="btn btn-info mb-2" id="inputGroupFileAddon01" onClick={this.onFileUpload}> <span class="spt-img upload-icon-white"></span> Upload Photo {this.state.isLoading ? (
                                                                        <span
                                                                            className="spinner-border spinner-border-sm ml-4"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        ></span>
                                                                    ) : (
                                                                            <span></span>
                                                                        )}</span>
                                                                </div>
                                                            </div>
                                                            <p>Allowed JPG, GIF or PNG.  <br/> <span>  {this.state.fileOriginalName}  </span> </p>
                                                            <div className="text-danger">{this.state.errorFileMsg}</div>
                                                        </div>


                                                        {/* <div class="profile_name pt-xl-3"> <h3 className="captilize"> {this.state.name}  </h3>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <button disabled={!this.state.selectedFile} className="btn btn-info mb-2" onClick={this.onFileUpload} id="inputGroupFileAddon01">Upload Photo   {this.state.isLoading ? (
                                                                        <span
                                                                            className="spinner-border spinner-border-sm ml-4"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        ></span>
                                                                    ) : (
                                                                            <span></span>
                                                                        )} </button>
                                                                </div>
                                                                <div className="custom-file">
                                                                    <input id="file" onChange={this.onFileChange} type="file" className="custom-file-input" />
                                                                    <label className="custom-file-label" >Choose file</label>
                                                                </div>
                                                            </div>
                                                            <p>Allowed JPG, GIF or PNG.</p>
                                                            <div className="text-danger">{this.state.errorFileMsg}</div>
                                                        </div> */}
                                                    </div>
                                                    <div class="profile_deatils pt-xl-5">
                                                        <form onSubmit={this.handleSubmit}>


                                                            {this.state.status == true ? (<div className="alert alert-success">
                                                                {this.state.message}
                                                            </div>) : ('')}

                                                            <div class="card-header formTitle">
                                                                <h5>BASIC INFORMATION </h5>
                                                            </div>
                                                            <br />

                                                            <div class="row">
                                                                <div class="col-sm-6 form-group">
                                                                    <label for="">Full Name*</label>
                                                                    <input type="text" class="form-control" name="name" placeholder="" value={this.state.name} onChange={this.onChangehandler} />
                                                                    <div className="text-danger">{this.state.errMsgName}</div>
                                                                </div>

                                                                <div class="col-sm-6 form-group">
                                                                    <label for="">Gender* </label>
                                                                    {/* <input type="text" class="form-control" name="gender" value={this.state.gender} onChange={this.onChangehandler} /> */}

                                                                    <select name="gender" class="form-control" onChange={this.onChangehandler}>

                                                                        <option value="Male" selected={this.state.gender == "male" ? "selected" : ""} > Male</option>
                                                                        <option value="Female" selected={this.state.gender == "female" ? "selected" : ""}> Female</option>
                                                                    </select>


                                                                    <div className="text-danger">{this.state.errMsgGender}</div>
                                                                </div>

                                                                <div class="col-sm-6 form-group">
                                                                    <label for="">Email*</label>
                                                                    <input type="text" class="form-control" name="email"
                                                                        value={this.state.email} readonly disabled />
                                                                </div>
                                                                <div class="col-sm-6 form-group">
                                                                    <label for="">Mobile*</label>
                                                                    <input type="text" class="form-control"
                                                                        name="mobile" value={this.state.mobile} readonly disabled />

                                                                </div>
                                                            </div>


                                                            <div class="card-header formTitle">
                                                                <h5>ADVANCE INFORMATION </h5>
                                                            </div>

                                                            <br />

                                                            <div class="row">
                                                                <div class="col-sm-6 form-group">
                                                                    <label for="">Experience*</label>
                                                                    <input type="number" class="form-control" name="experience" value={this.state.experience} onChange={this.onChangehandler} />
                                                                    <div className="text-danger">{this.state.errMsgExperience}</div>
                                                                </div>
                                                                <div class="col-sm-6 form-group">
                                                                    <label for="">Registration Number & Council *</label>
                                                                    <input type="text" class="form-control" name="registration_no" value={this.state.registration_no} onChange={this.onChangehandler} />
                                                                    <div className="text-danger">{this.state.errMsgRegNum}</div>
                                                                </div>

                                                                <div class="col-sm-6 form-group">
                                                                    <label for="">Qualification*</label>
                                                                    <Multiselect options={this.state.qualificationOptions} selectedValues={this.state.selectedQualificationValue} displayValue="name" name="specializations"
                                                                        onSelect={this.handleChangeQualification} onRemove={this.handleChangeQualification} />
                                                                    <div className="text-danger">{this.state.errMsgQualifications}</div>

                                                                </div>

                                                                <div class="col-sm-6 form-group">
                                                                    <label for="">Specialization*</label>
                                                                    <Multiselect options={this.state.specializationsOptions} selectedValues={this.state.selectedSpecializationValue} onSelect={this.handleChangeSpecialization} onRemove={this.handleChangeSpecialization} displayValue="name" name="specializations" />
                                                                    <div className="text-danger">{this.state.errMsgSpecializations}</div>

                                                                </div>
                                                                <div class="col-sm-6 form-group">
                                                                    <label for="">Languages*</label>
                                                                    <Multiselect options={this.state.languagesOptions} selectedValues={this.state.selectedLanguagesValue} onSelect={this.handleChangeLanguage} onRemove={this.handleChangeLanguage} displayValue="name" name="languages" />
                                                                    <div className="text-danger">{this.state.errMsgLanguages}</div>

                                                                </div>

                                                                <div class="col-sm-6 form-group">
                                                                    <label for="">Consultation Fee (INR)*</label>
                                                                    <input type="number" class="form-control" name="fee" value={this.state.fee} onChange={this.onChangehandler} />

                                                                    <div className="text-danger">{this.state.errMsgFee}</div>
                                                                </div>


                                                                <div class="col-sm-12 form-group">
                                                                    <label for="">About*</label>
                                                                    <textarea class="form-control" name="about"
                                                                        onChange={this.onChangehandler} value={this.state.about} rows={40}
                                                                        cols={10} />
                                                                    <div className="text-danger">{this.state.errMsgAbout}</div>



                                                                </div>

                                                                <div class="col-sm-12 form-group">
                                                                    <label for="">Educational Qualification And Certificate* </label>
                                                                    <textarea class="form-control" name="educationDetail" rows="10" cols="10" value={this.state.educationDetail} onChange={this.onChangehandler}></textarea>
                                                                    <div className="text-danger">{this.state.errMsgEducationDetail}</div>
                                                                </div>


                                                                {this.state.signature ? (<div class="col-sm-12 form-group">
                                                                    <label>Doctor's Signature : </label>
                                                                    <img src={ process.env.REACT_APP_ADMIN_BASE_URL+'images/doctor/signature/'+ this.state.signature} alt="signature" />
                                                                </div>) : ('') }
                                                                



                                                                <div class="col-md-12 pt-xl-4">
                                                                    <button type="submit" class="pay-btn">Save {this.state.isLoading ? (
                                                                        <span
                                                                            className="spinner-border spinner-border-sm ml-5"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        ></span>
                                                                    ) : (
                                                                            <span></span>
                                                                        )}</button>
                                                                    {/* <button type="submit" class="cancel-btn">Cancel</button> */}
                                                                </div>
                                                            </div>

                                                        </form>
                                                    </div>

                                                </div>
                                            </Tab>

                                            <Tab eventKey="timings" title=" Timing " class="nav-item">
                                                <Timing />
                                            </Tab>

                                            <Tab eventKey="setting" title=" Setting " class="nav-item">
                                                <Setting />
                                            </Tab>

                                            <Tab eventKey="change-Password" title=" Change Password "
                                                class="nav-item">
                                                <ChangePassword />
                                            </Tab>

                                        </Tabs>


                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>


            </section>

        )

    }
}

export default DoctorSetting;