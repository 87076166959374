import React, { Component } from 'react';
import App from './pages/App';
import * as Cookies from 'js-cookie';


class Agora extends Component {

  render() {
    Cookies.set('channel', this.props.match.params.channel)
    // Cookies.set('channel', this.props.channel);
    return <App />
  }

}

export default Agora;