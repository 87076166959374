import React, { Component } from 'react';
import LEFTNAV from "./includes/leftnav";
import { Button, Modal } from 'react-bootstrap';
import axios from "axios";
class ReferalList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }
   


    componentDidMount() {
       
    }




    render() {
        return (
            <section className="doctor-consultation-view doctor-main-section">
                <div className="container-fulid">
                    <div>

                        <LEFTNAV />
                        <div className="content-wrapper">
                            <div className="patient-appointment-list-right-col">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h2><span className="calendar-teg"><i className="spt-img calendar-icon"></i></span> Referrals</h2>
                                        {/* <form className="appointments-list-search">
                                            <input type="text" name="appointments-list-search" className="form-control" placeholder="Search" />
                                            <button type="submit" className="ap-list-search"><i className="spt-img search-icon"></i></button>
                                        </form> */}
                                    </div>
                                  
                                </div>
                                <div className="list-table">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th>Patient</th>
                                                    <th>Age</th>
                                                    <th>Medical History</th>
                                                    <th>Gender</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>




        )

    }
}

export default ReferalList;