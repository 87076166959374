import React, { Component } from 'react';
import LEFTNAV from "./leftnav";
import { Link } from "react-router-dom";
import axios from "axios";
import ChangePassword from "./changePassword";






class Profilesetting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            mobile: '',
            age: '',
            gender: '',
            email: '',
            madical_history: ' ',
            relation: '',
            selectedFile: '',
            errorFileMsg: '',
            fileOriginalName: '',


            successMsg: ' ',
            status: false,
            isLoading: false,

            errMsgFirstName: "",
            errMsgLastName: "",
            errMsgAge: "",
            errMsgMobile: "",
            errMsgGender: "",
            errMsgEmail: "",
            errMsgRelation: "",
            errMsgMedicalHistory: "",

        };
    }

    componentDidMount() {

        if (this.props.match.params.userId) {
            var id = this.props.match.params.userId;
            localStorage.setItem("filterByUserId", id);
            var memberObject = JSON.parse(localStorage.getItem("userData"));
            var memberId = memberObject.id;


        }

        // Pass token in the header
        let auth_token = localStorage.getItem('token');
        let header_token = { 'x-access-token': auth_token };

        var body = {
            memberId: memberId,
            memberContactId: id,
        }









        axios.post(process.env.REACT_APP_API_URL + "patient", body,
            { headers: header_token }).then((response) => {

                var result = response.data[0];
                // localStorage.setItem("userData", JSON.stringify(response.data[0]));

                localStorage.setItem("selectedUser", JSON.stringify(response.data[0]));

                console.log("Member Details :", result);
                this.setState({
                    first_name: result.first_name,
                    last_name: result.last_name,
                    mobile: result.mobile,
                    age: result.age,
                    gender: result.gender,
                    email: result.email,
                    madical_history: result.madical_history,
                    relation: result.relation,
                    profilePic: result.profile_pic
                });



            })
            .catch((error) => {
                console.log('Patient catch error');
                console.log(error);
            });


    }



    registerSubmitHandler = (event) => {



        event.preventDefault();
        this.setState({ isLoading: true });




        // console.log(this.state);
        var id = this.props.match.params.userId;

        if (id === undefined) {
            const user = JSON.parse(localStorage.getItem("userData"));
            id = user.id;
        }




        // Pass token in the header
        let auth_token = localStorage.getItem('token');
        let header_token = { 'x-access-token': auth_token };
        // Update Member 
        axios.put(process.env.REACT_APP_API_URL + "patient/" + id, this.state,
            { headers: header_token }).then((response) => {
                console.log("Response of update profile", response);
                if (response.data.success === true) {
                    console.log("Success member profile update :", response);
                    this.setState({
                        successMsg: response.data.message,
                        status: true,
                        errMsgFirstName: "",
                        errMsgLastName: "",
                        errMsgAge: "",
                        errMsgMobile: "",
                        errMsgGender: "",
                        errMsgEmail: "",
                        errMsgRelation: "",
                        errMsgMedicalHistory: "",
                        isLoading: false,

                    });
                }
                else if (response.data.status === "failed") {
                    this.setState({
                        status: false,
                        errMsgFirstName: response.data.errors.first_name,
                        errMsgLastName: response.data.errors.last_name,
                        errMsgAge: response.data.errors.age,
                        errMsgGender: response.data.errors.gender,
                        errMsgMobile: response.data.errors.mobile,
                        errMsgEmail: response.data.errors.email,
                        errMsgRelation: response.data.errors.relation,
                        errMsgMedicalHistory: response.data.errors.madical_history,
                    });
                }
                else {

                }

                setTimeout(() => {
                    this.setState({
                        status: false,
                        isLoading: false,
                    });
                }, 5000);


            }).catch((error) => {

                console.log('catch error');
                console.log(error);

            });
    }


    registerChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onFileChange = event => {
        // Update the state 
        this.setState({ 
            selectedFile: event.target.files[0],
            fileOriginalName: event.target.files[0].name, 
        });

            // alert("get file name ", event.target);
          // event.target.files[0]

          // alert(event.target.files[0].name);



        var fileInput = document.getElementById('file');
       

        var filePath = fileInput.value;
        // Allowing file type 
        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

        if (!allowedExtensions.exec(filePath)) {

            this.setState({
                errorFileMsg: 'Invalid file type',
                selectedFile: '',
            })

            fileInput.value = '';
            return false;
        }
        else {

            this.setState({
                errorFileMsg: '',
            })
        }


    };



    onFileUpload = () => {

        if (this.state.selectedFile) {


            var id = this.props.match.params.userId;
            this.setState({ isLoading: true });

            // Create an object of formData 
            const formData = new FormData();

            // Update the formData object 
            formData.append(
                "myFile",
                this.state.selectedFile,
                this.state.selectedFile.name
            );



            if ((id == undefined) || (id == null)) {
                var patientDetail = JSON.parse(localStorage.getItem("userData"));
                id = patientDetail.membercontactId;
            }




            axios.post(process.env.REACT_APP_API_URL + "updatePatientPic/" + id, formData).then((response) => {

                if (response.data.success === true) {

                    this.setState({
                        successMsg: response.data.message,
                        status: true,
                        isLoading: false,
                        profilePic: response.data.path,
                        selectedFile:'',

                    });

                }

                setTimeout(() => {
                    this.setState({
                        status: false,
                        isLoading: false,
                    });
                }, 5000);

                console.log("Result of update Image ", response);

            });

        } else {

            this.setState({ isLoading: false });
        }


    }





    render() {
        const status = this.state.status;
        const isLoading = this.state.isLoading;



        // Show and hide change password button as per primary member
        var changeStatus;
        var selectedUser = JSON.parse(localStorage.getItem("selectedUser"));
        if (selectedUser) {
            changeStatus = selectedUser.relation;
        } else {
            changeStatus = "Self";
        }



        return (
            <section className="patient-appointment-section patient-main-section">
                <div className="container">
                    <div>
                        <LEFTNAV />

                        <div className="content-wrapper">
                            <div className="patient-appointment-list-right-col">
                                <div className="col-md-12">

                                    {status ? (
                                        <div>
                                            <br />
                                            <div className="alert alert-success">
                                                {this.state.successMsg}.
                                        </div>
                                        </div>
                                    ) : (
                                            <span></span>

                                        )}

                                    <h2><span><i className="spt-img calendar-icon"></i></span> Profile Settings   </h2>
                                </div>


                                <div className="list-table">
                                    <div className="Profilerow d-flex pt-xl-4">
                                        <div className="profileavtar pr-5">
                                            {this.state.profilePic == null ?
                                                (<img alt="image-not-found" src={process.env.REACT_APP_BASE_URL + 'img/picture-not-available.jpg'} />
                                                )
                                                :
                                                (
                                                    <img alt="member-img" src={process.env.REACT_APP_IMAGE_LOCATION + "patient/profile_picture/" + this.state.profilePic} />
                                                )
                                            }
                                        </div>


                                        <div class="profile_name pt-xl-3"> <h3 className="captilize"> {this.state.first_name}  {this.state.last_name} </h3>
                                            <div class="input-group">
                                                <div class="custom-file">
                                                    <input id="file" onChange={this.onFileChange} type="file" className="custom-file-input" />
                                                    <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                                </div>
                                                <div class="input-group-prepend">
                                                    <span class="btn btn-info mb-2" id="inputGroupFileAddon01" onClick={this.onFileUpload}> <span class="spt-img upload-icon-white"></span> Upload Photo {this.state.isLoading ? (
                                                        <span
                                                            className="spinner-border spinner-border-sm ml-4"
                                                            role="status"
                                                            aria-hidden="true"
                                                        ></span>
                                                    ) : (
                                                            <span></span>
                                                        )}</span>
                                                </div>
                                            </div>
                                                    <p>Allowed JPG, GIF or PNG. 
                                                      <br/>  <span>  {this.state.fileOriginalName}  </span>
                                                    </p>
                                            <div className="text-danger">{this.state.errorFileMsg}</div>

                                            {/* <div class="custom-file patientChangePassword">
                                                <button class="change-pass" type="button">Change Password</button>
                                            </div> */}

                                            {changeStatus=="Self" ? (<ChangePassword/>):('')}
                                        </div>




                                        {/* <div className="profile_name pt-xl-3">
                                            <h3 className="captilize"> {this.state.first_name}  {this.state.last_name} </h3>


                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <button disabled={!this.state.selectedFile} className="btn btn-info mb-2" onClick={this.onFileUpload}>Upload Photo   {this.state.isLoading ? (
                                                        <span
                                                            className="spinner-border spinner-border-sm ml-4"
                                                            role="status"
                                                            aria-hidden="true"
                                                        ></span>
                                                    ) : (
                                                            <span></span>
                                                        )} </button>
                                                </div>
                                                <div className="custom-file">
                                                    <input id="file" onChange={this.onFileChange} type="file" className="custom-file-input" />
                                                    <label className="custom-file-label" >Choose file</label>
                                                </div>
                                                
                                               {changeStatus=="Self" ? (<ChangePassword/>):('')}
                                                    

                                            </div>

                                            
                                            <p>Allowed JPG, GIF or PNG.</p>
                                            <div className="text-danger">{this.state.errorFileMsg}</div>
                                        </div> */}
                                    </div>

                                    <form onSubmit={this.registerSubmitHandler}>
                                        <div className="profile_deatils pt-xl-5">
                                            <div className="row">
                                                <div className="col-sm-6 form-group">
                                                    <label>First Name *  </label>
                                                    <input type="text" className="form-control" name="first_name" value={this.state.first_name} onChange={this.registerChangeHandler} />
                                                    <div className="text-danger">{this.state.errMsgFirstName}</div>
                                                </div>

                                                <div className="col-sm-6 form-group">
                                                    <label>Last Name *</label>
                                                    <input type="text" className="form-control" name="last_name" value={this.state.last_name} onChange={this.registerChangeHandler} />
                                                    <div className="text-danger">{this.state.errMsgLastName}</div>
                                                </div>

                                                <div className="col-sm-6 form-group">
                                                    <label >Mobile *</label>
                                                    <input readOnly type="text" className="form-control" name="mobile" value={this.state.mobile} onChange={this.registerChangeHandler} />
                                                    <div className="text-danger">{this.state.errMsgMobile}</div>


                                                </div>


                                                <div className="col-sm-6 form-group">
                                                    <label >Email *</label>
                                                    <input readOnly type="text" className="form-control" name="email" value={this.state.email} onChange={this.registerChangeHandler} />
                                                    <div className="text-danger">{this.state.errMsgEmail}</div>
                                                </div>

                                                <div className="col-sm-6 form-group">
                                                    <label >Age *</label>
                                                    <input type="text" className="form-control" name="age" value={this.state.age} onChange={this.registerChangeHandler} />
                                                    <div className="text-danger">{this.state.errMsgAge}</div>
                                                </div>

                                                <div className="col-sm-6 form-group">
                                                    <label >Relationship *</label>
                                                    <input type="text" className="form-control" name="relation" value={this.state.relation} onChange={this.registerChangeHandler} />
                                                    <div className="text-danger">{this.state.errMsgRelation}</div>
                                                </div>



                                                <div className="col-sm-12 form-group">
                                                    <label >Gender *</label>
                                                    <span className="pt-2 gender_box">
                                                        <div className="radio-item">
                                                            <input type="radio" name="gender" value="male" id="male" className="describe-input" checked={this.state.gender === "male"} onChange={this.registerChangeHandler} />
                                                            <label className="label_f" htmlFor="male">Male</label>
                                                        </div>
                                                        <div className="radio-item">
                                                            <input type="radio" name="gender" value="female" id="femal" className="describe-input" checked={this.state.gender === "female"} onChange={this.registerChangeHandler} />
                                                            <label className="label_f" htmlFor="femal">Female</label>
                                                        </div>
                                                    </span>
                                                </div>


                                                <div className="col-sm-12 form-group">
                                                    <label>Medical History (Maximum allowed 1000 Characters) *</label>
                                                    <textarea maxLength="1000" value={this.state.madical_history} name="madical_history" className="form-control" onChange={this.registerChangeHandler}>


                                                    </textarea>
                                                    <div className="text-danger">{this.state.errMsgMedicalHistory}</div>
                                                </div>

                                                <div className="col-md-12 pt-xl-4">

                                                    <button type="submit" className="pay-btn"
                                                    > Save {isLoading ? (
                                                        <span
                                                            className="spinner-border spinner-border-sm ml-5"
                                                            role="status"
                                                            aria-hidden="true"
                                                        ></span>
                                                    ) : (
                                                            <span></span>
                                                        )}
                                                    </button>



                                                    <Link to="/patient/dashboard" className="cancel-btn">Back</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>

        )

    }
}

export default Profilesetting;