import React, { Component } from 'react';
import LEFTNAV from "./includes/leftnav";
import { Redirect, Link } from "react-router-dom";
import { Modal, Tab, Tabs } from 'react-bootstrap';
import JOINCALL from './joinCall';

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import axios from "axios";
class DoctorConsultaion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            doctorAppointments: [],

        };
    }





    getConsultationList(id) {
        console.log("Get doctor Id :", id);

        axios.get(process.env.REACT_APP_API_URL + 'doctor/getConsultationList/' + id)
            .then((response) => {
                console.log("--------Consultation list response ----------", response);
                if (response.data.status == true) {
                    this.setState({
                        doctorAppointments: response.data.result
                    });
                } else {
                    console.log("Failed Api request");
                }
            })
            .catch((error) => {
                console.log("Doctor Consultation  catch List");
            });
    }








    componentDidMount() {

        var LogedInUserObject = JSON.parse(localStorage.getItem("userData"));
        if (LogedInUserObject) {
            var doctorDetailId = LogedInUserObject.doctordetailId;
            this.getConsultationList(doctorDetailId);
        }

    }













    render() {


        // Redirect User if not login
        const login = localStorage.getItem("isLoggedIn");
        if (!login) {
            return <Redirect to="/" />;
        }

        console.log(this.state.modelPatientName);

        // const randonNumber = Math.floor((Math.random() * 999999999999999) + 1000);


        const columns = [

            {
                name: "Consultation No.",
                selector: "appointmentNo",
                sortable: true,
                width: "180px",
            },
            {
                name: "Patient Name",
                selector: "first_name",
                cell: appointment => <span> <span className="patientName">
                    <b className="captilize">{appointment.first_name} {appointment.last_name}</b>
                </span>
                    <p><span className="age">{appointment.age} yrs</span> | <span className="jendeer">{appointment.gender}</span></p>
                </span>,
            },
            {
                name: "Date",
                selector: "appointmentDate",
                sortable: true,

            },
            {
                name: "Time",
                selector: "appointment_time",
                sortable: true
            },

            {
                name: "Symptoms",
                selector: "symptoms",
                sortable: true
            },
            {
                name: "Consultation Type",
                selector: "consult_type",
                sortable: true,
                cell: appointment => <span>{appointment.consult_type == 1 ? 'Online' : 'In-Clinic'}</span>,
            },
            {
                name: "Status",
                selector: "appointmentStatus",
                sortable: true,
                cell: d => <span>{d.appointmentStatus == 2 ? (<span className="confirm">CONFIRM </span>) : ('')} {d.appointmentStatus == 5 ? (<span className="confirm"> COMPLETE </span>) : ('')}</span>,
            },

            {
                name: "Detail",
                selector: "appointmentId",
                cell: d => <span>{(d.appointmentStatus == 2) || (d.appointmentStatus == 5) ? ((
                    <Link to={`/doctor/consultationview/${d.appointmentId}`}><span> View </span></Link>)) : ('')} </span>,
                width: "100px",
                button: true,
            }

        ];


        const data = this.state.doctorAppointments;
        const tableData = { columns, data };


        return (
            <section className="doctor-consultation-view  doctor-main-section">
                <div className="container-fulid">
                    <div>
                        <LEFTNAV />

                        <div className="content-wrapper">
                            <div className="patient-appointment-list-right-col">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h2><span className="calendar-teg"><i className="spt-img calendar-icon"></i></span> Consultations</h2>
                                        {/* <form className="appointments-list-search">
                                            <input type="text" name="appointments-list-search" className="form-control" placeholder="Search" />
                                            <button type="submit" className="ap-list-search"><i className="spt-img search-icon"></i></button>
                                        </form> */}
                                    </div>

                                </div>
                                <div className="list-table doctor-consultation-view">
                                    <div className="table-responsive">




                                        {this.state.doctorAppointments.length > 0 ? (<DataTableExtensions {...tableData}>
                                            <DataTable
                                                title="Consultations"
                                                columns={columns}
                                                data={data}
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                paginationPerPage={10}
                                                className="table"
                                                responsive
                                            />
                                        </DataTableExtensions>) : (<div className="table">
                                            <div className="datanotAvailable"> There are no records to display </div>
                                        </div>)}



                                        
                                        {/* <table className="table">
                                            <thead className="thead-dark">
                                                <tr>

                                                    <th>Patient</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>Consultation No.</th>
                                                    <th className="symptoms">Symptoms</th>
                                                    <th>Type</th>
                                                    <th>Consult Type</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {this.state.doctorAppointments.map((appointment, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <table className="profile-table">
                                                                <tr>

                                                                    <td className="doctor-pro"><span className="member-img">

                                                                        {appointment.profile_pic == null ?
                                                                            (<img alt="image-not-found" src={process.env.REACT_APP_BASE_URL + "img/picture-not-available.jpg"} />
                                                                            )
                                                                            :
                                                                            (
                                                                                <img alt="member-img" src={process.env.REACT_APP_IMAGE_LOCATION + "patient/profile_picture/" + appointment.profile_pic} />
                                                                            )
                                                                        }

                                                                    </span></td>
                                                                    <td><b className="captilize">{appointment.first_name} {appointment.last_name}</b>
                                                                        <p><span className="age">{appointment.age} yrs</span> | <span className="jendeer">{appointment.gender}</span></p>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                        <td>{appointment.appointmentDate}</td>
                                                        <td>{appointment.appointment_time}</td>
                                                        <td>{appointment.appointmentNo}</td>
                                                        <td>{appointment.symptoms}</td>
                                                        <td>New</td>
                                                        <td>{appointment.consult_type == 1 ? 'Online' : 'In-Clinic'}</td>
                                                        {appointment.appointmentStatus == 2 ? (<td className="confirm">CONFIRM</td>) : ('')}

                                                        {appointment.appointmentStatus == 3 ? (<td className="cancelled">CANCELLED</td>) : ('')}

                                                        {appointment.appointmentStatus == 4 ? (<td className="cancelled">CANCELLED</td>) : ('')}

                                                        {appointment.appointmentStatus == 5 ? (<td className="confirm">COMPLETE </td>) : ('')}
                                                        <td>

                                                           


                                                            {(appointment.appointmentStatus == 2) || (appointment.appointmentStatus == 5) ? ('') :
                                                                ((<Link to={`/doctor/consultationview/${appointment.appointmentId}`}><span>
                                                                <img alt="image-not-found" src={process.env.REACT_APP_BASE_URL + "img/appointment.png"} />
                                                            </span></Link>))}


                                                        </td>
                                                    </tr>
                                                ))}



                                            </tbody>
                                        </table> */}
                                    </div>
                                </div>
                            </div>
                        </div>






                    </div>
                </div>
            </section>

        )

    }
}

export default DoctorConsultaion;