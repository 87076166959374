import React, { Component } from 'react';
import axios from "axios";
 

class User extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
        pager: {},
        pageOfItems: []
    };
  }

componentDidMount() {
  console.log('load component1');
  this.pageLoad();
}


// componentDidUpdate() {
//   console.log('load component2');
//   this.pageLoad();
// }



pageLoad(){
  axios.get("api/patient")
      .then((response) => {

        console.log("--------page load--------");
      
  });
}

// loadPage() {
//   axios.get("api/patient")
//       .then((response) => {
      
//   });
// }


render() {
    return <div className="container">
            <h2>Docters List</h2>
        
  <table class="table">
    <thead>
      <tr>
        <th>Name</th>
        <th>Experience</th>
        <th>Specialist</th>
        <th> Video Call</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Dr. A.K. Singh</td>
        <td>10 Years</td>
        <td>Orthopaedic</td>
        <td><a href="/agora">Call</a></td>
      </tr>
      <tr>
        <td>Dr. S.K. Singh</td>
        <td>15 Years</td>
        <td>Nurologist</td>
        <td><a href="/agora">Call</a></td>
      </tr>
      <tr>
        <td>Dr. P.K. Jaiswal</td>
        <td>20 Years</td>
        <td>Orthopaedic</td>
        <td><a href="/agora">Call</a></td>
      </tr>
    </tbody>
  </table>


          </div>
  }
}
 
export default User;