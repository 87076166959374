import React, { Component } from 'react';
import LEFTNAV from "./includes/leftnav";
import { Button, Modal } from 'react-bootstrap';
import axios from "axios";
import { Link } from "react-router-dom";


import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";


class Referral extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            referrals: [],
        };
    }


    getReferral() {

        axios.get(process.env.REACT_APP_API_URL + 'lab/referralList')
            .then((response) => {
                console.log("Get Lab referral ", response);

                if (response.data.status) {
                    this.setState({
                        referrals: response.data.result
                    })
                }
                else {
                    console.log("response failed for pharmacy referral ");
                }

            }).catch((error) => {
                console.log("catch error : ", error);
            });
    }


    componentDidMount() {
        this.getReferral();
    }




    render() {

        const columns = [

            {
                name: "Doctor",
                selector: "doctorName",
                sortable: true,

            },
            {
                name: "Patient",
                selector: "first_name",
                cell: appointment => <span> <span className="patientName">
                    <b className="captilize">{appointment.first_name} {appointment.last_name}</b>
                </span>
                    <p><span className="age">{appointment.age} yrs</span> | <span className="jendeer">{appointment.gender}</span></p>
                </span>,
            },
            {
                name: "Mobile",
                selector: "mobile",
                sortable: true,

            },
            {
                name: "Referral Summary",
                selector: "summary",
                sortable: true
            },

            {
                name: "Date",
                selector: "referralDate",
                sortable: true
            }

        ];


        const data = this.state.referrals;
        const tableData = { columns, data };


        return (
            <section className="doctor-consultation-view doctor-main-section">
                <div className="container-fulid">
                    <div>

                        <LEFTNAV />
                        <div className="content-wrapper">
                            <div className="patient-appointment-list-right-col">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h2><span className="calendar-teg"><i className="spt-img calendar-icon"></i></span> Referrals </h2>
                                    </div>

                                </div>
                                <div className="list-table">
                                    <div className="table-responsive">

                                        
                                    <DataTableExtensions {...tableData}>
                                        <DataTable
                                            title="Appointments"
                                            columns={columns}
                                            data={data}
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            pagination
                                            paginationPerPage={10}
                                            className="table"
                                            responsive
                                        />
                                    </DataTableExtensions>

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>




        )

    }
}

export default Referral;