import React, { Component } from 'react';
import LEFTNAV from "./includes/leftnav";
import { Button, Modal } from 'react-bootstrap';
import axios from "axios";
import { Redirect, Link } from "react-router-dom";


import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";


class PatientLIst extends Component {



    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            patients: [],
        };
    }


    getPatientList(id) {
        axios.get(process.env.REACT_APP_API_URL + 'doctor/getPatientList/' + id)
            .then((response) => {
                console.log("--------Patient list ----------", response);
                if (response.data.status == true) {
                    this.setState({
                        patients: response.data.result
                    });
                } else {
                    console.log("Failed Api request");
                }
            })
            .catch((error) => {
                console.log("catch error :", error);
            });
    }


    componentDidMount() {


        var LogedInUserObject = JSON.parse(localStorage.getItem("userData"));
        if (LogedInUserObject) {
            var doctorDetailId = LogedInUserObject.doctordetailId;
            this.getPatientList(doctorDetailId);
        }
    }




    render() {

        // Redirect User if not login
        const login = localStorage.getItem("isLoggedIn");
        if (!login) {
            return <Redirect to="/" />;
        }

        const columns = [


            {
                name: "Full Name",
                selector: "first_name",
                cell: appointment => <span> <span className="patientName">
                    <b className="captilize">{appointment.first_name} {appointment.last_name}</b>
                </span>
                </span>,
            },
            {
                name: "Age",
                selector: "age",
                sortable: true,

            },
            {
                name: "Email",
                selector: "email",
                sortable: true
            },

            {
                name: "Contact No.",
                selector: "mobile",
            },

            {
                name: "Gender",
                selector: "gender",
                sortable: true,
                cell: d => <span className="captilize">{d.gender}</span>,
            },

            {
                name: "Action",
                selector: "appointmentId",
                cell: d =>
                    <Link to={`/doctor/consultationview/${d.appointmentId}`} key={d.memberContactId}>
                        <button type="button" className="btn btn-outline-primary"> Detail </button>
                    </Link>,
                width: "100px",
                button: true,
            }

        ];


        const data = this.state.patients;
        const tableData = { columns, data };


        return (
            <section className="doctor-consultation-view doctor-main-section">
                <div className="container-fulid">
                    <div>

                        <LEFTNAV />
                        <div className="content-wrapper">
                            <div className="patient-appointment-list-right-col">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h2><span className="calendar-teg"><i className="spt-img calendar-icon"></i></span> Patients</h2>
                                        {/* <form className="appointments-list-search">
                                            <input type="text" name="appointments-list-search" className="form-control" placeholder="Search" />
                                            <button type="submit" className="ap-list-search"><i className="spt-img search-icon"></i></button>
                                        </form> */}
                                    </div>

                                </div>
                                <div className="list-table">
                                    <div className="table-responsive">




                                        {this.state.patients.length > 0 ? (<DataTableExtensions {...tableData}>
                                            <DataTable
                                                title="Patients"
                                                columns={columns}
                                                data={data}
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                paginationPerPage={10}
                                                className="table"
                                                responsive
                                            />
                                        </DataTableExtensions>) : (<div className="table">
                                            <div className="datanotAvailable"> There are no records to display </div>
                                        </div>)}

                                        

                                        {/* <table className="table">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Patient</th>
                                                    <th>Age</th>
                                                    <th>Email</th>
                                                    <th>Mobile</th>
                                                    <th>Gender</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.patients.map((patient,index)=>(

                                                    <tr>
                                                        <td> {index+1} </td>
                                                        <td className="captilize">
                                                                {patient.first_name} <nbsp/> 
                                                                {patient.last_name}
                                                        </td>
                                                        <td>{patient.age}</td>
                                                        <td>{patient.email}</td>
                                                        <td>{patient.mobile}</td>
                                                        <td> {patient.gender} </td>
                                                        <td> 
                                                            <Link to={`/doctor/consultationview/${patient.appointmentId}`} key={patient.memberContactId}>
                                                                <button type="button" className="btn btn-outline-primary"> Detail </button>
                                                            </Link> 
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>




        )

    }
}

export default PatientLIst;