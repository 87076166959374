import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AnimatedNumber from "animated-number-react";


class Home extends Component {

  state = {
    value: 1968,
    duration: 10000,
     userInput: '',
  };
  
  formatValue = (value) => value.toFixed(0);


  clearSearch(){
     
  }

  componentDidMount(){
    this.clearSearch();
  }


  render() {

    // document.getElementById('printbtn').value = '';

    return (
      <React.Fragment>
        <section className="homme_banner">
          <img className="img-fluid" src={process.env.REACT_APP_BASE_URL + "img/banner-1.jpg"} alt="home banner" />
          <article className="banner_capations container">
            <div className="banner_inner">
              <Link to="/">
                <img src={process.env.REACT_APP_BASE_URL + "img/playbtn.png"} alt="play buttons" />
              </Link>
              <h1> Stay Home <span> Consult your Doctor Online </span> </h1>


            </div>
          </article>
        </section>
        <section className="abouttext">
          <article className="container">
            <div className="abouttextinner">
              <div className="row ">
                <div className="col-xl-5 col-md-12 doctorscounting pt-xl-5">
                  {/* <h2> 1968+</h2> */}
                  {/* <input
                    type="number"
                    onChange={this.handleChange}
                    value={this.state.value}
                  /> */}
                  <h2>
                    <AnimatedNumber
                      value={this.state.value}
                      formatValue={this.formatValue} duration={this.state.duration}
                    /> +
                  </h2>
                  <h4> Doctors </h4>
                </div>
                <div className="col-xl-7 col-md-12 aboutright">
                  <h3> How it works </h3>
                  <p> Consulting through health click is as easy as a walk in the park! Browse or search for a
                  specialist, book an appointment online, pay online and show up in qurabl during your
                  scheduled appointment time. Register with us first you haven’t done so already and you will
                  be connected with top doctors and get the best care at your convenience for you and your
                            Family. </p>

                  <div className="registerrow pt-xl-3">
                    <ul>
                      <li> <h4> 1 <span> Register </span> </h4> </li>
                      <li>  <h4> 2 <span> Book An Appointment </span> </h4> </li>
                      <li> <h4> 3 <span> Pay </span> </h4> </li>
                      <li> <h4> 4 <span> Consult </span> </h4> </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>

      </React.Fragment>)
  }
}

export default Home;