import React, { Component } from 'react';
import LEFTNAV from "./includes/leftnav";
import { Button, Modal } from 'react-bootstrap';
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { Document, Page,pdfjs } from 'react-pdf';
import NumberFormat from 'react-number-format';


import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

class DoctorAppointment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,

            doctorAppointments: [],
            showHide: false,
            modelAppointmentId: '',
            modelAppointmentNo: '',
            modelPatientName: '',
            modelPatientPic: '',
            modelGender: '',
            modelAge: '',
            modelSymptoms: '',
            modelDocuments: '',
            modelDate: '',
            modelTime: '',
            modelConsultType: '',
            modelfee: '',
            modelStatus: '',
            message: '',
            appointmentStatus: false,


        };


    }

    state = { numPages: null, pageNumber: 1 };

    onDocumentLoadSuccess = ({ numPages }) => {
      this.setState({ numPages });
    };


    handleModalShowHide(id) {

        this.setState({
            showHide: !this.state.showHide,
            appointmentStatus: false,
            message: ''
        })
        this.getAppointmentDetail(id);
        this.componentDidMount();
    }

    getAppointmentDetail(id) {

        console.log(id);


        axios.get(process.env.REACT_APP_API_URL + 'doctor/getAppointmentDetails/' + id)
            .then((response) => {

                console.log("get single appointment :", response);


                if (response.data.status === true) {

                    this.setState({
                        modelAppointmentId: response.data.result[0].appointmentId,
                        modelAppointmentNo: response.data.result[0].appointmentNo,
                        modelPatientName: response.data.result[0].first_name + ' ' + response.data.result[0].last_name,
                        modelPatientPic: response.data.result[0].profile_pic,
                        modelGender: response.data.result[0].gender,
                        modelAge: response.data.result[0].age,
                        modelSymptoms: response.data.result[0].symptoms,
                        modelDocuments: response.data.result[0].documents,
                        modelDate: response.data.result[0].appointmentDate,
                        modelTime: response.data.result[0].appointment_time,
                        modelConsultType: response.data.result[0].consult_type,
                        modelfee: response.data.result[0].fee,
                        modelStatus: response.data.result[0].appointmentStatus,
                    })
                } else {

                    console.log("response failed for single object");
                }

            }).catch((error) => {

                console.log("catch error : ", error);

            });
    }


    getAppointmentList(id) {
        console.log("Get doctor Id :", id);

        axios.get(process.env.REACT_APP_API_URL + 'doctor/getAppointmentList/' + id)
            .then((response) => {
                console.log("--------Appointment list response ----------", response);
                if (response.data.status == true) {
                    this.setState({
                        doctorAppointments: response.data.result
                    });
                } else {
                    console.log("Failed Api request");
                }
            })
            .catch((error) => {
                console.log("Doctor Appointment  catch List");
            });
    }

    changeStatusAppointment(id, status) {

        var body = {
            id: id,
            status: status

        }

        console.log(body);

        // Pass token in the header
        let auth_token = localStorage.getItem('token');
        let header_token = { 'x-access-token': auth_token };

        axios.post(process.env.REACT_APP_API_URL + 'doctor/changeAppointmentStatus',
            body, { headers: header_token }).then((response) => {
                if (response.data.status == true) {

                    this.setState({
                        appointmentStatus: true,
                        message: response.data.message,
                        isLoading: false,

                    });
                }
            })
            .catch((error) => {
                console.log("Catch error :", error);
            })

    }



    componentDidMount() {
        this.setState({ isLoading: true });

        var LogedInUserObject = JSON.parse(localStorage.getItem("userData"));

        if (LogedInUserObject) {
            var doctorDetailId = LogedInUserObject.doctordetailId;
            this.getAppointmentList(doctorDetailId);
        }
    }




    render() {

        // Redirect User if not login
        const login = localStorage.getItem("isLoggedIn");
        if (!login) {
            return <Redirect to="/" />;
        }

        const documentCss = {
            "width": "400px",
        }


        const columns = [

            {
                name: "Appointment No.",
                selector: "appointmentNo",
                sortable: true,
                width: "180px",
            },
            {
                name: "Patient Name",
                selector: "first_name",
                cell: appointment => <span> <span className="patientName">
                    <b className="captilize">{appointment.first_name} {appointment.last_name}</b>
                </span>
                    <p><span className="age">{appointment.age} yrs</span> | <span className="jendeer">{appointment.gender}</span></p>
                </span>,
            },
            {
                name: "Date",
                selector: "appointmentDate",
                sortable: true,

            },
            {
                name: "Time",
                selector: "appointment_time",
                sortable: true
            },

            {
                name: "Symptoms",
                selector: "symptoms",
                sortable: true
            },
            {
                name: "Consultation Type",
                selector: "consult_type",
                sortable: true,
                cell: appointment => <span>{appointment.consult_type == 1 ? 'Online' : 'In-Clinic'}</span>,
            },
            {
                name: "Status",
                selector: "appointmentStatus",
                sortable: true,
                cell: d => <span>{d.appointmentStatus == 1 ? (<span className="pending">PENDING </span>) : ('')} {(d.appointmentStatus == 3) || (d.appointmentStatus == 4) ? (<span className="cancelled"> CANCELLED </span>) : ('')}</span>,
            },

            {
                name: "Detail",
                selector: "appointmentId",
                cell: d => <span className="button" onClick={() => this.handleModalShowHide(d.appointmentId)}>
                    View
                </span>,
                width: "100px",
                button: true,
            }

        ];


        const data = this.state.doctorAppointments;
        const tableData = { columns, data };

        return (
            <section className="doctor-consultation-view doctor-main-section">
                <div className="container-fulid">
                    <div>

                        <LEFTNAV />
                        <div className="content-wrapper">
                            <div className="patient-appointment-list-right-col">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h2><span className="calendar-teg"><i className="spt-img calendar-icon"></i></span> Appointments</h2>

                                    </div>

                                </div>
                                <div className="list-table">
                                    <div className="table-responsive">




                                        {this.state.doctorAppointments.length > 0 ? (<DataTableExtensions {...tableData}>
                                            <DataTable
                                                title="Appointments"
                                                columns={columns}
                                                data={data}
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                paginationPerPage={10}
                                                className="table"
                                                responsive
                                            />
                                        </DataTableExtensions>) : (<div className="table">
                                            <div className="datanotAvailable"> There are no records to display </div>
                                        </div>)}






                                        {/* <table className="table">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th>Patient</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>Appointment No.</th>
                                                    <th className="symptoms">Symptoms</th>
                                                    
                                                    <th>Consult Type</th>
                                                    <th>Status</th>
                                                    <th>Detail</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {this.state.doctorAppointments.map((appointment, index) => (
                                                    <tr>
                                                        <td>
                                                            <table class="profile-table">
                                                                <tr>
                                                                    <td class="doctor-pro"><span class="member-img">

                                                                        {appointment.profile_pic == null ?
                                                                            (<img alt="image-not-found" src={process.env.REACT_APP_BASE_URL + "img/picture-not-available.jpg"} />
                                                                            )
                                                                            :
                                                                            (
                                                                                <img alt="member-img" src={process.env.REACT_APP_IMAGE_LOCATION + "patient/profile_picture/" + appointment.profile_pic} />
                                                                            )
                                                                        }

                                                                    </span></td>
                                                                    <td><b className="captilize">{appointment.first_name} {appointment.last_name}</b>
                                                                        <p><span class="age">{appointment.age} yrs</span> | <span class="jendeer">{appointment.gender}</span></p>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                        <td>{appointment.appointmentDate}</td>
                                                        <td>{appointment.appointment_time}</td>
                                                        <td>{appointment.appointmentNo}</td>
                                                        <td>{appointment.symptoms}</td>
                                                        
                                                        <td>{appointment.consult_type == 1 ? 'Online' : 'In-Clinic'}</td>

                                                        {appointment.appointmentStatus == 1 ? (<td className="cancelled">PENDING</td>) : ('')}

                                                        {(appointment.appointmentStatus == 3) || (appointment.appointmentStatus == 4) ? (<td className="cancelled">CANCELLED</td>) : ('')}

                                                        
                                                       <td>
                                                            <span onClick={() => this.handleModalShowHide(appointment.appointmentId)} >
                                                                <img alt="image-not-found" src={process.env.REACT_APP_BASE_URL + "img/appointment.png"} />
                                                            </span>
                                                        </td>
                                                        
                                                    </tr>
                                                ))}



                                            </tbody>
                                        </table> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="doctor-appointment-modal" show={this.state.showHide}>


                    <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                        <h5 class="modal-title" id="exampleModalLabel">Appointment #  <span>{this.state.modelAppointmentNo}</span></h5>
                    </Modal.Header>
                    <Modal.Body>




                        <div class="row">
                            <div class="col-sm-12">

                                {
                                    this.state.appointmentStatus === true ? (<div class="alert alert-success">
                                        {this.state.message}
                                    </div>) : ("")
                                }

                            </div>
                            <div class="col-sm-4">
                                <div class="dashboard-user">
                                    <div class="dashboard-user-col">
                                        <div class="mamber-pro">
                                            <span class="member-img">
                                                {this.state.modelPatientPic == null ?
                                                    (<img alt="image-not-found" src={process.env.REACT_APP_BASE_URL + "img/picture-not-available.jpg"} />
                                                    )
                                                    :
                                                    (
                                                        <img alt="member-img" src={process.env.REACT_APP_IMAGE_LOCATION + "patient/profile_picture/" + this.state.modelPatientPic} />
                                                    )
                                                }
                                            </span>
                                            <span class="mamber-info">
                                                <h3 className="captilize">{this.state.modelPatientName}</h3>
                                                <p><span class="age">{this.state.modelAge} yrs</span> | <span class="jendeer">{this.state.modelGender}</span></p>
                                            </span>
                                        </div>
                                        <br /><br />
                                    </div>
                                </div>
                            </div>


                            {this.state.modelStatus == 1 ? (<div class="col-sm-8 text-right">
                                <button type="submit" disabled={!this.state.isLoading} class="cancel-appointment-btn" onClick={() => this.changeStatusAppointment(this.state.modelAppointmentId, 4)}>Cancel Appointment</button>
                                <button type="submit" disabled={!this.state.isLoading} class="cancel-appointment-btn" onClick={() => this.changeStatusAppointment(this.state.modelAppointmentId, 2)}>Confirm Appointment</button>
                            </div>) : ('')}


                        </div>
                        <div class="view-appoint-details">
                            <ul>
                                <li><span class="spt-img date-icon"></span> {this.state.modelDate}</li>
                                <li><span class="spt-img time-icon"></span> {this.state.modelTime}</li>
                                <li><span class="spt-img rs-icon"></span> 
                                <NumberFormat value={this.state.modelfee} displayType={'text'} thousandSeparator={true} prefix={'Rs. '} />
                                </li>

                                {this.state.modelConsultType == 1 ?
                                    (<li><span class="spt-img online-icon"></span>Online</li>) :
                                    (<li><span class="spt-img inclinic-icon"></span>In-Clinic</li>)}

                                {/* <li><span class="spt-img new-icon"></span>New</li> */}
                            </ul>
                        </div>
                        <div class="doc-detail">
                            <h3>Symptoms</h3>
                            <p>{this.state.modelSymptoms}</p>
                            <h3>Documents</h3>

                            {this.state.modelDocuments == null ?
                                (<p>Patient has not uploaded any documents for this appointment.</p>) : (
                                  <a href={process.env.REACT_APP_IMAGE_LOCATION + "patient/documents/" + this.state.modelDocuments}  
                                target="_blank">View Document</a>
                                )}



                                         
                                
                        </div>
                    </Modal.Body>

                </Modal>
            </section>




        )

    }
}

export default DoctorAppointment;