import React, { Component } from 'react';
import axios from "axios";
import { Link,Redirect } from "react-router-dom";

import * as Cookies from 'js-cookie'

class JoinCall extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }

    render() {
        
        // Cookies.set('channel', this.props.channel);
        
        return (

            <React.Fragment>
                 <Link to={`/videocall/${this.props.channel}`}>
                    <button type="button" className="btn btn-primary btn-lg">Join call</button>
                </Link> 

                 {/* <button onSubmit={this.goToChatPage()} type="button" className="btn btn-primary btn-lg">Join call</button> */}
                

                
            </React.Fragment>


        )

    }
}

export default JoinCall;