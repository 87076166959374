import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import LEFTNAV from "./includes/leftnav";
import axios from "axios";
import AnimatedNumber from "animated-number-react";

export default class Contact extends Component {
    constructor(props) {
        const user = JSON.parse(localStorage.getItem("userData"));
        super(props);
        this.state = {
            totalReferral: '',
            duration: 2000,
        };
    }

    formatValue = (value) => value.toFixed(0);



    getDashboard() {

        axios.post(process.env.REACT_APP_API_URL + "pharmacy/dashboard")
            .then((response) => {
                console.log("get dashboard response :", response);
                if(response.data.status){
                    this.setState({
                        totalReferral:response.data.result.total
                    })
                }
            })
            .catch((error) => {
                console.log("catch error: ", error);
            })
    }





    onLogoutHandler = () => {
        localStorage.clear();
        window.location = process.env.REACT_APP_BASE_URL;
    }






    componentDidMount() {
        //var LogedInUserObject = JSON.parse(localStorage.getItem("userData"));
        this.getDashboard();
    }





    render() {

        const btn = { "cursor": "pointer" }

        const { navigate } = this.state;

        // Authenticate
        const login = localStorage.getItem("isLoggedIn");
        if (!login) {
            return <Redirect to="/" />;
        }
        if (navigate) {
            return <Redirect to="/" />;
        }


        return (
            <React.Fragment>
                <section className="doctor-consultation-view doctor-main-section">
                    <div className="container-fulid">
                        <div>
                            <LEFTNAV />
                            <div className="content-wrapper">
                                <div className="patient-appointment-list-right-col dr-dashboard">
                                    <div className="row">
                                        <div className="col-sm-7">
                                            <h2>Welcome to the pharmacy panel here is your summary for all.</h2>
                                        </div>
                                        <div className="col-sm-5">
                                            <div className="appointments-list-add-col">
                                                <div className="add-member-btn">
                                                    <form className="select-day">
                                                        <select name="filterValue" className="form-select" onChange={this.onChangeHandler}>
                                                            <option value="All">All</option>
                                                        </select>
                                                        <span className="spt-img bold-calender-icon"></span>
                                                        <span className="spt-img down-icon"></span>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dashboard-tab">
                                        <a href="#"><i className="spt-img appointments-big-icon"></i> Total Referral
                                    <AnimatedNumber
                                                value={this.state.totalReferral}
                                                formatValue={this.formatValue}
                                                duration={this.state.duration} />
                                        </a>

                                        <Link to="/" onClick={this.onLogoutHandler} style={btn}>
                                            <span className="spt-img Logout-icon"></span>
                                            <span> Logout </span>
                                            <br />
                                        </Link>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}