import React, { Component } from 'react';
import { NavDropdown, Tab, Tabs } from 'react-bootstrap';
import { Redirect, Link, NavLink } from "react-router-dom";
import LEFT_PANEL_DOCTOR_DETAIL from './leftPanelDoctorDetail';
import axios from "axios";
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import NumberFormat from 'react-number-format';

class bookAppointment extends Component {



    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            id: props.match.params.doctorId,
            members: [],
            symptoms: [],
            doctor: '',
            totalReview: '',
            week: [],
            todayDate: '',
            day: '',
            part1: true,
            part2: false,
            part3: false,
            bookingSuccess: false,
            appointmentNumber: '',
            transectionId: '',

            patientName: '',
            member_id: '',
            appointmentDate: '',
            appointmentTime: '',
            membercontact_id: '',
            consultation_type: '',
            selectedSymptoms: [],
            termsAndCondition: '',
            isChecked: false,

            date: '',
            slot: '',
            fee: null,
            payment_method: '',

            errMsgselectedMember: '',
            errMsgselectedSymptoms: '',
            errMsgConsultationType: '',
            errMsgtermsAndCondition: '',
            errMsgPaymentMethod: '',
            errorMsgBookedSlot: '',
            errMsgFileMsg: '',
            activeId: 0,
            activeIdSlot: null,

            activeIdMember: null,


            selectedFile: '',
            slotsArray: [],
            page: 1,
            emptySlotMessage: 'Slot not available',
            availableDays: null,


        }


    }


    nextPrevBtn(page) {

        this.setState({
            page: page,
        })
    }

    handleClick(event, week) {

        var selectedDate = week;
        // show date and slots
        this.setState({
            activeId: event,
            appointmentDate: selectedDate,
            activeIdSlot: '',
            isLoading: true,
            appointmentTime: '',
            slotsArray: '',
            emptySlotMessage: '',
        })

        setTimeout(() => {
            this.getCalenderSlot(selectedDate);
        }, 1000);

    }


    getCalenderSlot(date) {

        var newDate = new Date(date);
        var newDay = newDate.getDate();
        var newMonth = newDate.getMonth() + 1;
        var newYear = newDate.getFullYear();
        var requestDate = newYear + '-' + newMonth + '-' + newDay;

        var body = {
            id: this.state.id,
            date: requestDate,
        }

        axios.post(process.env.REACT_APP_API_URL + 'getDoctorCalendar', body)
            .then((response) => {

                console.log(response);
                if (response.data.status == true) {

                    this.setState({
                        slotsArray: response.data.Calendar_Slots,
                        isLoading: false,
                    });

                    // console.log(" slot array  :", response.data.Calendar_Slots.split(":"));
                }

                if (response.data.Calendar_Slots.length == 0) {
                    this.setState({
                        emptySlotMessage: 'Slot not available',
                    });
                }


            }).catch((error) => {

                console.log("catch error", error);
            })

    }

    getCurrentTime(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();

        hours = (hours*60)*60;
        minutes = minutes*60;

        // var ampm = hours >= 12 ? 'PM' : 'AM';
        // hours = hours % 12;
        // hours = hours ? hours : 12;
        // minutes = minutes < 10 ? '0' + minutes : minutes;
        // var strTime = hours + ':' + minutes + ' ' + ampm;
        // return strTime;

        var finalhourseInSeconds = parseInt(hours) + parseInt(minutes);
        return finalhourseInSeconds;
    }



    getHourseInSeconds(slot){
       var hms = slot.substr(0,2);
       var minutes = slot.substr(3,2);
       var ampm = slot.substr(6,2);

       
        hms = (hms*60)*60;
        minutes = minutes*60;

        ampm = ampm=='AM' ? 0 : 43200;

        if(slot.substr(0,2)==12){
            if(slot.substr(6,2)=='PM'){
               ampm = 0;
            }else{
                ampm = 43200;
            }
       }

        console.log("ampm-2", ampm);

        var finalhourseInSeconds = parseInt(hms) + parseInt(minutes) + parseInt(ampm);

        return finalhourseInSeconds;
       
       
    }

    handleSlotClick(event, slot) {


         this.getHourseInSeconds(slot);




        let todayDate = new Date();
        let todayDay = todayDate.getDate();
        let todayMonth = todayDate.getMonth() + 1;
        let todayYear = todayDate.getFullYear();
        let todayFullDate = todayYear + ',' + todayMonth + ',' + todayDay;
        let todayDateNew = new Date(todayFullDate);

        let newDate = new Date(this.state.appointmentDate);
        let newDay = newDate.getDate();
        let newMonth = newDate.getMonth() + 1;
        let newYear = newDate.getFullYear();
        let requestDate = newYear + ',' + newMonth + ',' + newDay;
        let requestDateNew = new Date(requestDate);

        var disableSlot = this.getCurrentTime(new Date);
        console.log("disableSlot", disableSlot);
        console.log("slot", disableSlot);

        if (todayDateNew.getTime() == requestDateNew.getTime()) {
            // check old slot for today
            if (disableSlot > this.getHourseInSeconds(slot)) {
                alert('This slot is over please choose other slot');
            } else {

                this.setState({
                    activeIdSlot: event,
                    appointmentTime: slot
                })
            }
        } else {

            console.log("request dat is greater then today date");
            this.setState({
                activeIdSlot: event,
                appointmentTime: slot
            })
        }

    }

    handleMemberClick(event, memberId) {
        this.setState({
            activeIdMember: event,
            membercontact_id: memberId
        })
    }


    getMember() {




        var patientDetail = JSON.parse(localStorage.getItem("userData"));
        if (patientDetail) {

            var id = patientDetail.id;
            // Pass token in the header
            let auth_token = localStorage.getItem('token');
            let header_token = { 'x-access-token': auth_token };

            axios.get(process.env.REACT_APP_API_URL + "getMemberList/" + id, { headers: header_token }).then((response) => {
                if (response.data.status === true) {
                    this.setState({
                        members: response.data.result,
                    });

                } else {
                    console.log(response);
                }
            })
                .catch((error) => {
                    console.log('catch error');
                    console.log(error);
                });
        }
    }


    getSymptoms() {

        axios.get(process.env.REACT_APP_API_URL + "getSymptomsList")
            .then((response) => {


                if (response.data.status === true) {
                    this.setState({
                        symptoms: response.data.result,
                    });

                } else {
                    console.log(response);
                }
            })
            .catch((error) => {
                console.log('catch error');
                console.log(error);
            });

    }



    getDoctorDetail(id) {

        var ApiPath = "getDoctorDetail/" + id;
        axios.get(process.env.REACT_APP_API_URL + ApiPath)
            .then((response) => {


                if (response.data.status === true) {

                    console.log("-------- Get doctors details ----------", response);
                    this.setState({
                        doctor: response.data.result[0],
                        fee: response.data.result[0].fee

                    });

                } else {

                    // console.log("-------- Get doctors details false ----------", response);

                }
            });
    }

    month_name(dt) {
        var date = new Date();
        var mlist = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return mlist[date.getMonth()];
    }

    getWeekDates() {



        var date = new Date();
        date.setDate(date.getDate() + 0);





        var today = date.getDate() + ' ' + (this.month_name(date.getMonth() + 1)) + ' ' + date.getFullYear();

        var arrayDate = [today];


        var availableCalendar = 7;
        for (var i = 1; i < availableCalendar; i++) {

            date.setDate(date.getDate() + 1);

            var month = date.getMonth() + 1;

            var finalDate = date.getDate() + ' ' + (this.month_name(month)) + ' ' + date.getFullYear();
            arrayDate.push(finalDate);

        }
        this.setState({
            week: arrayDate,
            todayDate: today
        });

        console.log("array date :", arrayDate);

    }

    getDoctorRating(id) {

        var ApiPath = "getDoctorRating/" + id;
        axios.get(process.env.REACT_APP_API_URL + ApiPath)
            .then((response) => {

                if (response.data.status === true) {
                    this.setState({
                        doctorRatingList: response.data.result,
                        totalReview: response.data.totalReview,
                    });
                } else {
                    // console.log("-------- Get doctors rating details false ----------", response);
                }
            });
    }




    getCalenderDate(id) {

        var body = {
            id: id
        };
        axios.post(process.env.REACT_APP_API_URL + 'getDoctorCalendarDate', body)
            .then((response) => {
                if (response.data.status === true) {

                    this.setState({
                        week: response.data.CalendarDate,
                        todayDate: response.data.CalendarDate[0].date,
                        appointmentDate: response.data.CalendarDate[0].date,

                    });

                } else {


                }
            }).catch((error) => {
                console.log("Catch error in the calendar date :", error);
            });
    }

    componentDidMount() {
        var searchParameter = this.props.match.params.doctorId;

        this.setState({
            id: searchParameter,
        });

        // console.log(searchParameter);
        this.getDoctorDetail(searchParameter);
        this.getDoctorRating(searchParameter);

        // this.getWeekDates();


        this.getMember();
        this.getSymptoms();



        this.getCalenderDate(searchParameter);
        this.getCalenderSlot(new Date());
    }


    getPart2() {



        // errMsgselectedMember

        if (this.state.membercontact_id == '') {
            this.setState({
                errMsgselectedMember: 'Please select Member',
            });
        } else {
            this.setState({
                errMsgselectedMember: '',
            });
        }


        if (this.state.selectedSymptoms.length === 0) {
            this.setState({
                errMsgselectedSymptoms: 'Please select symptoms',
            });
        } else {
            this.setState({
                errMsgselectedSymptoms: '',
            });
        }

        if (this.state.consultation_type == '') {
            this.setState({
                errMsgConsultationType: 'Please select consultation type',
            });
        } else {
            this.setState({
                errMsgConsultationType: '',
            });
        }
        if (this.state.isChecked == false) {
            this.setState({
                errMsgtermsAndCondition: 'Please accept the terms and condition',
            });
        }
        else {
            this.setState({
                errMsgtermsAndCondition: '',
            });
        }



        if ((this.state.selectedSymptoms.length > 0) &&
            (this.state.consultation_type != '') &&
            (this.state.membercontact_id != '') &&
            (this.state.isChecked != false)) {
            this.setState({
                part1: false,
                part2: true,
                //  part3: false,
                // errMsgselectedSymptoms: '',
                // errMsgtermsAndCondition: ''
            });
        }
    }


    getPart3() {



        this.setState({
            part1: false,
            part2: false,
            part3: true
        });

        this.getPatientDetail();
    }





    getPatientDetail() {

        var memberObject = JSON.parse(localStorage.getItem("userData"));
        var memberId = memberObject.id;

        // Pass token in the header
        let auth_token = localStorage.getItem('token');
        let header_token = { 'x-access-token': auth_token };

        var body = {
            memberId: memberId,
            memberContactId: this.state.membercontact_id,
        }

        axios.post(process.env.REACT_APP_API_URL + "patient", body,
            { headers: header_token })
            .then((response) => {


                if (response.status == 200) {
                    console.log("----respoinse of member details :", response);

                    var result = response.data[0];
                    this.setState({
                        patientName: result.first_name + ' ' + result.last_name,
                        member_id: result.member_id

                    });
                }

            })
            .catch((error) => {
                console.log('catch error');
                console.log(error);
            });

    }



    getBack(page) {

        if (page === 'part2') {

            this.setState({
                part1: true,
                part2: false,
                part3: false
            });
        } else {

            this.setState({
                part1: false,
                part2: true,
                part3: false
            });
        }
    }





    // File upload code 

    onFileChange = event => {
        // this.setState({ selectedFile: event.target.files[0] });
        this.setState({ selectedFile: event.target.files[0] });

        var fileInput = document.getElementById('file');
        var filePath = fileInput.value;
        // Allowing file type 
        var allowedExtensions = /(\.jpg|\.jpeg|\.pdf|\.png|\.gif)$/i;

        if (!allowedExtensions.exec(filePath)) {

            this.setState({
                errMsgFileMsg: 'Invalid file type',
                selectedFile: '',
            })



            fileInput.value = '';
            return false;
        }
        else {

            this.setState({
                errMsgFileMsg: '',
            })
        }

    };

    uploadDocument(id) {

        console.log("Upload document -1");
        if (this.state.selectedFile !== '') {

            console.log("Upload document -2");

            const formData = new FormData();
            formData.append(
                "myFile",
                this.state.selectedFile,
                this.state.selectedFile.name
            );
            axios.post(process.env.REACT_APP_API_URL + "updateDocument/" + id, formData).then((response) => {

                if (response.data.success === true) {
                    console.log("docuemnt upload success Success", response);
                } else {
                    console.log("Failed to upload :", response);
                }
            }).catch((error) => {
                console.log("Update document catch error ", error);
            })
        }

    }

    //  ENd file upload code


    confirmAppointment(paymentMethod) {

        this.setState({
            isLoading: false,
        });

        var data = {
            doctordetail_id: this.state.id,
            membercontact_id: this.state.membercontact_id,
            member_id: this.state.member_id,
            symptoms: this.state.selectedSymptoms,
            termsAndCondition: 1,
            date: this.state.appointmentDate,
            slot: this.state.appointmentTime,
            consultation_type: this.state.consultation_type,
            consultationFee: this.state.fee,
            paymentMethod: paymentMethod,

        }

        // Pass token in the header
        let auth_token = localStorage.getItem('token');
        let header_token = { 'x-access-token': auth_token };

        // book Appointment  
        axios.post(process.env.REACT_APP_API_URL + "bookAppointment/", data, { headers: header_token }).then((response) => {
            console.log("Response of final appointment", response);
            if (response.data.success === true) {
                // update document 
                console.log("invoice response :", response);

                this.setState({
                    successMsg: response.data.message,
                    status: true,
                    isLoading: false,
                    appointmentNumber: response.data.appointmentNumber,
                    transectionId: response.data.transectionId,
                    bookingSuccess: true,
                    part3: false
                });

                this.uploadDocument(response.data.appointmentId);
            }
            else if (response.data.status === "failed") {
                this.setState({
                    status: false,
                    errMsgPaymentMethod: response.data.errors.paymentMethod,
                    errorMsgBookedSlot: response.data.errors.bookedSlot,
                    isLoading: false,


                });
            }
            else {

            }

            setTimeout(() => {
                this.setState({
                    status: false,
                    isLoading: false,
                });
            }, 5000);


        }).catch((error) => {

            console.log('book appointment catch error');
            console.log(error);

        });


    }

    bookAppointment(paymentMethod) {

        this.setState({
            isLoading: true,
        });

        var data = {
            doctordetail_id: this.state.id,
            membercontact_id: this.state.membercontact_id,
            member_id: this.state.member_id,
            symptoms: this.state.selectedSymptoms,
            termsAndCondition: 1,
            date: this.state.appointmentDate,
            slot: this.state.appointmentTime,
            consultation_type: this.state.consultation_type,
            consultationFee: this.state.fee,
            paymentMethod: paymentMethod,
            patient: this.state.patientName,

        }

        console.log("Request of book appointment : ", data);
        // return false;


        if (paymentMethod == 'cash') {
            this.confirmAppointment('cash');

        } else {
            this.rajorPay();
        }
    }


    rajorPay() {



        var data = {
            doctordetail_id: this.state.id,
            membercontact_id: this.state.membercontact_id,
            member_id: this.state.member_id,
            symptoms: this.state.selectedSymptoms,
            termsAndCondition: 1,
            date: this.state.appointmentDate,
            slot: this.state.appointmentTime,
            consultation_type: this.state.consultation_type,
            consultationFee: this.state.fee,
            paymentMethod: 'Card',
        }

        console.log("Request of book appointment : ", data);

        this.setState({
            isLoading: false,
        });

        // return false;


        axios.post(process.env.REACT_APP_API_URL + "order/", data).then((response) => {
            console.log("Response of appointment", response);

            if (response.data.success === true) {

                // start handle razorpay
                const data = response.data.order;
                const API_URL = process.env.REACT_APP_API_URL;
                console.log("get order :", data);
                const options = {
                    key: 'rzp_test_byMFJl0WtY7wjj',
                    name: "Doctor Patient Portal",
                    description: "we are provide online consultation ",
                    order_id: data.id,
                    handler: (response2) => {
                        try {
                            console.log("enter capture request ");
                            const paymentId = response2.razorpay_payment_id;
                            const url = `${API_URL}capture/${paymentId}`;

                            console.log("Payment Id :", paymentId);
                            const captureResponse = axios.post(url, {})

                            console.log("Get razorpay response ", captureResponse);

                            this.confirmAppointment('Card');

                        } catch (err) {
                            console.log("Enter catch error : ", err);
                        }
                    },
                    theme: {
                        color: "#686CFD",
                    },

                };
                const rzp1 = new window.Razorpay(options);
                rzp1.open();

                // end handle razorpay

            }


        })
    }


    handleInputChange = (event) => {

        if (event.target.name === 'selectedSymptoms') {

            console.log("Selected symptoms --------", this.state.selectedSymptoms);





            if (event.target.checked) {
                this.state.selectedSymptoms.push(event.target.value);

            }
            else {
                var index = this.state.selectedSymptoms.indexOf(event.target.value);
                this.state.selectedSymptoms.splice(index, 1);
            }
        }
        else if (event.target.name === 'termsAndCondition') {

            this.setState({
                [event.target.name]: event.target.value,
                isChecked: !this.state.isChecked,
            });
        }
        else {
            this.setState({
                [event.target.name]: event.target.value,
                // isChecked: !this.state.isChecked,
            });
        }



    }

    render() {







        // Authenticate
        const login = localStorage.getItem("isLoggedIn");
        if (!login) {
            return <Redirect to="/" />;
        }




        const doctor = this.state.doctor;
        const weeks = this.state.week;
        const members = this.state.members;
        const symptoms = this.state.symptoms;
        const slots = this.state.slotsArray;

        const part1 = this.state.part1;
        const part2 = this.state.part2;
        const part3 = this.state.part3;
        const bookingSuccess = this.state.bookingSuccess;

        const bookingConfirmCss = {
            width: "100px",
            margin: "0px 0px 0px 200px",

        };

        const calenderMessage = {
            color: "red",
            textAlign: "center",
            margin: "70px 0px 70px 0px",

        };


        // console.log("Get week dates : ", weeks);




        return (
            <React.StrictMode>

                {bookingSuccess === true ? (
                    <section class="payment-section thankyou-page-sec patient-main-section">
                        <div class="container">
                            <div class="detail-right-col">
                                <h2>Appointment Booked Successfully!</h2>
                                <div class="patient-info">
                                    <ul>
                                        <li class="patient-name">Patient Name <span className="captilize"> {this.state.patientName} </span></li>
                                        <li class="date-time">Date and Time <span> {this.state.appointmentDate} | {this.state.appointmentTime}</span></li>
                                        <li class="fee">Consulting Fee <span>   <NumberFormat value={this.state.doctor.fee} displayType={'text'} thousandSeparator={true} prefix={'Rs. '} />
                                        </span></li>
                                    </ul>
                                </div>
                                <div class="thank-message">
                                    <p>Appointment No. {this.state.appointmentNumber} booked with <span>DR. {this.state.doctor.name} on {this.state.appointmentDate} { }
                                        | {this.state.appointmentTime} </span></p>
                                    <Link to={`/patient/invoice/${this.state.transectionId}`} class="view-invoice">View Receipt</Link>
                                </div>
                            </div>
                        </div>

                    </section>
                ) : (


                    <section className="payment-section patient-main-section">
                        <div className="container">
                            <div className="row">

                                <LEFT_PANEL_DOCTOR_DETAIL id={this.state.id} />


                                {part1 === true ? (<div className="col-sm-9">
                                    <div className="detail-right-col">
                                        <div className="col-md-12">
                                            <h2>Book Appointment </h2>
                                        </div>





                                        <div className="dashboard-user select-member">

                                            <h4 className="pb-4 mb-3"> Select Member *    <span className="text-danger"> {this.state.errMsgselectedMember} </span></h4>



                                            {members.map((member, index) => (

                                                <div className="dashboard-user-col" key={member.id}>
                                                    <div className={this.state.activeIdMember === index ? 'mamber-pro active-user' : 'mamber-pro'} onClick={this.handleMemberClick.bind(this, index, member.id)}>
                                                        <span className="member-img">
                                                            {member.profile_pic == null ?
                                                                (<img alt="image-not-found" src={process.env.REACT_APP_BASE_URL + "img/picture-not-available.jpg"} />
                                                                )
                                                                :
                                                                (
                                                                    <img alt="member-img" src={process.env.REACT_APP_IMAGE_LOCATION + "patient/profile_picture/" + member.profile_pic} />
                                                                )
                                                            }
                                                        </span>
                                                        <span className="mamber-info">
                                                            <h3 className="captilize">{member.first_name} {member.last_name}
                                                                {/* <input type="radio" name="membercontact_id" value={member.id} checked={member.id == this.state.membercontact_id ? "true" : ""} onChange={this.handleInputChange} />
                                                                    */}
                                                            </h3>

                                                            <p><span className="age">{member.age} yrs</span> | <span className="jendeer">{member.gender}</span></p>
                                                            <p>{member.relation}</p>
                                                        </span>
                                                    </div>
                                                </div>

                                            ))}
                                        </div>


                                        {/* <div className="add-member-btn">
                                                <button data-toggle="modal" data-target="#exampleModal" type="button"><span
                                                    className="spt-img add-icon"></span> Add Member</button>
                                            </div> */}



                                        <div className="symptoms_block pt-xl-4">

                                            <h3> Select symptoms from below *   <span className="text-danger">{this.state.errMsgselectedSymptoms}</span></h3>

                                            <div className="symptoms_boxs">
                                                <ul>
                                                    {symptoms.map((symptom, index) => (

                                                        <li>
                                                            <label className="check"> {symptom.title}



                                                                <input type="checkbox" name="selectedSymptoms"
                                                                    value={symptom.title}
                                                                    onChange={this.handleInputChange} />





                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </li>
                                                    ))}




                                                </ul>
                                                {/* <div className="add-member-btn add-symptoms">
                                                <button data-toggle="modal" data-target="#exampleModal" type="button"><span className="spt-img add-icon"></span> Add Symptoms</button>
                                            </div> */}
                                            </div>
                                        </div>


                                        <div className="symptoms_block pt-xl-4">
                                            <h3> Select consultation type *   <span className="text-danger">{this.state.errMsgConsultationType}</span></h3>


                                            <label className="radio bookingRadio">Online
                                                <input type="radio" name="consultation_type" value="1" checked={this.state.consultation_type == 1 ? "true" : ""} onChange={this.handleInputChange} />
                                                <span className="checkround"></span>
                                            </label>

                                            <label className="radio bookingRadio" >In Clinic
                                                <input type="radio" name="consultation_type" value="2" checked={this.state.consultation_type == 2 ? "true" : ""} onChange={this.handleInputChange} />
                                                <span className="checkround"></span>
                                            </label>
                                        </div>



                                        {/* <div className="symptoms_block pt-xl-4">
                                                <h3> Select consultation type * </h3>
                                                <div className="text-danger">{this.state.errMsgConsultationType}</div>
                                                <input type="radio" name="consultation_type" value="1" checked={this.state.consultation_type == 1 ? "true" : ""} onChange={this.handleInputChange} />
                                                <label className="appointment_radio">Online</label>

                                                <input type="radio" name="consultation_type" value="2" checked={this.state.consultation_type == 2 ? "true" : ""} onChange={this.handleInputChange} />
                                                <label className="appointment_radio">In Clinic</label>
                                            </div> */}

                                        <div className="symptoms_block upload_doc_block">
                                            <h3> Upload Document   (Allowed JPG, GIF or PNG.)
                                                <span className="text-danger"> {this.state.errMsgFileMsg} </span></h3>
                                            <p>Upload related documents like Scan reports, X-ray etc.</p>
                                            <div className="uploaddoc_boxs">
                                                <div className=" upload d-flex justify-content-left">
                                                    {/* <a href="#" className="text-secondary">
                                                     <img src="img/upload-icons.png" alt="" /> 
                                                     Upload related documents like Scan reports, X-ray etc. 
                                                </a> */}



                                                    <input id="file" className="text-secondary form-control" multiple type="file" onChange={this.onFileChange} />



                                                </div>
                                                <div className="add-member-btn">
                                                    {/* <button data-toggle="modal" data-target="#exampleModal" type="button">
                                                    <span className="spt-img add-icon"></span>
                                                    Add More
                                                </button> */}




                                                </div>
                                            </div>
                                        </div>
                                        <div className="tc-check">
                                            <label className="check"> Click here to indicate that you have read and agree to the terms presented in the
                                                <Link to="/term-and-condition">Terms and Conditions agreement, Patient Consent and Privacy Policy</Link>. <span className="text-danger">{this.state.errMsgtermsAndCondition}</span>
                                                <input type="checkbox" name="termsAndCondition" value="1"
                                                    checked={this.state.isChecked} onChange={this.handleInputChange} />
                                                <span className="checkmark" ></span>
                                            </label>
                                        </div>
                                        <div className="next-btn-box">


                                            <button type="submit" className="pay-btn" onClick={() => this.getPart2()}>Next </button>

                                            <Link to="/doctorList/all">
                                                <button type="submit" className="cancel-btn">Cancel </button>
                                            </Link>


                                        </div>
                                    </div>
                                </div>


                                ) : ('')
                                }

                                {part2 === true ?
                                    (
                                        <div className="col-sm-9">
                                            <div className="detail-right-col">
                                                <div className="col-md-12">
                                                    <h2 >Book Appointment </h2>


                                                    <div className="row no-gutters">

                                                        <div className="col-sm-8">    <div className="dayname">
                                                            <p> {this.state.appointmentDate ? (this.state.appointmentDate) : ("")}
                                                            </p>   </div>  </div>

                                                        {/* <div className="col-sm-4">

                                                        <select className="form-control">
                                                            <option value=""> July 30,2020- 5 Aug, 2020 </option>
                                                            <option value=""> July 30,2020- 5 Aug, 2020 </option>
                                                            <option value=""> July 30,2020- 5 Aug, 2020 </option>
                                                            <option value=""> July 30,2020- 5 Aug, 2020 </option>
                                                        </select>

                                                    </div> */}


                                                    </div>


                                                    <div className="timeslot-row pt-xl-4">

                                                        <div className="slot_inner">

                                                            <ul>
                                                                <li>

                                                                    {this.state.page == 1 ? ('') : (<Link to="#" onClick={() => this.nextPrevBtn(this.state.page - 1)}> &lt;  </Link>)}
                                                                </li>

                                                                {/* {weeks.map((object, index) => (
                                                                        <li key={object.date}>
                                                                            <Link className={this.state.activeId === index ? 'active' : ' '}
                                                                                onClick={this.handleClick.bind(this, index, object.date)} >
                                                                                {object.day}
                                                                                <span> {object.date} </span>
                                                                            </Link>
                                                                        </li>
                                                                    ))} */}

                                                                {this.state.page == 1 ? (
                                                                    weeks.map((object, index) => (
                                                                        index < 7 ? (<li key={object.date}>
                                                                            <Link className={this.state.activeId === index ? 'active' : ' '}
                                                                                onClick={this.handleClick.bind(this, index, object.date)} >
                                                                                {object.day}
                                                                                <span> {object.date} </span>
                                                                            </Link>
                                                                        </li>) : ('')
                                                                    ))) : ('')}


                                                                {this.state.page == 2 ? (
                                                                    weeks.map((object, index) => (
                                                                        index > 6 && index < 14 ? (<li key={object.date}>
                                                                            <Link className={this.state.activeId === index ? 'active' : ' '}
                                                                                onClick={this.handleClick.bind(this, index, object.date)} >
                                                                                {object.day}
                                                                                <span> {object.date} </span>
                                                                            </Link>
                                                                        </li>) : ('')
                                                                    ))) : ('')}


                                                                {this.state.page == 3 ? (
                                                                    weeks.map((object, index) => (
                                                                        index > 13 && index < 21 ? (<li key={object.date}>
                                                                            <Link className={this.state.activeId === index ? 'active' : ' '}
                                                                                onClick={this.handleClick.bind(this, index, object.date)} >
                                                                                {object.day}
                                                                                <span> {object.date} </span>
                                                                            </Link>
                                                                        </li>) : ('')
                                                                    ))) : ('')}


                                                                {this.state.page == 4 ? (
                                                                    weeks.map((object, index) => (
                                                                        index > 20 && index < 28 ? (<li key={object.date}>
                                                                            <Link className={this.state.activeId === index ? 'active' : ' '}
                                                                                onClick={this.handleClick.bind(this, index, object.date)} >
                                                                                {object.day}
                                                                                <span> {object.date} </span>
                                                                            </Link>
                                                                        </li>) : ('')
                                                                    ))) : ('')}


                                                                {this.state.page == 5 ? (
                                                                    weeks.map((object, index) => (
                                                                        index > 27 && index < 34 ? (<li key={object.date}>
                                                                            <Link className={this.state.activeId === index ? 'active' : ' '}
                                                                                onClick={this.handleClick.bind(this, index, object.date)} >
                                                                                {object.day}
                                                                                <span> {object.date} </span>
                                                                            </Link>
                                                                        </li>) : ('')
                                                                    ))) : ('')}


                                                                {weeks.length > 7 && weeks.length < 15 ? (<li>
                                                                    {this.state.page >= 2 ? ('') : (<Link to="#" onClick={() => this.nextPrevBtn(this.state.page + 1)}> &gt; </Link>)}
                                                                </li>) : (<li> </li>)}

                                                                {weeks.length > 14 && weeks.length < 22 ? (<li>
                                                                    {this.state.page >= 3 ? ('') : (<Link to="#" onClick={() => this.nextPrevBtn(this.state.page + 1)}> &gt; </Link>)}
                                                                </li>) : ('')}

                                                                {weeks.length > 21 && weeks.length < 29 ? (<li>
                                                                    {this.state.page >= 4 ? ('') : (<Link to="#" onClick={() => this.nextPrevBtn(this.state.page + 1)}> &gt; </Link>)}
                                                                </li>) : ('')}




                                                            </ul>

                                                        </div>


                                                    </div>


                                                    <div className="timeslot py-xl-4">




                                                        {this.state.isLoading ? (<p style={calenderMessage}>
                                                            <span
                                                                className="spinner-border spinner-border-sm ml-5"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span></p>) : (' ')}

                                                        {slots.length == 0 ?
                                                            (<p style={calenderMessage}> {this.state.emptySlotMessage} </p>)
                                                            : (<ul>
                                                                {slots.map((slot, index) => (
                                                                    <li>
                                                                        {slot.Available == null ? (<Link className="bookedslot" title="Booked">{slot.Booked}</Link>) : (
                                                                            <Link title="Available" className={this.state.activeIdSlot === index ? 'selectedcss' : ' '}
                                                                                onClick={this.handleSlotClick.bind(this, index, slot.Available)}>
                                                                                {slot.Available}</Link>)}

                                                                    </li>
                                                                ))
                                                                }</ul>)}





                                                        {/* <ul>
                                                                <li> <Link className={this.state.activeIdSlot === 1 ? 'bookedslot' : ' '} onClick={this.handleSlotClick.bind(this, 1, '9:00')}>09:00 </Link> </li>
                                                                <li> <Link className={this.state.activeIdSlot === 2 ? 'bookedslot' : ' '} onClick={this.handleSlotClick.bind(this, 2, '9:30')}>09:30 </Link> </li>
                                                                <li> <Link className={this.state.activeIdSlot === 3 ? 'bookedslot' : ' '} onClick={this.handleSlotClick.bind(this, 3, '10:00')}>10:00 </Link> </li>
                                                                <li> <Link className={this.state.activeIdSlot === 4 ? 'bookedslot' : ' '} onClick={this.handleSlotClick.bind(this, 4, '10:30')}>10:30 </Link> </li>

                                                                <li> <Link className={this.state.activeIdSlot === 5 ? 'bookedslot' : ' '} onClick={this.handleSlotClick.bind(this, 5, '11:00')}>11:00 </Link> </li>

                                                                <li> <Link className={this.state.activeIdSlot === 6 ? 'bookedslot' : ' '} onClick={this.handleSlotClick.bind(this, 6, '11:30')}>11:30 </Link> </li>

                                                                <li> <Link className={this.state.activeIdSlot === 7 ? 'bookedslot' : ' '} onClick={this.handleSlotClick.bind(this, 7, '12:00')}>12:00 </Link> </li>

                                                                <li> <Link className={this.state.activeIdSlot === 8 ? 'bookedslot' : ' '} onClick={this.handleSlotClick.bind(this, 8, '12:30')}>12:30 </Link> </li>

                                                                <li> <Link className={this.state.activeIdSlot === 9 ? 'bookedslot' : ' '} onClick={this.handleSlotClick.bind(this, 9, '13:00')}>13:00 </Link> </li>

                                                                <li> <Link className={this.state.activeIdSlot === 10 ? 'bookedslot' : ' '} onClick={this.handleSlotClick.bind(this, 10, '13:30')}>13:30 </Link> </li>

                                                                <li> <Link className={this.state.activeIdSlot === 11 ? 'bookedslot' : ' '} onClick={this.handleSlotClick.bind(this, 11, '14:00')}>14:00 </Link> </li>

                                                            </ul> */}
                                                    </div>


                                                    <div className="col-md-12">

                                                        {/* <Link to={`/patient/bookappointmentform/${this.state.id}`} >
                                             <button type="submit" className="pay-btn">Next </button></Link> */}

                                                        <button type="submit" disabled={!this.state.appointmentDate} disabled={!this.state.appointmentTime} className="pay-btn" onClick={() => this.getPart3()}>Next </button>

                                                        <button type="submit" className="cancel-btn" onClick={() => this.getBack('part2')}>Back</button>


                                                    </div>


                                                </div>



                                            </div>
                                        </div>) : ('')
                                }

                                {part3 === true ? (

                                    <div className="col-sm-9">
                                        <div className="detail-right-col">
                                            <div className="col-md-12">
                                                <h2>Book Appointment</h2>
                                                <div className="patient-info">
                                                    <ul>

                                                        <li className="patient-name">Patient Name <span className="captilize"> {this.state.patientName}</span></li>
                                                        <li className="date-time">Appointment Date
                                                            <span>{this.state.appointmentDate} | {this.state.appointmentTime}
                                                            </span>
                                                        </li>
                                                        <li className="fee">Consulting Fee
                                                            <span>  <NumberFormat value={this.state.doctor.fee} displayType={'text'} thousandSeparator={true} prefix={'Rs. '} /></span>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="payment-method-row">
                                                <div className="payment-option">
                                                    <div className="col-md-12">
                                                        <h3>Payment Method</h3>
                                                        <div className="text-danger">{this.state.errorMsgBookedSlot}</div>

                                                        <div className="text-danger">{this.state.errMsgPaymentMethod}</div>
                                                        <br />
                                                        <ul className="nav nav-tabs">

                                                            <Tabs defaultActiveKey="payment1" transition={false}>
                                                                <Tab eventKey="payment1" title="Card / Internet Banking">
                                                                    <div className="tab-content">
                                                                        <div id="credit-card" className="container tab-pane active"><br />
                                                                            <form className="Card-form" method="">

                                                                                <div className="form-row">
                                                                                    <div className="col-md-12">

                                                                                        <button type="button" className="pay-btn" onClick={() => this.bookAppointment('Card')}>
                                                                                            PAY
                                                                                            <NumberFormat value={this.state.doctor.fee} displayType={'text'} thousandSeparator={true} prefix={' Rs. '} />
                                                                                            {this.state.isLoading ? (
                                                                                                <span
                                                                                                    className="spinner-border spinner-border-sm ml-5"
                                                                                                    role="status"
                                                                                                    aria-hidden="true"
                                                                                                ></span>
                                                                                            ) : (
                                                                                                <span></span>
                                                                                            )}</button>
                                                                                        <button type="submit" className="cancel-btn" onClick={() => this.getBack('part3')}>Back</button>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>

                                                                    </div>

                                                                </Tab>


                                                                {this.state.consultation_type == 2 ? (
                                                                    <Tab eventKey="payment2" title=" In-Clinic ">
                                                                        <div className="tab-content">
                                                                            <br />
                                                                            <div className="col-md-12">

                                                                                <button type="button" className="pay-btn" onClick={() => this.bookAppointment('cash')}>
                                                                                    PAY <NumberFormat value={this.state.doctor.fee} displayType={'text'} thousandSeparator={true} prefix={' Rs. '} />
                                                                                    {this.state.isLoading ? (
                                                                                        <span
                                                                                            className="spinner-border spinner-border-sm ml-5"
                                                                                            role="status"
                                                                                            aria-hidden="true"
                                                                                        ></span>
                                                                                    ) : (
                                                                                        <span></span>
                                                                                    )}
                                                                                </button>
                                                                                <button type="submit" className="cancel-btn" onClick={() => this.getBack('part3')}>Back</button>
                                                                            </div>
                                                                        </div>
                                                                    </Tab>) : ('')}



                                                            </Tabs>


                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : ('')
                                }




                            </div>
                        </div>

                    </section>)}
            </React.StrictMode>

        )

    }
}




export default bookAppointment;