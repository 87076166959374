import React, { Component } from 'react';
import LEFTNAV from "./leftnav";
import axios from "axios";
import JOINCALL from './joinCall';



import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";


class Consultation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            consultations: [],
            showHide: false,
            today:new Date().getTime(),
        
        };
    }

    getConsultationLists() {

        var filterByUserId = localStorage.getItem("filterByUserId");
        if (filterByUserId) {

            axios.get(process.env.REACT_APP_API_URL + "getConsultationList/" + filterByUserId)
                .then((response) => {

                    console.log("Consultation data :", response.data.result);

                    if (response.data.status === true) {
                        this.setState({
                            consultations: response.data.result,
                        });

                    } else {
                        console.log(response);
                    }
                })
                .catch((error) => {
                    console.log('catch error');
                    console.log(error);
                });
        }

    }




    componentDidMount() {
        this.getConsultationLists();
    }


    getTodayDate(){
            var today = new Date();
            var newDay = today.getDate();
            var newMonth = today.getMonth() + 1;
            var newYear = today.getFullYear();
            var date = newYear + '-' + newMonth + '-' + newDay;
            return date;
    }


    render() {
        
        var today = this.getTodayDate();
        const columns = [

            {
                name: "Consultation No.",
                width: "170px",
                selector: "appointmentNo",
                sortable: true
            },
            {
                name: "Date & Time",
                width: "160px",
                sortable: true,
                selector: "appointmentDate",
                cell: appointment => <span>
                    {appointment.appointmentDate} {appointment.appointment_time}

                </span>,
            },

            {
                name: "Doctor Name",
                width: "160px",
                selector: "doctor_name",
                sortable: true,
                cell: appointment => <span>
                  <span className="captilize"> <b> Dr.  {appointment.doctor_name} </b> 
                 
                  {appointment.doctor_specialization && appointment.doctor_specialization.substring(30,0) } 
                  {appointment.doctor_specialization && appointment.doctor_specialization.length >30 ? ('...') : ('') }
                </span>
                </span>,
            },
            {
                name: "Symptoms",
                selector: "symptoms",
                width: "160px",
               
            },


            {
                name: "Status",
                width: "120px",
                selector: "status",
                sortable: true,
                cell: d => <span>{d.status == 2 ? (<span className="confirm">CONFIRM </span>) : ('')}
                    {d.status == 5 ? (<span className="confirm">COMPLETE </span>) : ('')}
                </span>,
            },
            {
                name: "Action",
                width: "150px",
                selector: "status",
                cell: d => <span>{d.status == 2 && d.consult_type == 1 && new Date(today).getTime() === new Date(d.appointmentDate2).getTime() ? (<JOINCALL channel={d.appointmentNo} />) : ('')}</span>,
            }

        ];


        const data = this.state.consultations;
        const tableData = { columns, data };


        return (
            <section className="patient-appointment-section  patient-main-section">
                <div className="container">
                    <div>
                        <LEFTNAV />

                        <div className="content-wrapper">
                            <div className="patient-appointment-list-right-col">
                                <div className="col-md-12">
                                    <h2><span><i className="spt-img calendar-icon"></i></span> Consultations</h2>
                                    {/* <form className="appointments-list-search">
                                        <input type="text" name="appointments-list-search" className="form-control" />
                                        <button type="submit" className="ap-list-search"><i className="spt-img search-icon"></i></button>
                                    </form> */}
                                </div>
                                <div className="list-table d-inline-block w-100">
                                    <div className="table-responsive">


                                        {this.state.consultations.length > 0 ? (<DataTableExtensions {...tableData}>
                                            <DataTable
                                                title="Consultation"
                                                columns={columns}
                                                data={data}
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                paginationPerPage={10}
                                                className="table"
                                                responsive
                                            />
                                        </DataTableExtensions>) : (<div className="table">
                                            <div className="datanotAvailable"> There are no records to display </div>
                                        </div>)}



                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        )

    }
}

export default Consultation;